import UserAuthContext from "../../context/UserAuthContext";
class Auth {

  constructor(uid = null, db = null, firebase = null) {
    if (uid) {
      this.uid = uid;
    } else {
      this.setUid()
    }
    if (db) {
      this.db = db;
    }
    if( firebase ) {
      this.firebase = firebase;
    }
  }

  getUid() {
    return new Promise((resolve, reject) => {
      const uid = window.sessionStorage.getItem('uid');
      if (uid) {
        resolve(uid);
      } else {
        reject("You haven't authenticated!");
      }

    });

  }

  setUid() {
    const uid = window.sessionStorage.getItem('uid');
    this.uid = uid;
  }

  getUsername() {
    return new Promise(async (resolve, reject) => {
      const uid = this.uid;
      const userRef = await this.db.collection('users').doc(uid).get();
      const username = await userRef.data()?.username;
      if (username) {
        resolve(username);
      } else {
        reject(new Error("Current user doesn't have username"));
      }
    });
  }

  logout() {
    return new Promise(async (resolve, reject) => {
      this.firebase.auth().signOut().then(function() {
        resolve(true);
      }).catch(function(error) {
        reject(error);
      });
    });
  }

  clearCookies() {
    window.sessionStorage.removeItem('uid');
  }
}
Auth.contextType = UserAuthContext;

export default Auth;