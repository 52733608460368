import { fetchInsertWidget } from "./widgetFetchers";

export { fetchInsertWidget }

class Widget {
  constructor(uid, db) {
    this.uid = uid;
    this.db = db;
  }

  fetchAllWidgets() {
    return new Promise(async (resolve, reject) => {

      try {
        /* get firestore user ref */
        const userRef = this.db.collection('users').doc(this.uid);
        if (!userRef) reject("UID isn't exist in database");

        /* get widgets ref */
        const widgetsRef = userRef.collection('widgets');
        //TODO: add widget collection when widgets collection not available
        if (!widgetsRef) reject("Widgets are not available for this user");

        /* get widgets data */
        const widgetsDocsRef = await widgetsRef.get();
        const widgets = widgetsDocsRef.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        });
        resolve(widgets);

      } catch (e) {
        reject("error fetching widgets");
      }

    })
  }

  fetchAllWidgetsByUsername(username) {
    return new Promise(async (resolve, reject) => {

      /* get firestore user ref */
      const userRef = this.db.collection('users').where("username", "==", username).get();
      debugger;
      if (!userRef) reject("UID isn't exist in database");

      /* get widgets ref */
      const widgetsRef = userRef.collection('widgets');
      //TODO: add widget collection when widgets collection not available
      if (!widgetsRef) reject("Widgets are not available for this user");

      /* get widgets data */
      const widgetsDocsRef = await widgetsRef.get();
      const widgets = widgetsDocsRef.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data(),
        }
      });
      resolve(widgets);

    })
  }

  fetchWidget(widgetId) {
    return new Promise(async (resolve, reject) => {
      /* get firestore user ref */
      const userRef = this.db.collection('users').doc(this.uid);
      if (!userRef) reject("UID isn't exist in database");

      /* get widgets ref */
      const widgetsRef = userRef.collection('widgets');
      //TODO: add widget collection when widgets collection not available
      if (!widgetsRef) reject("Widgets are not available for this user");

      /* get widgets data */
      const widgetItemRef = await widgetsRef?.doc?.(widgetId)?.get?.();
      const widgetData = await widgetItemRef?.data?.();

      resolve(widgetData);

    });
  }

  fetchWidgetSnapshot(widgetId, callback) {
    return new Promise(async (resolve, reject) => {
      /* get firestore user ref */
      const userRef = this.db.collection('users').doc(this.uid);
      if (!userRef) reject("UID isn't exist in database");

      /* get widgets ref */
      const widgetsRef = userRef.collection('widgets');
      //TODO: add widget collection when widgets collection not available
      if (!widgetsRef) reject("Widgets are not available for this user");

      /* get widgets data */
      const unsubscribe = await widgetsRef.doc(widgetId).onSnapshot(widget => {
        callback(widget.data());

      });
      resolve(unsubscribe);

    });
  }

  addNewWidget(widget) {
    return new Promise(async (resolve, reject) => {
      /* get firestore user ref */
      const userRef = this.db.collection('users').doc(this.uid);
      if (!userRef) reject("UID isn't exist in database");

      /* get widgets ref */
      const widgetsRef = userRef.collection('widgets');
      //TODO: add widget collection when widgets collection not available
      if (!widgetsRef) reject("Widgets are not available for this user");

      await this.updateOrderOtherWidgetItems(widgetsRef);

      const res = await widgetsRef.doc().set({
        ...widget,
        order: 0,
      });
      resolve(res);

    })

  }

  updateOrderOtherWidgetItems(widgetsRef) {
    return new Promise(async (resolve, reject) => {
      const d = await widgetsRef.orderBy('order', 'asc').get();
      const items = d.docs;
      items.forEach(async (item, index) => {
        const widgetId = item.id;
        const widgetRef = this.db.collection('users').doc(this.uid).collection('widgets').doc(widgetId);
        await widgetRef.set({
          order: index + 1,
        }, { merge: true });
      });
      resolve(true);
      // debugger;

    });
  }

  updateWidget({ widgetId, field, value }) {
    return new Promise(async (resolve, reject) => {

      const userRef = this.db.collection('users').doc(this.uid);
      /* get firestore user ref */
      if (!userRef) reject("UID isn't exist in database");

      /* get widgets ref */
      const widgetsRef = userRef.collection('widgets');

      /* get widget object by id */
      const widgetRef = widgetsRef.doc(widgetId);

      let updateObj = {};
      updateObj[field] = value;
      await widgetRef.set(updateObj, { merge: true })
      resolve(true);
    });

  }

  updateWidgetBulk(widgetId, updateObj) {
    return new Promise(async (resolve, reject) => {

      const userRef = this.db.collection('users').doc(this.uid);
      /* get firestore user ref */
      if (!userRef) reject("UID isn't exist in database");

      /* get widgets ref */
      const widgetsRef = userRef.collection('widgets');

      /* get widget object by id */
      const widgetRef = widgetsRef.doc(widgetId);

      await widgetRef.set(updateObj, { merge: true })
      resolve(true);
    });

  }

  deleteWidget({ widgetId }) {
    return new Promise(async (resolve, reject) => {
      const userRef = this.db.collection('users').doc(this.uid);
      /* get firestore user ref */
      if (!userRef) reject("UID isn't exist in database");

      /* get widgets ref */
      const widgetsRef = userRef.collection('widgets');

      /* get widget object by id */
      try {
        await widgetsRef.doc(widgetId).delete();
        resolve(true);
      } catch (e) {
        reject(new Error(e));

      }

    });
  }

  updateWidgetsOrder(widgets) {
    return new Promise(async (resolve, reject) => {

      await Promise.all(widgets.map(async (item, index) => {
        try {
          await this.updateWidget({
            widgetId: item.id,
            field: "order",
            value: index,
          });
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }));
      resolve(true);

    })
  }
}

export default Widget;