import React, { useState, useEffect } from "react";
import styled from "styled-components";
import StatCard from "../../components/StatCard";
import SectionBox from "../../components/SectionBox";
import TopWidget from "./TopWidget";
import Articles from "./Articles";
// import { Button } from "../../components/forms";
import ScreenContainer from "../../components/ScreenContainer";
import AnalyticsBar from "../../components/AnalyticsBar";
import { fetchGetHomeAnalytics, fetchGetInsights, fetchGetTopLinkWidgets } from "../../actions/analytics";
import UserDataContext from "../../context/UserDataContext";
import mapApiToApp from "../../helpers/mapApiToApp";
import { Link, Route, /*useHistory*/ } from "react-router-dom";
import { FilterDatePage } from "../";
import { Spinner } from "../../components";
import InsightsDateContext from "../../context/InsightsDateContext";
import { getDateFilterModeFullString } from "../../helpers/helper";
import Widget from "../../Entities/Widget/Widget";
import { auth, db } from "../../utilities/firebaseContext";
// import { format } from "date-fns";



const HomeDashboard = () => {
  const currentUser = auth?.currentUser;
  const uid = currentUser?.uid;


  const [totalVisitor, setTotalVisitor] = useState(0);
  const [totalLinkClicks, setTotalLinkclicks] = useState(0);
  const [totalUserEngagementDuration, settotalUserEngagementDuration] = useState(0);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [filterDateMode, setFilterDateMode] = useState("last_7_days");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [startDateFinal, setStartDateFinal] = useState(new Date());
  const [endDateFinal, setEndDateFinal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [insightsDate, setInsightsDate] = useState("lululu");
  const [topWidgetLinks, setTopWidgetLinks] = useState([]);
  const [articleList, setArticleList] = useState([]);

  // const history = useHistory();


  const { userData } = React.useContext(UserDataContext);
  // console.log("userData iz ", userData);

  async function getInsights({ userData, startDate, endDate }) {
    const username = userData?.username;
    const res = await fetchGetInsights({ username, startDate, endDate, filterDateMode });
    const totalVisits = res?.totalVisits;
    const totalLinkClicks = res?.totalLinkClicks;
    const totalUserEngagementDuration = res?.totalUserEngagementDuration;
    const totalEngMinutes = totalUserEngagementDuration / 60;


    setTotalVisitor(totalVisits);
    setTotalLinkclicks(totalLinkClicks);
    settotalUserEngagementDuration(totalEngMinutes.toFixed(2));
  }

  async function getAnalytics({ userData, startDate, endDate }) {
    const username = userData?.username;
    const data = await fetchGetHomeAnalytics({ username, startDate, endDate, filterDateMode });
    const mappedData = mapApiToApp({ data, type: "analytics" });
    setAnalyticsData(mappedData);


  }
  /*
  useEffect(() => {
    async function start() {
      alert("big boy move");
      getInsights({ userData });
      if (analyticsData?.length === 0) getAnalytics({ userData });


    }

    if (userData) start();
    // eslint-disable-next-line
  }, [userData]);
  */

  useEffect(() => {
    async function start() {
      setLoading(true);
      if (filterDateMode && userData) {
        // console.log("filterDateMode kun", filterDateMode);
        // console.warn("userData kun ", userData);
        getInsights({ userData, startDate: startDateFinal, endDate: endDateFinal });
        await getAnalytics({ userData, startDate: startDateFinal, endDate: endDateFinal });
        await getTopLinkWidgets({
          uid, db,
          onSuccess: ({ data }) => {
            console.log("datahey", data);
            setTopWidgetLinks(data);
          }
        });
        await getArticleList({
          onSuccess: ({ data }) => {
            const posts = data?.posts;
            setArticleList(posts);
          }
        });
        setLoading(false);

      }

    }
    start();
    // eslint-disable-next-line
  }, [filterDateMode, startDateFinal, endDateFinal, userData]);

  useEffect(() => {
    // setInsightsDate("uiiiiiiiii")
  }, []);

  return (
    <>
      <InsightsDateContext.Provider value={{ insightsDate, setInsightsDate }}>
        <Route path="/dashboardv2/filter_date">
          <ScreenContainer>
            <FilterDatePage
              filterDateMode={filterDateMode}
              setFilterDateMode={setFilterDateMode}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDateFinal={setStartDateFinal}
              setEndDateFinal={setEndDateFinal}
            />
          </ScreenContainer>
        </Route>
        <Route exact path="/dashboardv2">

          <ScreenContainer>
            <Head>
              <div style={{
                flex: 1,
              }}>
                <h1>HOME</h1>
                {/* <button onClick={() => history.push("/wizard")}>go</button> */}
              </div>
              <Link
                to="/dashboardv2/filter_date"
                className="button button-secondary2 button-mini "
              // style={{
              //   width: "auto",
              //   color: "var(--primary)",
              //   border: "1px solid rgba(170, 145, 196, 0.2)",
              //   boxShadow: "0px 3px 2px rgba(170, 145, 196, 0.2)",
              //   textDecoration: "none",
              // }}
              ><i className="far fa-calendar" />&nbsp;{getDateFilterModeFullString({ mode: filterDateMode, startDate: startDateFinal, endDate: endDateFinal })}</Link>

            </Head>
            <Spinner show={loading} />
            <Section className="primary-statistic" style={{ marginTop: '1em' }}>
              <StatCard title="Total Visits" number={totalVisitor} /*rate="15.43%"*/ />
              <StatCard title="Total Link Clicks" number={totalLinkClicks} /*rate="15.43%"*/ />
              <StatCard title="Total Engagement" number={totalUserEngagementDuration} /*rate="15.43%"*/ />
            </Section>

            <AnalyticsBar data={analyticsData} />

            <SectionBox title="TOP WIDGET OVERALL" body={<TopWidget data={topWidgetLinks} />} style={{
              marginTop: "1em"
            }} />
            <SectionBox title="ARTICLES" body={<Articles data={articleList} />} />

          </ScreenContainer>

        </Route>
      </InsightsDateContext.Provider>
    </>
  )
}

async function getTopLinkWidgets({ userData, startDate, endDate, uid, db, onSuccess }) {
  if (!uid || !db) return;
  const topLinks = await fetchGetTopLinkWidgets({ username: "dytra" });
  console.log("topLinks are ", topLinks);
  const mappedTopLinks = topLinks?.map?.(async item => {
    const { widget_id, event_count } = item || {};
    const w = new Widget(uid, db);
    const widget = await w?.fetchWidget(widget_id);
    if (widget?.url) {
      return { ...widget, event_count };
    } else {
      return null;
    }
  });
  const resolvedTopLinks = await Promise.all(mappedTopLinks);
  const filteredTopLinks = resolvedTopLinks.filter(item => (item));
  onSuccess({ data: filteredTopLinks });
  return filteredTopLinks;

}

async function getArticleList({ onSuccess }) {
  const res = await fetch("https://public-api.wordpress.com/rest/v1.1/sites/peronaid.wordpress.com/posts?number=10");
  const data = await res.json();
  onSuccess({ data })
  return res;
}


const Section = styled.section`
  display:flex;
  justify-content:space-between;
  margin-bottom:1em;
  /* flex-wrap:wrap; */
`;

const Head = styled.section`
  display:flex;
  justify-content:space-between;
  align-items: center;
`;

export default HomeDashboard;