import React, { useContext } from "react";
import LinkWidgetPreview from "../LinkWidget/LinkWidgetPreview";
import { ThemeContext } from 'styled-components';
// import UserAuthContext from "../../context/UserAuthContext";

const SingleLink = ({ widget, userData }) => {
  const themeContext = useContext(ThemeContext);
   console.warn("themeContext iz ",themeContext);
  return (
    <LinkWidgetPreview link={widget} themeContext={themeContext} userData={userData} />
  )
}

export default SingleLink;