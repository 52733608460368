import React from "react";
import { useHistory } from "react-router-dom";
import "./TopBar.scss";

const TopBar = ({ title }) => {
  const history = useHistory();

  return (
    <div className="top-barv3">
      <div className="top-bar--icon">
        <i className="fas fa-chevron-left" onClick={() => history.goBack()} />
      </div>
      <div className="top-bar--title">{title}</div>
      <div className="top-bar--icon" style={{visibility:'hidden'}}>
        <i className="fas fa-chevron-left" onClick={() => history.goBack()} />
      </div>
    </div>
  )
}

export default TopBar;