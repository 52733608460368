import React from "react";
import { ResponsiveBar } from '@nivo/bar'
import noDataImage from "../../assets/illustrations/no_data.svg";

const AnalyticsBar = ({ data }) => {
  console.log("data kun", data);

  return (
    <div style={{
      width: '100%',
      height: '20em'
    }}>
      {data?.length > 0 && (
        <ResponsiveBar
          data={data}
          keys={['profile_view']}
          indexBy="date"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          // colors={{ scheme: 'purple_orange' }}
          colors={['#5F31E0']}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          fill={[
            {
              match: {
                id: 'fries'
              },
              id: 'dots'
            },
            {
              match: {
                id: 'sandwich'
              },
              id: 'lines'
            }
          ]}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          // borderColor="#000000"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'date',
            legendPosition: 'middle',
            legendOffset: 32
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'views',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />

      )}

      {data?.length === 0 && (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center" }}>
          <img src={noDataImage} style={{ width: "150px", height: "150px",marginBottom:"1em  " }} alt="no data illustration" />
          <h3>Analytics Currently Not Available :(</h3>
          <em style={{ textAlign: "center", fontSize:".85rem",color:"var(--darkgrey)" }}>We need more time to collect your data. <br />At the moment, you can share your link profile to others.</em>
        </div>
      )}
    </div>
  )
}

/*
const data = [
  {
    "country": "AD",
    "donut": 116,
    "donutColor": "hsl(254, 70%, 50%)",
  },
  {
    "country": "AE",

    "donut": 58,
    "donutColor": "hsl(254, 70%, 50%)"
  },
  {
    "country": "AF",

    "donut": 67,
    "donutColor": "hsl(262, 70%, 50%)"
  },
  {
    "country": "AG",

    "donut": 114,
    "donutColor": "hsl(310, 70%, 50%)"
  },
  {
    "country": "AI",
    "donut": 169,
    "donutColor": "hsl(40, 70%, 50%)"
  },
  {
    "country": "AL",
    "donut": 35,
    "donutColor": "hsl(170, 70%, 50%)"
  },
  {
    "country": "AM",
    "donut": 26,
    "donutColor": "hsl(126, 70%, 50%)"
  }
];
*/

export default AnalyticsBar;