import React from "react";
const firebase = require('firebase');
var firebaseui = require('firebaseui');



const firebaseConfig = {
  apiKey: "AIzaSyChDirkiB-HmZ2ompDfWfJRSE6bZhfQUVI",
  authDomain: "perona-id.firebaseapp.com",
  databaseURL: "https://perona-id.firebaseio.com",
  projectId: "perona-id",
  storageBucket: "perona-id.appspot.com",
  messagingSenderId: "328770145508",
  appId: "1:328770145508:web:b468711cdb61940ae230f1",
  measurementId: "G-20BNM2Y9EF"
};
// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);

}
//console.log(firebase.apps.length);

export const ui = new firebaseui.auth.AuthUI(firebase.auth());
export const db = firebase.firestore();
export const auth = firebase.auth();
export const FireStoreContext = React.createContext(db);
export const analytics = firebase.analytics();

export default firebase;
