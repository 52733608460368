import { INSIGHTS_URL, ANALYTICS_URL } from "../config/variables";
import { analytics } from "../utilities/firebaseContext";
import { format } from "date-fns";

export async function fetchRecordLogProfileView({ uid, username }) {
  logProfileViewGA({ uid, username });
}

export async function fetchRecordWidgetClicks({ widget_id, url, title, username }) {
  analytics.logEvent("widget_link_click", {
    widget_id,
    url,
    title,
    username,
  });
  analytics.logEvent("widget_interaction", {
    widget_id,
    widget_type: "link",
    url,
    title,
    username,
  });
}

// eslint-disable-next-line
async function logProfileViewFirestore({ uid }) {
  console.log("UID izz ", uid);
  const res = await fetchAnalytics({
    category: "page_view",
    data: {
      userId: uid,
    }
  });
  return res;
}

async function logProfileViewGA({ uid, username }) {

  analytics.logEvent("profile_view", {
    uid,
    username,
  });
}

async function fetchAnalytics({ category, data = null }) {
  const fire = await fetch(INSIGHTS_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      category, data
    }),
  });
  const res = await fire.json();
  return res;
}

export async function fetchGetInsights({ username, filterDateMode, startDate, endDate }) {
  const queryParam = {
    username,
    filterDateMode,
  }
  if (filterDateMode === "custom") {
    queryParam.startDate = format(startDate ?? new Date(), "yyyy-MM-dd");
    queryParam.endDate = format(endDate ?? new Date(), "yyyy-MM-dd");
  }
  const queryParamString = new URLSearchParams(queryParam).toString();
  const fire = await fetch(`${INSIGHTS_URL}?${queryParamString}`, {
    headers: {
      "Content-Type": "application/json"
    }
  });
  const res = await fire.json();
  return res;
}

export async function fetchGetHomeAnalytics({ username, filterDateMode, startDate, endDate }) {
  const queryParam = {
    username,
    filterDateMode,
  }
  if (filterDateMode === "custom") {
    queryParam.startDate = format(startDate ?? new Date(), "yyyy-MM-dd");
    queryParam.endDate = format(endDate ?? new Date(), "yyyy-MM-dd");
  }
  const queryParamString = new URLSearchParams(queryParam).toString();
  const fire = await fetch(`${ANALYTICS_URL}?${queryParamString}`, {
    headers: {
      "Content-Type": "application/json"
    }
  });
  const res = await fire.json();
  return res;

}

export async function fetchGetTopLinkWidgets({ username, filterDateMode, startDate, endDate }) {
  const queryParam = {
    username,
    filterDateMode,
  }
  if (filterDateMode === "custom") {
    queryParam.startDate = format(startDate ?? new Date(), "yyyy-MM-dd");
    queryParam.endDate = format(endDate ?? new Date(), "yyyy-MM-dd");
  }
  const queryParamString = new URLSearchParams(queryParam).toString();
  const fire = await fetch(`${INSIGHTS_URL}/getTopWidgets?${queryParamString}`, {
    headers: {
      "Content-Type": "application/json"
    }
  });
  const res = await fire.json();
  return res;
}
