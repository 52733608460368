import React from "react";
import Overlay from "../Overlay";
import "./ActionSheet.scss";
const ActionSheet = ({ showActionSheet, toggleShowActionSheet, children }) => {
  return (
    <Overlay showOverlay={showActionSheet} toggleShowOverlay={toggleShowActionSheet}>
      {<section id="action-sheet" style={{display: showActionSheet ? 'block' : 'none'}}>
        {children}
      </section>}
    </Overlay>
  )
}


export default ActionSheet;