import React, { useState } from "react";
import './Landing.scss';
import homeLanding from "../../assets/home-landing.svg";
import HighlightPoints from "./HighlightPoints";
// import erinProfile from "../../assets/images/erindesongprofile.png";
// import Testimonials from "./Testimonials";
import SuperCallAction from "./SuperCallAction";
// import FooterLanding from "./FooterLanding";
// import smartphoneImage from "../../assets/images/smartphone2.png";
import Fade from 'react-reveal/Fade';
import LandingTemplate from "../../templates/LandingTemplate/LandingTemplate";
import BottomAlert from "../../components/BottomAlert";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import GaussianHero from "../../components/GaussianHero/GaussianHero";

const HomeLandingNew = () => {
    const [cookies, setCookie] = useCookies(['cookieAccepted']);
    const [showCookieConsent, setShowCookieConsent] = useState(false);

    // const [showMenu, setShowMenu] = useState(false);

    // const handleClickBurger = () => {
    //     setShowMenu(!showMenu);
    // }
    const handleAcceptCookie = () => {

        setCookie("cookieAccepted", true, { path: "/" });
        setShowCookieConsent(false);
        const tidioChat = document.getElementById("tidio-chat-iframe");
        if (tidioChat) tidioChat.classList.add("cookieaccepted");
    }
    React.useEffect(() => {
        if (cookies?.cookieAccepted) {
            setShowCookieConsent(false);
        } else {

            setShowCookieConsent(true);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <LandingTemplate>
            <BottomAlert show={showCookieConsent} onPressPrimary={handleAcceptCookie} />
            <div className="landing-hero maxw-container">
                <Fade left duration={800}>
                    <div className="left">
                        <div style={{
                            zIndex: 2,
                            marginTop: "3em",
                        }}>
                            <span className="tagline" style={{
                                color: "#5F31E0",
                                fontSize: "14px",
                                fontWeight: "700",



                            }}>BOOST YOUR ONLINE PRESENCE</span>
                            <p style={{
                                fontWeight: "800",
                                fontSize: "42px",
                                // marginRight:"3em"
                            }}>Gather <span className="primary-text">Links</span> in a Single Place and <span className="primary-text">Increase</span> your User Engagement.</p>
                            <div className="subtitle">
                                <p>Get your personalized URL and share across your favourite social media for free.</p>
                            </div>
                            <Link to="sign_up">
                                <button
                                    className="button"
                                    style={{
                                        alignSelf: "flex-start",
                                        width: "auto",
                                    }}
                                >Get Started</button>
                            </Link>
                        </div>
                        <GaussianHero />
                    </div>
                </Fade>
                <Fade right duration={800}>
                    <div className="right hero-image-container" style={{
                        justifyContent: "center",
                    }}>
                        <img className="" src={homeLanding} alt="main landing" style={{
                            width: "400px",
                            marginTop: "3em"
                        }} />
                        {/* <img src={smartphoneImage} style={{width:"280px",height:"500px"}} /> */}
                        {/* <div style={{
                        border: "9px solid #000",
                        borderRadius: "36px",
                     
                        width: "370px",
                        height: "500px",
                        overflow: "hidden",
                     
                    }}>
                       
                        <iframe src="https://perona.id/erindesong" style={{
                            width: "100%",
                            height: "100%",
                            border: "0px",
                            overflowY:"hidden"
                        }} title="perona profile" />
                        
                    </div> */}
                    </div>
                </Fade>
            </div>
            <HighlightPoints />
            {/* <Testimonials /> */}
            <Fade bottom>
                <SuperCallAction />
            </Fade>

        </LandingTemplate>
    )
}

export default HomeLandingNew;