import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MaxContainer from "../../components/MaxContainer";

// const Container = styled.li`
//   width:100%;
//   margin-bottom: 1.5em;
//   list-style:none;
// `;
const YoutubeWidgetPreview = ({ widget }) => {
  const [embedVideoUrl, setEmbedVideoUrl] = useState();
  const [videoTitle, setVideoTitle] = useState();
  useEffect(() => {
    const video = widget.videos[0];
    const videoUrl = video.url;
    const videoTitle = video.title;
    const params = new URL(videoUrl).searchParams;
    const v = params.get("v");
    if (!v) return;
    const embedVideoUrl = `https://www.youtube.com/embed/${v}`;
    setEmbedVideoUrl(embedVideoUrl);
    setVideoTitle(videoTitle);
  }, [widget.videos]);
  return (
    <MaxContainer>
      {/* // <Container> */}
      {
        embedVideoUrl && (
          <div style={{marginBottom:"1em"}}>
            <YoutubeFrame width="100%" height="315" src={embedVideoUrl} frameBorder="0" title={videoTitle} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ borderRadius: '.5em' }}></YoutubeFrame>
          </div>
        )
      }
      {/* // </Container> */}
    </MaxContainer>
  )
}

const YoutubeFrame = styled.iframe`
  border-radius:.5em;
@media (max-width: 415px) {
  box-sizing: border-box;
  padding-left:1em;
  padding-right:1em;
}
`;

export default YoutubeWidgetPreview;