import React from "react";
import Tippy from '@tippyjs/react';
import styled from "styled-components";

const icon = <i className="fas fa-question" />;

const TippyComponent = ({ popOverText }) => {
  return (
    <Tippy content={popOverText}>
      <IconPopup>{icon}</IconPopup>
    </Tippy>
  )
}

const Label = ({ children, popOverText, ...props }) => {

  return (
    <StyledLabel {...props}>{children} {" "} {popOverText && <TippyComponent popOverText={popOverText} />}</StyledLabel>
  )
}

const StyledLabel = styled.label`
  margin-bottom:.5em;
  display:flex;
  
`;

const IconPopup = styled.span`
  display:inline-flex;
  width:5px;
  height:5px;
  background:#5F31E0C4;
  padding:.5em;
  align-items:center;
  justify-content:center;
  border-radius:50%;
  margin-left:5px;
  i {
    
    color:white;
    font-size: .5rem;
  }
`;

export default Label;