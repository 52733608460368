import React from "react";
import styled from "styled-components";
import BottomSheet from "../../../components/BottomSheet";
import ThemeSlides from "./ThemeSlides";

const ThemePickerBottomSheet = ({
  onClickSlideItem,
  theme,
  themes,
  onClickSubmit
}) => {
  return (
    <ThemePickerBottomSheetStyled>
      <BottomSheet title="Pick Theme" renderContent={() => renderContent({ onClickSlideItem, onClickSubmit, theme, themes })} />
    </ThemePickerBottomSheetStyled>
  )
}

const renderContent = ({ onClickSlideItem, theme, themes, onClickSubmit }) => {
  return (
    <>

      <div style={{
        padding: "0em 1em"
      }}>
        <ThemeSlides onClickSlideItem={onClickSlideItem} activeTheme={theme} themes={themes} />
        <button className="button button-primary" onClick={onClickSubmit}>Pick this Theme</button>
      </div>
    </>
  )
}

const ThemePickerBottomSheetStyled = styled.div`

`;

export default ThemePickerBottomSheet;