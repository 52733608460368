import React, { useState } from "react";
import { ScreenContainer, WidgetPicker, SubmitButton } from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db } from "../../utilities/firebaseContext";

const WidgetPickerPage = ({
  userAuthData
}) => {
  const location = useLocation();
  const [selectedWidget, setSelectedWidget] = useState();
  const history = useHistory();
  const locationState = location?.state;
  const onboardingData = locationState?.onboardingData;
  const finishAddFirstWidget = onboardingData?.finishAddFirstWidget
  const finishPreviewWidget = onboardingData?.finishPreviewWidget;
 
  // console.warn("onboardingData zuaa ",onboardingData);
  // console.warn("finishAddFirstWidget ",finishAddFirstWidget);
  // console.warn("finishPreviewWidget ",finishPreviewWidget);
  const { t } = useTranslation();
  const uid = userAuthData?.uid;

  const handleSelectWidget = widget => {
    if(!widget.disabled) {
      setSelectedWidget(widget);

    } else {
      alert("This widget will be coming soon, stay tuned!");
    }
  }

  const handleClickConfirm = async (widget) => {
    console.warn("uid izz ",uid);
    if(!uid) return;
    if(selectedWidget?.name) {
      if(!finishAddFirstWidget)  {
        await db.collection("users").doc(uid).update({
          "onboarding.finishAddFirstWidget":true,
        });
      }
      history.push(`/dashboardv2/add_widget/${selectedWidget.name}`);
    } else {
      alert("Please pick a widget");
    }
  }
  return (
    <ScreenContainer>
      <h3 style={{ marginBottom: '.5em' }}>{t('PICK_WIDGET')}</h3>
      <WidgetPicker
        selectedWidget={selectedWidget}
        setSelectedWidget={setSelectedWidget}
        onSelectWidget={handleSelectWidget}
        onClickConfirm={handleClickConfirm}
        finishAddFirstWidget={finishAddFirstWidget}
        finishPreviewWidget={finishPreviewWidget}
        style={{ marginBottom: '2em' }}
      />
      {/* <button onClick={handleClickConfirm}>Confirm</button> */}
      <div style={{ marginTop: '-1.15em' }}>
        <SubmitButton value="Select" onClick={handleClickConfirm} />

      </div>

    </ScreenContainer>
  );
}

export default WidgetPickerPage;