import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
const LogoutPage = () => {
  const history = useHistory();
  useEffect(() => {
    history.push("/");
    // eslint-disable-next-line
  }, [])
  return (
    <p>Redirecting</p>
  )
}

export default LogoutPage;