import React, { useEffect, useState, useContext } from "react";
import LinkWidget from "../../components/LinkWidget/LinkWidget";
import WhiteCard from "../../components/WhiteCard/WhiteCard";
import { Link } from "react-router-dom";
import { FireStoreContext } from "../../utilities/firebaseContext";
import { Widget } from "../../Entities";
import "../../styles/utility.scss";
import "../../templates/DefaultLayoutV2/DefaultLayoutV2.scss";
import UserAuthContext from "../../context/UserAuthContext";
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";
import { AddButton, SubmitButton } from "../../components";
import styled, { ThemeContext } from "styled-components"
import { Input } from "../Form";
import "../../assets/vendors/typicons/typicons.min.css";
import { ScreenContainer } from "../";
import { ToastContainer } from "react-toastify";

const LinkWidgetEditor = ({ toastCall, setToastCall, ...props }) => {
  const location = useLocation();
  const match = useRouteMatch();
  const [crudType, setCrudType] = useState("read");
  const [widget, setWidget] = useState(location.widget);
  const [title, setTitle] = useState();
  const [url, setUrl] = useState();
  const db = React.useContext(FireStoreContext);
  const userAuthData = useContext(UserAuthContext);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!userAuthData?.uid) return;
    setSubmitting(true);
    const uid = userAuthData?.uid;
    if (crudType === "update") {
      const title = e.target.title.value;
      const url = e.target.url.value;
      /* update links state */
      const widgetId = match.params.id;
      const linkIndex = match.params.index;
      widget.links[linkIndex] = { title, url };
      const widgetLinks = widget.links;
      const w = new Widget(uid, db);
      const updateWidget = await w.updateWidget({
        widgetId,
        field: 'links',
        value: widgetLinks
      });
      if (updateWidget) {
        setToastCall({
          type: "success",
          message: "Link Successfully updated"
        });
      }
      setSubmitting(false);
      history.goBack();


    } else {
      /* make link obj */
      const link = { title, url };
      /* make widget  obj */
      const newWidget = widget;
      newWidget.links = [
        ...newWidget.links, link
      ];

      /* insert widget links to db */
      const widgetId = match.params.id;
      const userRef = await db.collection('users').doc(uid);
      await userRef.collection('widgets').doc(widgetId).set(newWidget);
      // alert("Success Insert New Link");
      setToastCall({
        type: "success",
        message: "New Link successfully added"
      });
      setSubmitting(false);
      history.goBack();
    }

  }

  useEffect(() => {
    const segments = location.pathname.split('/');
    const action = segments[4];
    if (!action) {
      setCrudType('read');
    } else if (action === "add") {
      setCrudType('create');
    } else if (action === "edit") {
      setCrudType("update");
    }

  }, [location.pathname]);

  useEffect(() => {
    /* prevent fire api 2 times when widget already in state */
    if (widget) return;
    async function start() {
      const uid = userAuthData?.uid;
      const widgetId = match.params.id;
      const widget = new Widget(uid, db);
      /*unsubscribe =*/ await widget.fetchWidgetSnapshot(widgetId, widgetItem => {
        setWidget(widgetItem);

      });
    }
    if (userAuthData?.uid) start();
    // return (() => {
    /* Unsubscribe */
    // unsubscribe();
    // })
  }, [db, match.params.id, widget, userAuthData]);


  return (
    <>
      <ToastContainer autoClose={2000} />
      <TopWidgetItemBar>
        <div className="left-side">
          <Link title="Edit Link Setting" to={{ pathname: `/dashboardv2/widgets/${match.params.id}/add`, crudType: 'create' }}><AddButton theme={theme}><i className="fas fa-plus" /> Add New Link</AddButton></Link>
        </div>

        <div className="right-side">
          {/* <WidgetItemSettingButton> */}
          {/* <Link to="/dashboard"><i className={`typcn typcn-cog-outline icon`}></i></Link> */}
          {/* </WidgetItemSettingButton> */}


        </div>
      </TopWidgetItemBar>

      {(crudType === "create" || crudType === "update") && (
        // <WhiteCard>
        <ScreenContainer>
          <CreateEditForm
            handleOnSubmit={handleOnSubmit}
            setTitle={setTitle}
            setUrl={setUrl}
            title={title}
            url={url}
            db={db}
            crudType={crudType}
            userAuthData={userAuthData}
            match={match}
            submitting={submitting}
            {...props} />
          {/* // </WhiteCard> */}
        </ScreenContainer>
      )}

      {widget && crudType === "read" && (
        <WhiteCard title="Links">
          <LinkWidget widget={widget} mode="edit" userAuthData={userAuthData} />
        </WhiteCard>
      )}
    </>
  )
}

const CreateEditForm = ({ handleOnSubmit, setTitle, setUrl, title, url, db, crudType, match, submitting, ...props }) => {
  const [btnText, setBtnText] = useState("Add");
  const userAuthData = useContext(UserAuthContext);
  useEffect(() => {
    if (crudType === "update") setBtnText("Update");

  }, [crudType]);

  useEffect(() => {
    async function start() {
      const uid = userAuthData?.uid;
      const widgetId = match?.params.id;
      const linkIndex = parseInt(match?.params?.index);
      const widget = new Widget(uid, db);
      const widgetLinks = await widget.fetchWidget(widgetId);
      if (widgetLinks?.links && match?.params?.id && match?.params?.id) {
        const widgetLinkByIndex = widgetLinks.links[linkIndex];
        setTitle(widgetLinkByIndex?.title);
        setUrl(widgetLinkByIndex?.url);

      }
    }
    if (crudType === "update" && userAuthData?.uid) start();
    return (() => console.log('cleanup createeditform'));
  }, [crudType, db, match, setTitle, setUrl, userAuthData]);
  return (
    <form onSubmit={handleOnSubmit}>
      <label>Name</label>
      <Input type="text" name="title" value={title || ""} className="new-widget" placeholder="Link Title" onChange={e => setTitle(e.target.value)} autoComplete="off" required />
      {/* <input type="text" name="title" value={title || ""} className="new-widget" placeholder="Link Title"  onChange={e => setTitle(e.target.value)} required /> */}
      <label>URL</label>
      <Input type="url" name="url" value={url || ""} className="new-widget" placeholder="URL..." autoComplete="off" onChange={e => setUrl(e.target.value)} required />
      {/* <input type="url" name="url" value={url || ""} className="new-widget" placeholder="URL..." autoComplete="off" onChange={e => setUrl(e.target.value)} required /> */}
      {/* <button type="submit" className="btn-primary w-100">{btnText}</button> */}
      <SubmitButton value={btnText} submitting={submitting} spinnerColor="white" />
    </form>
  )
}

const TopWidgetItemBar = styled.div`
  display:flex;
  align-items:flex-start;
  .right-side {
    margin-left:auto;
    border:1px solid #ebe1f5;
    border-bottom:2px solid #ebe1f5;
    border-radius:25%;
    padding-left:.25em;
    padding-right:.25em;
    padding-bottom:.25em;
    a {
      color:#c1a7e5 !important;
    }
      
  }
`;

// const WidgetItemSettingButton = styled.div`
// display:flex;
// justify-content:center;
// align-items:center;

//   a {
//     display:flex;
//     align-items:center;
//     font-size:2rem;
//   }
// `
export default LinkWidgetEditor;