import React from "react";
import styled from "styled-components";

const AlertBox = ({ children, type, title, ...props }) => {


  return (
    <AlertBoxStyled type={type}>
      {title && <h3 style={{marginBottom:".3em"}}>{title}</h3>}
      {children}
    </AlertBoxStyled>
  )
}

const getBackgroundColorByType = type => {
  const m = {
    warning: "#fff3cd",
    success: "#d4edda",
    danger: "#f8d7da",
    info: "#d1ecf1",
  }
  return m[type] ? m[type] : m.info;
}

const getFontColorByType = type => {
  const m = {
    warning: "#856404",
    success: "#155724",
    danger: "#721c24",
    info: "#0c5460",
  }
  return m[type] ? m[type] : m.info;
}

const getBorderColorByType = type => {
  const m = {
    warning: "#ffeeba",
    success: "#c3e6cb",
    danger: "#f5c6cb",
    info: "#bee5eb"
  }
  return m[type] ? m[type] : m.info;
}

const AlertBoxStyled = styled.div`
  background: ${props => getBackgroundColorByType(props.type)};
  border:1px solid ${props => getBorderColorByType(props.type)};
  padding: 1em;
  margin-bottom:2em;
  border-radius:5px;
  color:${props => getFontColorByType(props.type)};
`;

export default AlertBox;