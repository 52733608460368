import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

const Button = ({ title, children, navigateTo, onClick, ...props }) => {
  const history = useHistory();


  // eslint-disable-next-line 
  const handleClick = () => {
    if (navigateTo) {
      history.push(navigateTo);
    } else {
      if (onClick) onClick();
    }
  }
  return (
    <StyledButton {...props} onClick={handleClick}>{children ? children : title}</StyledButton>
  )
}

const StyledButton = styled.button`
  background:pink;
`;

export default Button;