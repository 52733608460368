import React from "react";
import TopBar from "./TopBar";
import "./DefaultLayoutV3.scss";

const DefaultLayoutV2 = ({ children, title, ...props }) => {

  return (
    <div className="default-layout-pageV3">

      <header className="headerboxV3">
        <TopBar title={title} />
        
        {/* <div id="profile-picture" style={profilePictureStyle}> */}
        {/* <h2>{title}</h2> */}
      </header>
      <div className="main-content">
        {children}
      </div>
    </div>
  )
}

export default DefaultLayoutV2;