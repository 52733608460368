import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { WidgetItemBox } from '../../pages/Widgets/WidgetItem';

export function SortableItem(props) {
  const { id, label } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    // background: "red",
    marginBottom: "1em"
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {/* <span>hoho</span> */}
      {/* <span>{label}</span> */}

      <WidgetItemBox
        {...{
          widgetGroupExpand: false,
          toggleWidgetGroupExpand: () => { },
          widgetType: "",
          widgetTitle: label,
          widget: {},
          handleClickContextIcon: () => { },
          onClickEdit: () => props?.onClickEdit(id, props?.index)
          // id,
          // children,
        }}
      />
      {/* ... */}
    </div>
  );
}

export default SortableItem;