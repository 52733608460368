import React from "react";

const GaussianHero = () => {
  return (
    <div style={{
      // background: "red",
      top: 75,
      width: 350,
      height: 350,
      left:-50,
      position: "absolute",
      // zIndex:0,
      opacity:.5
    }}>
      <div style={{
        position: "relative",
        width: "237px",
        height: "162px",
        background: "rgba(255, 200, 3, 0.4)",
        left:"150px",
        filter: "blur(144px)"
      }}></div>
      <div style={{
        position: "relative",
        // left:100,
        width: "237px",
        height: "162px",
        top:-100,
        background: "rgba(255, 3, 139, 0.3)",
        filter: "blur(100px)",
      }}></div>

    </div>
  )
}

export default GaussianHero;