import React, { useEffect, useState } from "react";
import LinkWidgetForm from "../../components/LinkWidgetV2/LinkWidgetForm";
import YoutubeWidgetForm from "../../components/YoutubeWidget/YoutubeWidgetForm";
import TextWidgetForm from "../../widgets/Text/TextWidgetForm";
import { CopyableWidgetForm, SingleLinkWidgetForm, ProfileCoverWidgetForm } from "../../widgets";
import { ScreenContainer, } from "../../components";
import { ToastContainer, } from 'react-toastify';
import { toast } from "../../utilities/toastWrapper";
import { useParams } from "react-router-dom";
import SocialMediaWidgetForm from "../../widgets/SocialMedia/SocialMediaWidgetForm";
import { Fade } from "react-reveal";

const WidgetForm = ({ crud, setToastCall, toastCall }) => {
  const [widgetType, setWidgetType] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const params = useParams();
  const widget_type = params?.widget_type;

  const toastObj = window.sessionStorage.getItem("toastObj");
  // const title = crud === "create" ? "Insert New Widget" : "Edit Widget";

  // const handleWidgetTypeChange = (e) => {
  //   const selectedWidgetType = e.target.value;
  //   setWidgetType(selectedWidgetType);
  // }

  useEffect(() => {
    if (toastObj) {
      console.log("toastObj is", toastObj);
    }
    // eslint-disable-next-line
  }, [toastObj]);

  useEffect(() => {
    if (!widget_type) {

    } else {
      setWidgetType(widget_type);
    }
    // eslint-disable-next-line
  }, [/*widget_type*/]);


  return (
    // <DefaultLayout headerTitle={title}>
    <ScreenContainer>
      <ToastContainer autoClose={3000} />
      {/* {crud === "create" && (
        <select className="default-select" onChange={handleWidgetTypeChange} required>
          <option value="">Pilih Widget</option>
          <option value="link">Link</option>
          <option value="youtube">Youtube</option>
        </select>
      )} */}
      <Fade top duration={600} effect="fadeInUp"
        distance={"5px"}
      >
        <div>
          {widgetType === 'single_link' && <SingleLinkWidgetForm submitting={submitting} setSubmitting={setSubmitting} toast={toast} setToastCall={setToastCall} />}
          {widgetType === 'link' && <LinkWidgetForm submitting={submitting} setSubmitting={setSubmitting} toast={toast} setToastCall={setToastCall} />}
          {widgetType === 'youtube' && <YoutubeWidgetForm submitting={submitting} setSubmitting={setSubmitting} toast={toast} setToastCall={setToastCall} />}
          {widgetType === 'text' && <TextWidgetForm submitting={submitting} setSubmitting={setSubmitting} toast={toast} setToastCall={setToastCall} />}
          {widgetType === 'copyable' && <CopyableWidgetForm submitting={submitting} setSubmitting={setSubmitting} toast={toast} setToastCall={setToastCall} />}
          {widgetType === 'profile_cover' && <ProfileCoverWidgetForm submitting={submitting} setSubmitting={setSubmitting} toast={toast} setToastCall={setToastCall} />}
          {widgetType === 'social_media' && <SocialMediaWidgetForm submitting={submitting} setSubmitting={setSubmitting} toast={toast} setToastCall={setToastCall} />}
        </div>
      </Fade>
    </ScreenContainer>
    // </DefaultLayout>
  )
}

export default WidgetForm;