import React, { useEffect, useState } from 'react';
import 'firebaseui/dist/firebaseui.css';
import './../App.scss';
import './login-page.scss';
import socialhero from '../assets/social-hero.svg';
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import { Link } from "react-router-dom";
import LanguageSwitcher from "../components/LanguageSwitcher";


const AUTH_CALLBACK_URL = process.env.REACT_APP_AUTH_CALLBACK_URL;
const USE_FIREBASEUI = false;


const LoginPage = ({ firebase, ui, setUserAuthData, uid, setUid, db, history, authType }) => {

  const ENV = process.env.REACT_APP_ENVIRONMENT;
  const [authTypeState, setAuthTypeState] = useState("sign_in");

  const toggleAuthType = e => {
    e.preventDefault();
    const isSignIn = authTypeState === "sign_in" ? true : false;
    setAuthTypeState(isSignIn ? "sign_up" : "sign_in");
  }


  const doSendEmailVerification = (e) => {
    e.preventDefault();
    // alert("testing send");
    firebase.auth().currentUser.sendEmailVerification()
      .then(() => {
        // Email verification sent!
        alert("Email Verification sent! Check your mail box");
        // ...
      }).catch(e => {
        console.log("error send email confirm", e);
        alert("Too many request, please try again");
      });
  }

  useEffect(() => {
    if (authType === "sign_up") {
      setAuthTypeState("sign_up");
    }
  }, [authType]);

  console.log("authType iz", authType);

  useEffect(() => {
    // if(ENV==="production") return;
    if (!USE_FIREBASEUI) return;
    ui.start('div#login-form', {
      signInFlow: 'popup',
      signInSuccessUrl: `${AUTH_CALLBACK_URL}/auth_success`,
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          const uid = authResult.user.uid;
          console.log('Auth result:');
          console.log(authResult);
          console.log(uid);
          // setUserAuthData(authResult);
          setUid(uid);
          return true;
        },
        uiShown: () => {
          /* after the widget has been rendered */
          const loader = document.getElementById('loader');
          if (loader) {
            loader.style.display = 'none';

          }
        },
      },
      signInOptions: [
        {
          provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          scopes: [
            'email',
          ],
        },
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          fullLabel: "lalala"
        }

      ],
      // Terms of service url.
      tosUrl: 'https://personagram.me/tos',
      // Privacy policy url.
      privacyPolicyUrl: 'https://personagram.me/privacy',

    })
  }, [firebase.auth.FacebookAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID, ui, setUserAuthData, setUid, ENV]);

  useEffect(() => {
    if (uid) {
      window.sessionStorage.setItem('uid', uid);
    }
  }, [uid]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!firebase) return;
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        // history.push("/dashboardv2/widgets");
      } else {
        // alert("not loggedin");

      }
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => { // detaching the listener
      if (user) {
        // ...your code to handle authenticated users. 
      } else {
        // No user is signed in...code to handle unauthenticated users. 
      }
    });
    return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting. 
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const tidio = document.getElementById("tidio-chat");
    if (tidio) tidio.remove();

  }, []);
  return (
    <div className="page" id="login-page">
      {
        // ENV === "development"
        true
        && (
          <>
            <aside className="hero">
              {USE_FIREBASEUI === true && <div id="loader">Loading...</div>}

              <h1><Link to="/">perona</Link></h1>
              <span className="slogan">Boost your online presence at ease.</span>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={socialhero} alt="social hero" className="social-hero" style={{ width: '19em' }} />
              </div>
              <p style={{ fontSize: '.85rem' }}>Copyright &copy; {new Date().getFullYear()} by dytra</p>
              <p style={{ fontSize: '.85rem' }}>All rights reserved.</p>
            </aside>
            <aside className="form" style={{ paddingBottom: '5em', paddingRight: '5em', paddingLeft: '5em', height: "100vh" }}>
              <div style={{ paddingTop: '2em', marginBottom: '2em', display: 'flex', justifyContent: 'flex-end' }}>
                <LanguageSwitcher />
              </div>
              <div id="login-form">
                {authTypeState === "sign_in" && <SignInForm firebase={firebase} db={db} history={history} toggleAuthType={toggleAuthType} />}
                {authTypeState === "sign_up" && <SignUpForm firebase={firebase} db={db} history={history} toggleAuthType={toggleAuthType} setAuthTypeState={setAuthTypeState} />}
                {authTypeState === "email_verification_sent" && <EmailSentBox sendEmail={doSendEmailVerification} setAuthTypeState={setAuthTypeState} />}
                {/* <button onClick={handleClickTestEmail}>Test Send Email hehe</button> */}
              </div>
              {/* <footer className="form-footer">Powered by personagram. Allrights reserverd (c) 2020</footer> */}
            </aside>

          </>
        )}
      {ENV === "xxx" && <p>Signup is currently disabled. Please contact <a href="mailto:dytra.io@icloud.com">admin</a></p>}
    </div>

  );
}

const EmailSentBox = ({ sendEmail,setAuthTypeState }) => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column"
    }}>
      <h1 className="text-center">Email Verification Sent</h1>
      <div style={{
        display: "flex", justifyContent: 'center',
        alignItems: "center",
        margin: "1.5em 0 2em"
      }}>
        <i className="far fa-check-circle" style={{
          fontSize: "3.5rem",
          color: "var(--success)"
        }} />
      </div>
      <p className='text-center' style={{ marginBottom: 0 }}>An email containing the verification link has been sent to your email address.</p>
      <p className='text-center'>If you haven't received it yet, please check your spam folder.</p>
      {/*// eslint-disable-next-line */}
      <p className='text-center' style={{ marginBottom: 0 }}><a href="/#" onClick={sendEmail} >Click here</a> if you still didn't receive the email.</p>
      {/*// eslint-disable-next-line */}
      <p className='text-center'><a href="/#" onClick={() => setAuthTypeState("sign_in")}>Sign In</a> if you already clicked the confirmation link</p>
    </div>
  )


}

export default LoginPage;