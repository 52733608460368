import React from "react";
import styled from "styled-components";

const HeaderConfig = ({ children }) => {
  return (
    <Header>
      {children}
    </Header>
  );
}

const Header = styled.div`
  display:flex;
  align-items: center;
  margin-bottom: 1em;
  h2 {
    margin-right:.5em;
  }
`;

export default HeaderConfig;