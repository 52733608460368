import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components"
import styled from "styled-components";
import LinkWidgetPreviews from "../../widgets/LinkWidget/LinkWidgetPreviews";
import YoutubeWidgetPreview from "../../widgets/YoutubeWidget/YoutubeWidgetPreview";
import { TextWidgetPreview, CopyableWidgetPreview, SingleLinkWidgetPreview, ProfileCoverWidgetPreview } from "../../widgets";
import { useLocation } from "react-router-dom";
import SpinnerFull from "../SpinnerFull";
import { ToastContainer } from "react-toastify";
import { VerifiedBadge } from "../";
import { LAVENDER_THEME, SKYBLUE_THEME, H4X0R_THEME, EWEN_THEME } from "../../themes";
import SocialMediaWidgetPreview from "../../widgets/SocialMedia/SocialMediaWidgetPreview";
// import UserDataContext from "../../context/UserDataContext";

const PreviewWidgets = ({ userAuthData, userData, widgets, setWidgets, ...props }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const userTheme = props?.theme ?? userData?.theme;
  const [theme, setTheme] = useState(LAVENDER_THEME);
  const [filteredWidgets, setFilteredWidgets] = useState([]);
  console.warn("themizz ", theme);
  console.warn("usertheme kung ", userTheme);
  // const {userData} = React.useContext(UserDataContext);
  // const themeCtx = userData?.themeCtx;

  const [showDisplayName,/* setShowDisplayName*/] = useState(false);

  useEffect(() => {
    //search for ProfileCover widget, if available, hide DisplayName component
    const profileCoverAvailable = userData?.profileImagePath;
    if (profileCoverAvailable) {
      // setShowDisplayName(false);
    }
  }, [widgets, userData]);

  useEffect(() => {
    if (!userTheme || !userData) return;
    // alert("lol");
    let theme;
    let currentTheme = userTheme;
    if (props?.theme) {
      // alert("hey");
      currentTheme = props?.theme;
    }
    console.warn("currentTheme yoooo ",currentTheme);
    if (currentTheme === "lavender") {
      theme = LAVENDER_THEME;
    } else if (currentTheme === "skyblue") {
      theme = SKYBLUE_THEME;
    } else if (currentTheme === "h4x0r") {
      theme = H4X0R_THEME;
    } else if(currentTheme === "ewen") {
      theme = EWEN_THEME;
    }else {
      if (props?.theme) {
        theme = props?.theme
      } else {
        theme = userData?.customThemeColors;
      }

    }
    console.warn("theme izzx ", theme);
    setTheme(theme);
  }, [userTheme, userData, props.theme]);

  useEffect(() => {
    if (/*widgets?.length > 0 &&*/ userData) {
      console.log("widgeidggg", widgets);
      const profileCoverWidget = {
        "id": (Math.random() + 1).toString(36).substring(7),
        "profileImagePath": userData?.profileImagePath,
        "bio": userData?.bio,
        "subTitle": userData?.bio,
        "type": "profile_cover",
        "order": 0
      }
      setFilteredWidgets([profileCoverWidget, ...widgets]);
    }
  }, [widgets, userData]);
  return (
    <ThemeProvider theme={theme}>
      <MainProfile className="main-profile" pathname={pathname}>
        {/* <Container pathname={pathname}> */}
        <ToastContainer
          autoClose={2000}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          hideProgressBar
          position="bottom-center"
        />
        <HeaderBoxv2>
          {/* hello */}
          {/* <div className="profile-picture" style={{ backgroundImage: "url('https://i.pravatar.cc/300'" }}></div> */}
        </HeaderBoxv2>
        {(!userData || !widgets) && (
          <SpinnerFull />
        )}

        {showDisplayName && (

          <DisplayName>
            {userData?.verifiedUser ? <VerifiedBadge /> : null}
            <h1>{userData?.displayName ? userData?.displayName : userData?.username}</h1>
          </DisplayName>

        )}
        {filteredWidgets && filteredWidgets.map((widget, itemIndex) => {

          let widgetComponent;
          if (widget.type === 'link') {
            widgetComponent = <LinkWidgetPreviews key={itemIndex} widget={widget} itemIndex={itemIndex} userData={userData} />
          } else if (widget.type === 'youtube') {
            widgetComponent = <YoutubeWidgetPreview key={itemIndex} widget={widget} itemIndex={itemIndex} userData={userData} />
          } else if (widget.type === "text") {
            widgetComponent = <TextWidgetPreview key={itemIndex} widget={widget} itemIndex={itemIndex} userData={userData} />
          } else if (widget.type === "copyable") {
            widgetComponent = <CopyableWidgetPreview key={itemIndex} widget={widget} itemIndex={itemIndex} userData={userData} />
          } else if (widget.type === "single_link") {
            widgetComponent = <SingleLinkWidgetPreview key={itemIndex} widget={widget} itemIndex={itemIndex} userData={userData} />
          } else if (widget.type === "profile_cover") {
            widgetComponent = <ProfileCoverWidgetPreview key={itemIndex} widget={widget} itemIndex={itemIndex} userData={userData} />
          } else if(widget.type === "social_media") {
            widgetComponent = <SocialMediaWidgetPreview key={itemIndex} widget={widget} itemIndex={itemIndex} userData={userData} />
          }
          return widgetComponent;
        })}
        {/* </Container> */}
      </MainProfile>
    </ThemeProvider >
  )
}

const DisplayName = styled.div`
    text-align:center;
    margin-bottom:2em;
    margin-top:1em;
    display:flex;
    justify-content:center;
    align-items:center;
  `;

const MainProfile = styled.div`
/* margin-top:-4em; */
min-height:100vh;
/* padding-left:1em; */
/* padding-right:1em; */
box-sizing:border-box;
padding-bottom:4.5em;
padding-top:${props => props.pathname.includes("/preview") ? "4.5em" : "0"};
padding-left:${props => props.pathname.includes("/preview") ? "1em" : "0"};
padding-right:${props => props.pathname.includes("/preview") ? "1em" : "0"};
background: ${props => props.theme?.background};
transition: all .3s ease-in-out;
@media only screen and (min-width: 768px) {
  padding-top:${props => props.pathname.includes("/preview") ? "5em" : "1.5em"};
}
@media (max-width:415px) {
  /* padding-top:0; */
  }

`;


const HeaderBoxv2 = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  .profile-picture {
    margin-top:3em;
    background-size: cover;
    width: 100px;
    height: 100px;
    border-radius:50%;
    margin-bottom:.5em;
    text-align:center;
  }
`;

export default PreviewWidgets