import React from "react";
import Tippy from '@tippyjs/react';

const VerifiedBadge = () => {
  return (
    <Tippy content="This user has been verified">
      <i className="fas fa-check-circle" style={{
        color: '#66D8C6',
        fontSize: ".85rem",
        marginRight: '.5em',
        marginTop: '.5em',
      }}></i>
    </Tippy>
  )
}
export default VerifiedBadge;