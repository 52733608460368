import React from 'react';

const LinkWidgetContent = ({contentIndex,widgetContent,itemIndex,handleClickLinkWidgetContextIcon,mode}) => {
  const {url,title} = widgetContent;
  
  return(
    <li key={contentIndex}>
    <a href={url} target="_blank" rel="noopener noreferrer">
      <h1>{title}</h1></a>
    <span className="context-icon" data-widget-group-id={itemIndex} data-widget-content-id={contentIndex} onClick={(e) => handleClickLinkWidgetContextIcon({e,url,title,contentIndex})}>:</span>
  </li>
  )
}

export default LinkWidgetContent;