export const array_move = (arr, fromIndex, toIndex) => {
  console.log(`moving inde ${fromIndex} to ${toIndex}`);
  let newArr = arr.slice();
  var element = newArr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
};

export const isEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isAlphaNumeric = string => {
  const re = /[^a-z0-9_.]/gi;
  return !re.test(String(string).toLowerCase());
}