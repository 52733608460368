import React, { useState } from "react";
import styled from "styled-components";
import { TextInput, SubmitButton } from "../components";
import { AlertBox } from "../components/forms";
import { useTranslation } from "react-i18next";
import { analytics } from "../utilities/firebaseContext";

const SignInForm = ({ firebase, db, history, toggleAuthType }) => {

  const [submitting, setSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertObject, setAlertObject] = useState();
  const { t } = useTranslation();
  // const auth = firebase.auth();

  const handleSubmit = e => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    doLogin({ email, password });

  }

  const doLogin = async ({ email, password }) => {
    setSubmitting(true);
    const successSignIn = await signIn({ email, password });
    if (successSignIn === true) {
      setSubmitting(false);
      console.log("success sign in");
      analytics.logEvent('login', {
        method: 'email'
      });
      history.push("/dashboardv2/widgets");
    } else {
      const errorPhrase = `${successSignIn?.errorCode}: ${successSignIn?.errorMessage}`;
      console.error(errorPhrase);
      setAlertObject({ title: "Gagal Login", type: "danger", message: errorPhrase });
      setShowAlert(true)
      setSubmitting(false);
    }
  }

  const signIn = async ({ email, password }) => {
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(async (user) => {
          console.log("user kyun",user);
          const emailVerified = user?.user?.emailVerified;
          if (emailVerified) {
            resolve(true);
          } else {
            await user?.user?.sendEmailVerification();
            resolve({ errorCode: "EMAIL_NOT_VERIFIED", errorMessage: "Email Not Verified, Please verify by clicking the confirmation link that we have been sent to your email address" });
          }

        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          resolve({ errorCode, errorMessage });
        });

    });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-head">
        <h1>Sign In</h1>
        <p>{t('DONT_HAVE_ACCOUNT')} <a href="/sign_up" onClick={toggleAuthType}>{t('SIGN_UP')}</a> {t('HERE')}.</p>
      </div>
      {showAlert && <AlertBox type={alertObject.type} title={alertObject.title}>{alertObject.message}</AlertBox>}
      {/* <input type="email" name="email" placeholder="Email" /> */}
      <TextInput type="email" name="email" placeholder="Email" required />
      {/* <input type="password" name="password" placeholder="Password" /> */}
      <TextInput type="password" name="password" placeholder="Password" required />
      {/* <input type="submit" value="Masuk" /> */}
      <SubmitButton value="Sign In" submitting={submitting} spinnerColor="white" />
    </Form>
  )
}

const Form = styled.form`
  display:flex;
  flex-direction:column;
`;

export default SignInForm;