import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import MaxContainer from '../../components/MaxContainer';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import styled from 'styled-components';
import { Spinner, } from '../../components';

function ArticlePage() {
  const route = useRouteMatch();
  const postId = route?.params?.id;
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function start() {
      if (!postId) return;
      setLoading(true);
      const url = `https://public-api.wordpress.com/rest/v1.1/sites/peronaid.wordpress.com/posts/${postId}`;
      const res = await fetch(url);
      const article = await res.json();
      console.log("article ", article);
      const { title, content, post_thumbnail
      } = article || {};
      setTitle(title);
      setContent(content);
      setThumbnail(post_thumbnail?.URL);
      setLoading(false);
    }
    start();
  }, [postId]);
  return (
    <div style={{ marginTop: ".5em" }}>
      <article>
        <MaxContainer>
          <ScreenContainer>
            {/* <SpinnerFull /> */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spinner show={loading} />
            </div>
            <h1>{title}</h1>
            <ThumbnailImage src={thumbnail} />
            <ArticleContent className="content" dangerouslySetInnerHTML={{ __html: content }} style={{ marginTop: "1em" }} />
          </ScreenContainer>
        </MaxContainer>
      </article>
    </div>);
}

const ThumbnailImage = styled.img`
  width:100%;
  margin-top:1em;
  border-radius:12px;
`;

const ArticleContent = styled.div`
  line-height:1.65rem;
  margin-bottom:2em;
  li {
    list-style-position: inside;
    text-indent: -1em; 
    padding-left: 1em;  
    
  }
  p {
    margin-top:1.25rem;
  }
`;

export default ArticlePage;