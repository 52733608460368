import React from 'react';
import styled from "styled-components"
import Fade from 'react-reveal/Fade';


const BottomAlert = ({
  onPressPrimary,
  show
}) => {
  // const [show, setShow] = useState(true);

  const handleClick = () => {
    if (onPressPrimary) {
      onPressPrimary();
    }
    // setShow(!show);
  }
  return (
    <>
      {show && (
        <StyledDiv>
          <Fade bottom duration={800}>
            <div className="thecontainer" style={{
              width: "90%",
              background: "#fff",
              // background: "blue",
              boxShadow: "0px 6px 12px rgba(97, 97, 97,0.25)",
              padding: ".85em .85em",
              paddingLeft: "1.5em",
              // paddingLeft:"1.5em",
              borderRadius: "12px",
              fontSize: "1.2rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
              <div style={{
                display: "flex",
                flex: "1"
              }}>
                <p>This website uses cookies in order to offer you the most relevant information. Please accept cookie to maximize the usability of our app.</p>
              </div>
              <div
                className='button-container'
                style={{
                  display: "flex",
                  // flex: "1",
                  // background: "red"
                }}>
                <button
                  onClick={handleClick}
                  className='button' style={{
                    margin: 0,
                  }}>Got It</button>
              </div>
            </div>

          </Fade>
        </StyledDiv>

      )}
    </>
  )
}

const StyledDiv = styled.div`
  position:fixed;
  /* background:#fff; */
  display: flex;
  justify-content: center;
  margin-bottom:1em;
  /* margin-bottom:2em; */
  /* margin:0 1em;  */
  /* margin-left:1em; */
  /* margin-right:1em; */
  /* margin-right: 100em; */
  
  bottom:0;
  width:100%;
  /* left:10%; */
  /* padding:1em 2em; */
  box-sizing: border-box;
  border-radius: 12px;
  z-index: 999;

  @media only screen and (max-width: 600px) {
    .thecontainer {
      flex-direction:column !important;
      margin-left:1em;
      margin-right:1em;
      padding: .85em .75em !important; 
      .button-container {
        width:100%;
        margin-top:.51em;
      }
    }
  }
`;

export default BottomAlert;