import React from "react";
import styled from "styled-components";
import { toast } from "../../utilities/toastWrapper";
import MaxContainer from "../../components/MaxContainer";

const CopyableWidgetPreview = ({ widget }) => {
  const copyText = widget?.copyText;
  const handleClickCopy = () => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(copyText);
    } else {
      navigator.clipboard.writeText(copyText).then(function () {
        toast.dark("Text copied successfully");
      }, function (err) {
        // toast.dark("Failed to copy");
        // console.error(err);
        // alert(err);
        fallbackCopyTextToClipboard(copyText);
      });

    }
  }

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      if (successful) {
        toast.dark("Text copied successfully");

      } else {
        toast.dark("Failed to copy.");
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }
  return (
    <MaxContainer>
      <Container>
        {/* <div>a</div> */}
        <input type="text" value={copyText} disabled />
        <button onClick={handleClickCopy}><i className="far fa-copy"></i></button>
      </Container>
    </MaxContainer>
  )
}

const Container = styled.div`
  display:flex;
  height:3em;
  /* background: #f7f7f7; */
  background:#f9f5fa;
  justify-content:space-between;
  border-radius:10px;
  margin-bottom:1.5em;
  box-sizing:border-box;
  @media (max-width:415px) {
    /* background:red; */
    margin-left:1em;
    margin-right:1em;
  }
  div {
    /* content:'empty'; */
    min-width:5em;
    visibility:hidden;
  }
  input {
    /* flex:9; */
    background:transparent;
    border:0;
    text-align:center;
    font-size:1.25rem;
    font-weight:bold;
    color:black;
  }
  button {
    cursor:pointer;
    background:transparent;
    /* flex:1; */
    min-width:5em;
    font-weight:bold;
    font-size:1.25rem;
    border:0;
    transition: all .3s ease-in-out;
    border-top-right-radius:10px;
    border-bottom-right-radius:10px;
    &:hover {
      background:#f0edf5;
    }
  }
`;

export default CopyableWidgetPreview;