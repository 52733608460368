import React from 'react';

const LoginPagev2 = () => {
  return(<div class="login-page">
    <aside>
      <h1>personagram</h1>
    </aside>
    <aside>
      <h1>login</h1>
    </aside>
  </div>);
}

export default LoginPagev2;