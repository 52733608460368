import React from "react";

const FooterLanding = () => {
  return(
    <footer className="landing-footer maxw-container" style={{
      flexDirection:"column"
    }}>
      <div className="main-footer">
        <div className="left">
          <h1 className="text-logo">perona</h1>
          <p className="motto">Express &amp; Personalized Yourself</p>
        </div>
        <div className="right">
          <div className="menu-footer">
            <span>Company</span>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/">Pricing</a></li>
              <li><a href="/">About</a></li>
            </ul>
          </div>
          <div className="menu-footer">
            <span>Company</span>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/">Pricing</a></li>
              <li><a href="/">About</a></li>
            </ul>
          </div>
          <div className="menu-footer">
            <span>Company</span>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/">Pricing</a></li>
              <li><a href="/">About</a></li>
            </ul>
          </div>
        </div>

      </div>
      <div className="footnote">
        <p><em>Copyright © 2021 - 2022 by dytra</em></p>
      </div>
    </footer>
  )
}

export default FooterLanding;