import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { TextInput, SubmitButton, Spinner, Label } from "../../components";
import { TextArea } from "../../components/forms";
import { User } from "../../Entities";
import HeaderConfig from "./components/HeaderConfig";
import { validateImage } from "../../widgets/ProfileCover/ProfileCoverWidgetForm"
import firebase from "../../utilities/firebaseContext";
import { useHistory, } from 'react-router-dom';

const ENV = process.env.REACT_APP_ENVIRONMENT;
const SuccessIcon = () => {
  return (
    <p><i className="fas fa-check"></i> Changes updated successfully</p>
  )
}

const DangerIcon = ({ message }) => {
  return (
    <p><i className="fas fa-times"></i> {message ? message : "Username already exists"}</p>
  )
}

const ConfigPageProfile = ({ userAuthData, db, userData, setUserData, ...props }) => {
  // const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const subTitle = userData?.subTitle;
  const bio = userData?.bio;

  const handleOnSubmit = async e => {
    e.preventDefault();
    e.persist();
    setSubmitting(true);
    console.log("submitting");
    const uid = userAuthData?.uid;
    const profileImageEl = e.target.profileImage;
    const profileImage = profileImageEl.files[0];
    let profileImagePath;
    if (profileImage) {
      profileImagePath = `images/users/${uid}/profile.jpg`;
      const validImage = validateImage(profileImage);
      if (!validImage) {
        return;
      } else {
        await uploadImageProfile({ profileImage, profileImagePath })
      }

    }
    const displayName = e.target.displayName?.value;
    const subTitle = e.target.subTitle?.value;
    const bio = e.target.bio?.value; 

    const username = e.target.username.value;
    if (!username.match(/^[0-9a-z]+$/)) {
      setSubmitting(false);
      toast.error(<DangerIcon message="Username not valid" />);
      return;
    }

    const u1 = new User(null, db);
    const userNameExists = await u1.userNameExists(username, uid);


    if (!userNameExists) {
      /*update firestore */

      const u = new User(uid, db);

      let updateObj = {
        username, displayName, 
        subTitle,
        bio,
      }

      if(profileImagePath) {
        updateObj.profileImagePath = profileImagePath;
      }


      await u.updateUserData(updateObj);
      setUserData({ ...userData, username, displayName });
      // setLoading(false);
      setSubmitting(false);
      console.log("done submitting");
      history.push("/dashboardv2/config");
      toast.success(<SuccessIcon />);

    } else {
      setSubmitting(false);
      toast.error(<DangerIcon />);
    }
  }

  const handleOnChange = () => {
    console.log("test");
  }

  const handleOnChangeDisplayName = () => {
    console.log("test 2");
  }

  useEffect(() => {
    // load user data every page is loaded

    async function start() {
      // setLoading(true);
      const u = new User(userAuthData?.uid, db);
      const userData = await u.getUserData();
      setUserData(userData);
      setLoading(false);


    }

    if (db && userAuthData) start();
    // eslint-disable-next-line
  }, [db, userAuthData]);
  console.log("ENV kyun", ENV);
  return (
    <div>
      <ToastContainer autoClose={3000} />
      <HeaderConfig>
        <h2>Profile</h2>
        <Spinner show={loading || submitting} />
      </HeaderConfig>
      <form onSubmit={handleOnSubmit}>
        {/*ENV === "development"*/ true && (
          <>
            <Label>Profile Picture</Label>
            {/* {(imageProfilePath) &&
              <a href={`${STORAGE_URL}/${imageProfilePath?.replace(/\//g, '%2F')}?alt=media`} target="_new" style={{ color: '#9E66D8' }}>
                <i className="fas fa-external-link-alt"></i></a>
            } */}
            <TextInput type="file" name="profileImage" accept=".jpg,.jpeg,.png" />
            <Label popOverText="A text below your username">Title</Label>
            <TextInput type="text" name="subTitle"
              defaultValue={subTitle || ""}
              placeholder="Product Designer, Web Developer, Manager" autoComplete="off"></TextInput>
            <Label>Bio</Label>
            <TextArea name="bio"
              defaultValue={bio}
            />
          </>
        )}
        <TextInput name="displayName" label="Display Name" placeholder="Display Name..." defaultValue={userData?.displayName || ""} onChange={handleOnChangeDisplayName} />
        <TextInput name="username" label="Username" placeholder="Username..." defaultValue={userData?.username || ""} onChange={handleOnChange} autoComplete="off" pattern="[a-z0-9]+" />
        {!loading && <SubmitButton value="Update" submitting={submitting} spinnerColor="white" />}

      </form>
    </div>
  )
}

export const uploadImageProfile = async ({ profileImage, profileImagePath }) => {
  // Create a root reference
  var storageRef = firebase.storage().ref();

  // Create a reference to 'images/mountains.jpg'
  var mountainImagesRef = storageRef.child(profileImagePath);

  // While the file names are the same, the references point to different files
  // mountainsRef.name === mountainImagesRef.name;           // true
  // mountainsRef.fullPath === mountainImagesRef.fullPath;   // false
  const res = await mountainImagesRef.put(profileImage);
  return res;
}



export default ConfigPageProfile;