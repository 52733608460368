import React, { useContext, useEffect, useState } from "react";
import { FireStoreContext } from "../../utilities/firebaseContext";
import { User, Widget } from "../../Entities";
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { TextInput, SubmitButton, Label, AddButton, }from "../../components";
import UserAuthContext from "../../context/UserAuthContext";
import socialMaster from "./socialMaster";
import SelectPicker from "../../components/forms/SelectPicker/SelectPicker";
import SortableList from "../../components/SortableList/SortableList";
import generateSocialUrl from "./generateSocialUrl";

const SocialMediaWidgetForm = ({ crud, toast, widget, submitting, setSubmitting, setToastCall, setShowToast, id, ...props }) => {
  const widgetId = id;
  const db = React.useContext(FireStoreContext);

  const history = useHistory();
  const location = useLocation();
  console.log("location izzzzzxxx ", location);
  const currentPath = location?.pathname;
  let match = useRouteMatch();
  const params = useParams()
  console.log("params herher", params);
  // console.log("match kyun",match);
  // console.warn("matchpath", match.path);
  const userAuthData = useContext(UserAuthContext);
  const uid = userAuthData?.uid;
  const [crudType, setCrudType] = useState("read");
  console.log("crudType KUN ", crudType);
  const [currentSocialCode, setCurrentSocialcode] = useState();
  const [currentSocials, setCurrentSocials] = useState([])
  const [loading, setLoading] = useState(true);

  const handleOnSubmit = async (e) => {
    e.persist();
    e.preventDefault();
    setSubmitting(true);
    if (!uid) return;
    const formData = new FormData(e.target);
    console.log("currentPath izz ", currentPath);
    console.log("editincludes", "edit".includes(currentPath));
    // return;
    const u = new User(uid, db);
    const index = currentPath.split("/").at(-1);
    if (currentPath.includes("edit")) {

      await doUpdateSocial({ data: formData, index, user: u, uid, db, setCurrentSocials })
      // return;
      setSubmitting(false);
      history.goBack();
    } else {
      const data = generateNewSocials({ data: formData, state: currentSocials, addNew: true });
      const updateObj = {
        socials: data,
      };
      await u.updateUserData(updateObj);
      await reloadCurrentSocials({ uid, db, setCurrentSocials });
      setCrudType("read");
      setSubmitting(false);
    }

  }

  const handleClickAdd = () => {
    setCrudType("create");
  }

  const handleChangeSocial = (social) => {
    const code = social.value;
    setCurrentSocialcode(code);
  }

  const handleClickSubmit = async () => {
    const newWidget = {
      type: 'social_media',
      socials: currentSocials,

    };

    await doSubmitWidget({ newWidget, uid, db, widgetId });

  }

  const doUpdateSocial = async ({ data, index, user, uid, db, setCurrentSocials }) => {
    const newSocials = generateNewSocials({ data, index });
    const updateObj = {
      socials: newSocials
    }
    // console.log("updateObj iz ",updateObj);
    // return;
    await user.updateUserData(updateObj);
    await reloadCurrentSocials({ uid, db, setCurrentSocials });
    return true;
  }

  const generateNewSocials = ({ data, index, addNew }) => {
    const social = data.get('social');
    const username = data.get('username');
    const socialObj = socialMaster.find(item => item?.value === social);
    const url = generateSocialUrl({ social, socialObj, username })
    const updateObj = {
      label: socialObj?.label,
      social,
      username,
      url,
    }
    let newSocials = currentSocials.slice();
    let index_ = index;
    if (addNew) {
      index_ = newSocials?.length;
    }
    newSocials[index_ ?? 0] = updateObj;
    return newSocials;
  }

  const doUpdateWidget = async ({ data, uid, db }) => {
    try {
      const updateObj = {
        socials: data,
      };
      console.log("updateObj izz ", updateObj);
      const u = new User(uid, db);
      await u.updateUserData(updateObj);
      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
      history.push("/dashboardv2/widgets");
    } catch (e) {

      setToastCall({
        type: "error",
        message: "There was an error while updating Widget"
      });
    }
  }


  const doSubmitWidget = async ({ newWidget, widgetId, }) => {
    const uid = userAuthData?.uid;
    const WidgetStore = new Widget(uid, db);
    if (props?.crudType === "update") {
      await doUpdateWidget({ data: currentSocials, uid, db });
    } else {
      await doInsertWidget({ WidgetStore, newWidget });
    }
  }

  const doInsertWidget = async ({ WidgetStore, newWidget }) => {
    try {
      await WidgetStore.addNewWidget(newWidget);
      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
      history.push("/dashboardv2/widgets");
    } catch (e) {

      setToastCall({
        type: "error",
        message: "There was an error while updating new Wiget"
      });
    }
  }

  const handleClickEdit = (data, index) => {
    // setCrudType("update");
    history.push(`${currentPath}/edit/${index}`, { data });
  }

  const handleClickDelete = () => {
    const ok = window.confirm("Are you sure do you want to delete this social link?");
    if (ok) {
      const index = currentPath.split("/").at(-1);
      doDeleteSocialItem({ index });
    }
  }

  const doDeleteSocialItem = async ({ index }) => {
    let newCurrentSocials = currentSocials.filter((item, i) => {
      // eslint-disable-next-line
      return i != index;
    });
    const updateObj = {
      socials: newCurrentSocials
    }
    const u = new User(uid, db);
    await u.updateUserData(updateObj);
    await reloadCurrentSocials({ uid, db, setCurrentSocials });
    history.goBack();
  }

  useEffect(() => {
    async function start() {
      // setLoading(true);
      await reloadCurrentSocials({ uid, db, setCurrentSocials });
      setLoading(false);
    }
    if (uid && db) start();
  }, [uid, db, setCurrentSocials]);

  return (
    <>
      {/* <Fade top duration={600} effect="fadeInUp" when={!loading} 
      distance={"5px"}
      > */}
      {!loading && (
        <div>
          <Switch>
            <Route exact path={`${match.path}`}>
              {crudType === "read" && (
                <AddButton onClickAdd={handleClickAdd}><i className="fas fa-plus" /> Add New Social</AddButton>
              )}
              {/* <FormOld widget={widget} submitting={submitting} onSubmit={handleOnSubmit} /> */}
              {crudType !== "read" && <FormNew
                onSubmit={handleOnSubmit}
                socialCode={currentSocialCode}
                widget={widget}
                onChangeSocial={handleChangeSocial}
                submitting={submitting}
                onClickDelete={handleClickDelete}
              />}
              {/* <pre>{JSON.stringify(currentSocials)}</pre> */}

              {crudType === "read" && (
                <SortableList
                  items={currentSocials}
                  setItems={setCurrentSocials}
                  itemKey={"social"}
                  itemValue={"username"}
                  itemLabel={"label"}
                  onClickEdit={handleClickEdit}
                />

              )}

              {crudType === "read" && currentSocials?.length > 0 && (
                <SubmitButton
                  onClick={handleClickSubmit}
                  type="submit" value={crudType === "create" ? "Submit Widget" : "Update Widget"} submitting={submitting} spinnerColor="white" />

              )}

            </Route>
            <Route path={`${match.path}/edit/:index`} >
              <FormNew
                onSubmit={handleOnSubmit}
                socialCode={currentSocialCode}
                widget={widget}
                onClickDelete={handleClickDelete}
                onChangeSocial={handleChangeSocial}
                submitting={submitting}
                crudType="update"
              />
            </Route>
          </Switch>
          {/* <Route path={"dashboardv2/widget/:id/edit"}>
            <div style={{height:"600px",background:"red"}}>
            <span>editing</span>
            </div>
          </Route> */}

        </div>
      )}

      {/* </Fade> */}
    </>
  )
}

const FormNew = ({ onChangeSocial, socialCode, widget, onSubmit, submitting, crudType, onClickDelete }) => {
  const location = useLocation();
  const state = location?.state;
  const data = state?.data;
  const { social, username } = data || {}; // if crudtype update
  const socialObj = socialMaster.find(item => [socialCode, social].includes(item?.value));
  // eslint-disable-next-line
  const { label, popOverText, value, placeholder, type } = socialObj || {};
  console.log("socialCodekyun ", social);
  return (
    <form onSubmit={onSubmit}>
      <SelectPicker required name="social" label="Social" options={socialMaster} onChange={onChangeSocial} {...{
        defaultValue: crudType === "update" ? { label: data?.label, value: social } : undefined
      }} />
      <Label htmlFor={socialCode} popOverText={popOverText}>{label || data?.label}</Label>
      {(socialCode || social) && (
        <TextInput type={type ?? "text"} name={"username"}
          // defaultValue={widget?.[socialCode] || ""} 
          id={socialCode} className="default-input-text widget-title" placeholder={placeholder} autoComplete="off" required {...{
            defaultValue: crudType === "create" ? widget?.[socialCode] || "" : username
          }} />
      )}
      <SubmitButton type="submit" value="Submit" submitting={submitting} spinnerColor="white" />
      {crudType === "update" && (
        <SubmitButton
          onClick={onClickDelete}
          value="Delete"
          spinnerColor={"#000"}
          type="button"
          style={{
            backgroundColor:"#fff",
            color:"#000",
            boxShadow: "0px 5px 10px rgb(193 193 193 / 30%)",
            border:"1px solid #ebebeb",
          }}

        />
      )}
    </form>
  )
}

const getSocials = async ({ uid, db }) => {
  const u = new User(uid, db);
  const data = await u.getUserData()
  const socials = data?.socials ?? [];
  return socials;

}

const reloadCurrentSocials = async ({ uid, db, setCurrentSocials }) => {
  const socials = await getSocials({ uid, db });
  setCurrentSocials(socials);
}

// const ENV = process.env.REACT_APP_ENVIRONMENT;

export default SocialMediaWidgetForm;