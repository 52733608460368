import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "../../Label";


const ColorPicker = ({ label, value, name, onChange }) => {

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Input type="text" value={value} onChange={e => onChange(name, e.target.value)} maxLength={7} />
      <StyledColorPicker type="color" onChange={e => onChange(name, e.target.value)} value={value} />
    </Container>
  )
}

const Container = styled.div``;

const StyledColorPicker = styled.input`
  position: relative;
  top: -37px;
  left: 10px;
  width: 25px;
`;

const Input = styled.input`
  outline: none;
  width: 100%;
  height: 3em;
  border: none;
  background: white;
  box-sizing: border-box;
  border-radius: 5px;
  /* margin-bottom: 2em; */
  border: 1px solid #f1ebf8;
  box-shadow: 0px 2px 5px rgba(170,145,196,0.1);
  font-size: 1rem;
  padding-left:2.7em;
  position:relative;

`;

ColorPicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export default ColorPicker;