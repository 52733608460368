import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { User, Widget } from "../../Entities";
import PreviewProfile from "../../components/PreviewProfile/PreviewProfile";
import { fetchRecordLogProfileView } from "../../actions/analytics";
// import { Helmet } from "react-helmet";
// import LinkWidget from "../../components/LinkWidget/LinkWidget";
import styled from "styled-components";

// const theme = {
//   primary: '#c19aea',
//   background: '#f7f4fa',
//   primaryText: '#b485e6',
//   boxShadow: '0px 5px 10px rgba(170, 145, 196, 0.3)',
// }

const PublicProfilePage = ({ history, db }) => {
  const location = useLocation();
  const [widgets, setWidgets] = useState([]);
  const [userData, setUserData] = useState(null);
  const [uid, setUid] = useState();
  useEffect(() => {
    async function start() {
      const pathname = location.pathname;
      const username = pathname.split('/')[1];
      /* check if username exist or not */
      const u = new User(null, db);
      await u.getUidByUsername(username).then(async uid => {
        setUid(uid);
        const w = new Widget(uid, db);
        const widgets = await w.fetchAllWidgets();
        const sortedWidgets = widgets.slice().sort(function (a, b) {
          return a?.order - b?.order;
        });
        setWidgets(sortedWidgets);
      }).catch(e => {
        console.error(e);
        history.push("/");
      });
    }
    if (db && history) start();

  }, [db, history, location, setWidgets]);

  useEffect(() => {
    async function start() {
      const u = new User(uid, db);
      const userData = await u.getUserData();
      setUserData(userData);
    }
    if (uid && db)
      start();
  }, [uid, db]);

  /* analytics */
  useEffect(() => {
    async function start() {
      const username = location.pathname.replace("/", "");
      fetchRecordLogProfileView({ uid, username });
    }
    if (uid) start();
    // eslint-disable-next-line
  }, [uid]);

  useEffect(() => {
    if (!userData?.username) return;
    document.title = `${userData?.username} | Perona`;

  }, [userData]);

  useEffect(() => {
    if (userData) {
      // console.warn("userData nyol", userData);
      const customThemeColors = userData?.customThemeColors;
      const primary = customThemeColors?.primary;
      if (primary) {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', primary);

      }
    }
  }, [userData]);

  return (
    <>
      <Container>
        <div className="test">
          <PreviewProfile userData={userData} widgets={widgets}
            // theme={theme}
          />
        </div>

      </Container>
    </>
  )
}

const Container = styled.div`
 /* @media only screen and (min-width: 768px) {
  .test {
    padding-top:1em;
    background:transparent;

  }
 }  */
`;



export default PublicProfilePage;