import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import Flag from "../../components/Flag";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const handleChangeLanguage = e => {
    const lang = e.target.value;
    i18n.changeLanguage(lang);
  }

  return (
    <>
      {/* <Flag lang="id"/> */}
      <Select onChange={e => handleChangeLanguage(e)} className="language-switcher">
        <option value="en">English</option>
        <option value="id">Indonesian</option>
      </Select>
    </>
  )
}

export const Select = styled.select`
  background: #5F31E00A;
  border:1px solid transparent;
  padding-top:.5em;
  padding-bottom:.5em;
  padding-left:.5em;
  padding-right:.5em;
  border-radius:5px;
  color:#5F31E0;
  font-size:1rem;
  transition: all .3s ease-in-out;
  &:hover {
    border:1px solid #c19aea;
    cursor:pointer;
  }
`;

export default LanguageSwitcher;