import { format } from "date-fns";

export const getDateFilterModeFullString = ({ mode, startDate, endDate }) => {
  const m = {
    last_7_days: "Last 7 Days",
    last_14_days: "Last 14 Days",
    last_30_days: "Last 30 Days",
  }
  if (m[mode]) {
    return m[mode]
  } else {
    if (mode === "custom") {
      return `${format(startDate ?? new Date(), "d MMM yyyy")} - ${format(endDate ?? new Date(), "d MMM yyyy")}`
    } else {
      return "nono"
    }
  }
}