import React, { useContext, useEffect, useState } from "react";
import PreviewProfile from "../../components/PreviewProfile/PreviewProfile";
import UserAuthContext from "../../context/UserAuthContext";
// import FireStoreContext from "../../utilities/firebaseContext";
import { Widget, User } from "../../Entities";
// import { useHistory } from "react-router-dom";
import Joyride from 'react-joyride';

// const theme = {
//   primary: '#c19aea',
//   background: '#f7f4fa',
//   primaryText: '#b485e6',
//   boxShadow: '0px 5px 10px rgba(170, 145, 196, 0.3)',
// }

// const ENV = process.env.REACT_APP_ENVIRONMENT;
const PreviewProfilePage = ({ db, userData, setUserData }) => {
  // const [userData, setUserData] = useState(null);
  const [widgets, setWidgets] = useState([]);
  const userAuthDataContext = useContext(UserAuthContext);
  const [userAuthData, setUserAuthData] = useState();

  const handleClickViewPublic =()=> {

  }

  useEffect(() => {
    async function start() {
      const w = new Widget(userAuthData?.uid, db);
      const widgets = await w.fetchAllWidgets();
      const sortedWidgets = widgets.slice().sort(function (a, b) {
        return a?.order - b?.order;
      });
      setWidgets(sortedWidgets);
    }
    if (db && userAuthData) start();

  }, [db, setWidgets, userAuthData]);

  useEffect(() => {
    // load user data every page is loaded

    async function start() {
      const u = new User(userAuthData?.uid, db);
      const userData = await u.getUserData();
      setUserData(userData);

    }

    if (db && userAuthData) start();
    // eslint-disable-next-line
  }, [db, userAuthData]);

  useEffect(() => {
    if (userAuthDataContext) {
      // debugger;
      setUserAuthData(userAuthDataContext);
    }
  }, [userAuthDataContext]);
  return (
    <>
      {/*ENV === "development" &&*/ widgets?.length > 0 && userData?.onboarding?.finishAddFirstWidget && 
      userData?.onboarding?.finishPreviewWidget && !userData?.onboarding?.finishPublicView &&
       <Joyride steps={steps} />}
      <PreviewProfile userAuthData={userAuthData} userData={userData} widgets={widgets} setWidgets={setWidgets} handleClickViewPublic={handleClickViewPublic} />
    </>
  )
}

const steps = [
  {
    target: '.fa-external-link-alt',
    content: "Click This button to open your profile in a new tab. You can use this link to share accross your favourite social media",
  },
];
export default PreviewProfilePage;