import React from "react";
import Spinner from "../Spinner/Spinner";
import styled from "styled-components";

const SpinnerFull = () => {

  return (
    <Container>
      <Spinner show={true} />
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  /* height:100%; */
  padding-top:5em;
  i {
    font-size:2rem;
  }
`;

export default SpinnerFull;