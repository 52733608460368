import React from "react";
import styled from "styled-components";
import { lighten } from 'polished';
import { Spinner } from "../";

const SubmitButtonComponent = ({ value, submitting, spinnerColor, onClick, style,type }) => {
  let disabled = false;
  if (submitting) {
    disabled = true;
  }

  return (
    <SubmitButton
      type={type ?? "submit"}
      className="btn-add-new-widget"
      disabled={disabled}
      submitting={submitting}
      onClick={onClick}
      style={{ ...style }}>
      {submitting && <Spinner show={submitting} color={spinnerColor} />} {value}
    </SubmitButton>
  )
}

const SubmitButton = styled.button`

  background: ${props => props.submitting ? lighten(0.05, '#5F31E0') : '#5F31E0'};
  cursor: pointer;
`;


export default SubmitButtonComponent;