import { TextWidgetPreview, TextWidgetManage, TextWidgetForm } from "./Text";
import { CopyableWidgetForm, CopyableWidgetPreview, CopyableWidgetManage } from "./Copyable";
import { SingleLinkWidgetForm, SingleLinkWidgetPreview, SingleLinkWidgetManage } from "./SingleLink";
import { ProfileCoverWidgetForm, ProfileCoverWidgetPreview, ProfileCoverWidgetManage } from "./ProfileCover";
import { SocialMediaWidgetForm } from "./SocialMedia";
export {
  TextWidgetPreview,
  TextWidgetManage,
  TextWidgetForm,
  CopyableWidgetForm,
  CopyableWidgetPreview,
  CopyableWidgetManage,
  SingleLinkWidgetForm,
  SingleLinkWidgetPreview,
  SingleLinkWidgetManage,
  ProfileCoverWidgetForm,
  ProfileCoverWidgetPreview,
  ProfileCoverWidgetManage,
  SocialMediaWidgetForm,
}