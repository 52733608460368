import React, { useContext } from "react";
import { Label, TextInput, SubmitButton } from "../../components";
import UserAuthContext from "../../context/UserAuthContext";
import { FireStoreContext } from "../../utilities/firebaseContext";
import { Widget } from "../../Entities";
import { useHistory } from "react-router-dom";

const CopyableWidget = ({ submitting, setSubmitting, setToastCall, widget, ...props }) => {

  const userAuthData = useContext(UserAuthContext);
  const db = React.useContext(FireStoreContext);
  const history = useHistory();
  const copyText = widget?.copyText;

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    // const title = e.target.title.value;
    const copyText = e.target.copyText.value;
    const widgetId = widget?.id;

    const newWidget = {
      type: 'copyable',
      copyText
    }


    await doSubmitWidget({ newWidget, widgetId });
    setSubmitting(false);
  }

  const doSubmitWidget = async ({ newWidget, widgetId }) => {
    const uid = userAuthData?.uid;
    const WidgetStore = new Widget(uid, db);
    if (props?.crudType === "update") {
      await doUpdateWidget({ WidgetStore, newWidget, widgetId })
    } else {
      await doInsertWidget({ WidgetStore, newWidget });
    }
  }

  const doInsertWidget = async ({ WidgetStore, newWidget }) => {
    try {
      await WidgetStore.addNewWidget(newWidget);
      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
      history.push("/dashboardv2/widgets");
    } catch (e) {

      setToastCall({
        type: "error",
        message: "There was an error while updating new Wiget"
      });
    }
  }

  const doUpdateWidget = async ({ WidgetStore, newWidget, widgetId }) => {
    try {
      await WidgetStore.updateWidgetBulk(widgetId,
        { ...newWidget });
      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
      history.push("/dashboardv2/widgets");
    } catch (e) {

      setToastCall({
        type: "error",
        message: "There was an error while adding new Wiget"
      });
    }
  }
  return (
    <form onSubmit={handleSubmit} method="post">
      {/* <Label popOverText="Not mandatory, but it will be use to label the widget so you can manage easily">Label</Label>
      <TextInput name="title" placeholder="Label" /> */}
      <Label popOverText="The Text that that visitor will be able to copy">Text</Label>
      <TextInput name="copyText" maxLength="50" placeholder="Text" defaultValue={copyText || ""} autoComplete="off" required />
      <SubmitButton type="submit" value="Submit" submitting={submitting} spinnerColor="white" />
    </form>
  )
}

export default CopyableWidget;