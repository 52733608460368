import React from 'react';
import { toast as sendToast } from 'react-toastify';

export const toast = {
  success: (message) => toastSuccess(message),
  error: (message) => toastDanger(message),
  dark: (message) => toastDark(message),
  default: (message) => toastDefault(message),
}

const toastSuccess = (message) => {
  sendToast.success(<SuccessIcon message={message} />);
}

const toastDanger = (message) => {
  sendToast.error(<DangerIcon message={message} />);
}

const toastDark = (message) => {
  sendToast.dark(message);
}

const toastDefault = (message) => {
  sendToast(message);
}

const SuccessIcon = ({message}) => {
  return (
    <p><i className="fas fa-check"></i> {message} </p>
  )
}

const DangerIcon = ({ message }) => {
  return (
    <p><i className="fas fa-times"></i> {message}</p>
  )
}