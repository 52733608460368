const socialMaster = [
  {
    label: "Instagram",
    value: "instagram",
    baseUrl: "https://instagram.com/",
    iconClass: "fab fa-instagram",
    popOverText: "Your Instagram username without the @",
    placeholder: "Instagram Username...",
  },
  {
    label: "Twitter",
    value: "twitter",
    baseUrl: "https://twitter.com/",
    iconClass: "fab fa-twitter",
    popOverText: "Your Twitter username without the @",
    placeholder: "Twitter Username...",
  },
  {
    label: "Tiktok",
    value: "tiktok",
    baseUrl: "https://tiktok.com/@",
    iconClass: "fab fa-tiktok",
    popOverText: "Your Tiktok username without the @",
    placeholder: "Tiktok Username...",
  },
  {
    label: "Facebook",
    value: "facebook",
    baseUrl: "https://facebook.com/",
    iconClass: "fab fa-facebook",
    popOverText: "Your Facebook Profile URL",
    placeholder: "Facebook URL...",
  },
  {
    label: "Youtube",
    value: "youtube",
    baseUrl: "https://youtube.com",
    type: "url",
    iconClass: "fab fa-youtube",
    popOverText: "Your Youtube Channel URL",
    placeholder: "Youtube URL...",
  },
  {
    label: "Twitch",
    value: "twitch",
    baseUrl: "https://twitch.tv/",
    iconClass: "fab fa-twitch",
    popOverText: "Your Twitch Channel Username",
    placeholder: "Twitch Username...",
  },
  // {
  //   label: "Discord",
  //   value: "discord",
  //   baseUrl: "https://discord.com",
  //   iconClass: "fab fa-discord",
  //   popOverText: "Your Discord Username",
  //   placeholder: "Discord Username...",
  // },
  {
    label: "Github",
    value: "github",
    baseUrl: "https://github.com/",
    iconClass: "fab fa-github",
    popOverText: "Your Github username without the @",
    placeholder: "Github Username...",
  },
  {
    label: "Website",
    value: "website",
    type: "url",
    iconClass: "fas fa-globe",
    popOverText: "Your Website URL address",
    placeholder: "Website URL...",
  }
]

export default socialMaster;