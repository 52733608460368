import React from "react";
import styled from "styled-components";

const TextArea = ({...props}) => {
  return (
    <StyledTextArea {...props}></StyledTextArea>
  )
}

const StyledTextArea = styled.textarea`
    margin-top:0 !important;
    outline: none;
    width: 100%;
    padding: 1em;
    border: none;
    background: white;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 2em;
    border: 1px solid #f1ebf8;
    box-shadow: 0px 2px 5px rgba(170, 145, 196, 0.1);
    font-size: 1rem;
`;

export default TextArea;