import React, { useState } from "react";
import '../../pages/LandingPage/Landing.scss';
// import homeLanding from "../../assets/home-landing.svg";
// import HighlightPoints from "../../pages/LandingPage/HighlightPoints";
// import erinProfile from "../../assets/images/erindesongprofile.png";
// import Testimonials from "./Testimonials";
// import SuperCallAction from "../../pages/LandingPage/SuperCallAction";
import FooterLanding from "../../pages/LandingPage/FooterLanding";
// import smartphoneImage from "../../assets/images/smartphone2.png";
// import Fade from 'react-reveal/Fade';
import { Link, useRouteMatch, } from 'react-router-dom';
import classNames from "classnames";

const LandingTemplate = ({
    children
}) => {
    const [showMenu, setShowMenu] = useState(false);
    const route = useRouteMatch();
    const path = route?.path;
    console.warn(" ", route);

    const handleClickBurger = () => {
        setShowMenu(!showMenu);
    }

    React.useEffect(() => {
        const script = document.createElement('script');

        script.src = "//code.tidio.co/4jfavd4jztuq8ep8autewkgqwriarz6s.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <div style={{ overflowX: 'hidden' }}>
            <header className="landing-header">
                <div className="maxw-container align-items-center landing-header-container">
                    <div className="logo-container">
                        <a href="/" className="perona-text-logo" style={{
                            // color: "#9A52E4",
                            fontSize: "26px",
                            // fontWeight: "600",
                            textDecoration: "none"

                        }}>perona</a>
                        <div className="burger-container" style={{

                        }}>
                            <i className={`fas ${showMenu ? 'fa-times' : 'fa-bars'}`} onClick={handleClickBurger} />
                        </div>

                    </div>
                    <div
                        // className="menu-container" 
                        className={`menu-container ${showMenu ? 'menu-container-show' : ''}`} style={{
                            // display: showMenu ? "flex" : "none",
                        }}>
                        <ul className="menu">
                            {/* <li><Link to="/landing">Home</Link></li> */}
                            {/* <li><Link to="/pricing">Pricing</Link></li>
                            <li><Link to="/about">About</Link></li> */}
                            {menuLinks.map((item, index) => {
                                return (
                                    <li key={index}><Link to={item?.path} className={classNames({ active: path === item?.path })}>{item?.title}</Link></li>
                                )
                            })}
                            <li key={"signup"}><Link to="/login" className="button button-primary button-mini login-button">Login</Link></li>
                        </ul>
                    </div>

                </div>
            </header >
            {children}
            < FooterLanding />
        </div >
    )
}

const menuLinks = [
    { path: "/", title: "Home" },
    { path: "/pricing", title: "Pricing" },
    { path: "/about", title: "About" }
];

export default LandingTemplate;
