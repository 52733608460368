import classNames from "classnames";
import React from "react";
import styled from "styled-components";

const ThemeSlides = ({
  onClickSlideItem,
  activeTheme,
  themes,
}) => {
  console.log("currentActiveTheme ", activeTheme);

  return (
    <ThemeContainer>
      <div className="theme-items-container">
        {themes.map((item, index) => {
          const active = activeTheme === item?.name;
          return (
            <ThemeItem key={index} onClick={() => onClickSlideItem({ name: item?.name })} active={active} {...item} />
          )
        })}
      </div>
    </ThemeContainer>
  )
}

const ThemeItem = ({ onClick, name, label, active, style }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <button className={classNames("theme-item", {
        active
      })} onClick={onClick}
        style={{ 
          // background: style?.background 
        }}
      >
        <div className="background" style={{
          background: style?.background
        }}>
          <div className="left" style={{
            background: style?.background,
          }}></div>
          <div className="right" style={{
            background: style?.secondaryBackground,
          }}></div>
        </div>
        <div className="circle" style={{
          background: style?.primary,
        }}>
          <span>Aa</span>
        </div>
        {active && (
          <div className="check">
            <span><i className="fas fa-check-circle" /></span>
          </div>
        )}

      </button>
      <h3 className="theme-item-title">{label}</h3>
    </div>
  )
}

const ThemeContainer = styled.div`
  overflow-x:scroll;
  display:flex;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  .theme-items-container {
    display: flex;
    margin-top:.5em;
  }
  .theme-item {
    cursor:pointer;
    border-radius:12px;
    width:150px;
    flex-shrink: 0;
    height:100px;
    position: relative;
    /* background:red; */
    margin-right:12px;
    content:'';
    overflow:hidden;
    border:none;
    /* transition:all .3s ease-in-out; */
    /* border-width:4.5px; */
    /* border-color:#66D8C6; */
    &.active {
      border:4.5px solid #66D8C6;
    }
    .background {
      /* background:blue; */
      content:'';
      display:flex;
      height:100%;
      .left {
        flex:1;
        background:#F4EDFF;
      }
      .right {
        flex:1;
        background:#EFE0FA;
      }
    }
    .circle {
      background:#5E31DD;
      position: absolute;
      width:65px;
      height:65px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
      /* transform:translate(50%,10%); */
      display:flex;
      justify-content: center;
      align-items: center;
      span {
        font-size:21px;
        font-weight:600;
        color:#fff;
      }
    }

    .check {
      /* background:var(--success); */
      width:20px;
      height:20px;
      color:var(--success);
      font-size:16px;
      position: absolute;
      top:0;
      border-radius:50%;
      margin-left:.25em;
      margin-top:.25em;
    }
  }
  .theme-item-title {
    text-align:center;
    margin-top:.5em;
  }

`;

export default ThemeSlides;