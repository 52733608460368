import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { db } from "../../utilities/firebaseContext";

const ENV = process.env.REACT_APP_ENVIRONMENT;
const Header = styled.header`
  background:white;
  display:flex;
  align-items:center;
  height:3.5em;
  padding:.5em 1em;
  box-sizing:border-box;
  border-bottom:1px solid ${props => props?.theme?.borderColor};
  position:fixed;
  top:0;
  width:100%;
  z-index:1;
  .side-right {
    display:flex;
    margin-left: auto;
    .top-bar-username {
      display:flex;
      flex-direction:column;
      font-size:.85rem;
    }
    .top-bar-inbox {
      margin-left:1em;
      display:flex;
      align-items:center;
      font-size:1.25rem;
    }
  }
`;

const TopBar = ({ userData, userAuthData }) => {
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const history = useHistory();
  const mainNav = [
    "/dashboardv2",
    "/dashboardv2/widgets",
    "/dashboardv2/notifications",
  ];

  const handleClickBack = () => {
    if (history.length <= 2) {
      history.push("/dashboardv2");
    } else {
      history.goBack();
    }
  }

  const handleClickExterLink = () => {
    const uid = userAuthData?.uid;
    if (!uid) return;
    const onboarding = userData?.onboarding;
    const finishPublicView = onboarding?.finishPublicView;
    if (!finishPublicView) {
      db.collection("users").doc(uid).update({
        "onboarding.finishPublicView": true,
      });
    }
  }

  return (

    <Header theme={theme} className="app-top-bar">
      {mainNav.includes(location.pathname) && (
        <React.Fragment>
          <div className="top-bar-title">
            <Link to="/" className="perona-logo">perona</Link>

          </div>
          <div className="side-right">

            {ENV !== "production" && (
              <>
                <div className="top-bar-username">
                  {/* <span>Aaron</span> */}
                  {/* <span>Free Version</span> */}
                </div>
                {/* <div className="top-bar-inbox"><i className="fas fa-envelope"></i></div> */}
              </>
            )}

          </div>
        </React.Fragment>
      )}
      {!mainNav.includes(location.pathname) && (
        <React.Fragment>
          <BackButtonContainer onClick={handleClickBack} theme={theme}>
            <i className="typcn typcn-chevron-left"></i>
          </BackButtonContainer>
        </React.Fragment>
      )}
      {location.pathname.includes("/dashboardv2/preview") && <ExternalLinkIcon userData={userData} onClick={handleClickExterLink} />}

    </Header>
  )
}


const ExternalLinkIcon = ({ userData, onClick }) => {
  const username = userData?.username;
  if (!username) return null;
  return (
    <a href={`https://perona.id/${username}`} onClick={onClick} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto', color: 'var(--primary)' }}><i className="fas fa-external-link-alt"></i></a>
  )
}

const BackButtonContainer = styled.div`
  height:100%;
  display:flex;
  align-items:center;
  &:hover {
    cursor: pointer;
    
  }
  i {
    font-size:1.5rem;
    color: ${theme => theme.theme.primary};
  }
`;

export default TopBar;