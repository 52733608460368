import React, { useState } from "react";
import { ToastContainer, } from 'react-toastify';
import { SubmitButton, Label } from "../../components";
import HeaderConfig from "./components/HeaderConfig";
import { useTranslation } from "react-i18next";
import { toast } from "../../utilities/toastWrapper";
import { Select } from "../../components/forms";

const ConfigPageGeneral = ({ userAuthData, db, userData, setUserData, ...props }) => {
  // const [userData, setUserData] = useState({});
  // const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLange = i18n.language;

  const handleOnSubmit = async e => {
    e.preventDefault();
    // setLoading(true);
    setSubmitting(true);


    /* change language */
    const lang = e.target.language.value;
    i18n.changeLanguage(lang);

    setSubmitting(false);
    toast.success("Setting successfully changed");
  }

  return (
    <div>
      <ToastContainer autoClose={3000} />
      <HeaderConfig>
        <h2>General</h2>
        {/* <Spinner show={loading || submitting} /> */}
      </HeaderConfig>
      <form onSubmit={handleOnSubmit}>
        <Label htmlFor="language">{t("LANGUAGE")}</Label>
        <Select name="language" id="language" defaultValue={currentLange} className="default-select">
          <option value="en">English</option>
          <option value="id">Indonesian</option>
        </Select>
        {<SubmitButton value="Update" submitting={submitting} spinnerColor="white" />}

      </form>
    </div>
  )
}


export default ConfigPageGeneral;