import React, { useState, /*useEffect*/ useContext } from 'react';
import './App.scss';
// import Dashboard from './pages/Dashboard';
import { Switch, Route, Link, useHistory, } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import LoginPagev2 from './pages/LoginPagev2';
import LogoutPage from "./pages/LogoutPage";
import UsernamePickerPage from "./pages/UsernamePickerPage";
import ThemePickerPage from "./pages/ThemePickerPageV2";
import Payment from "./pages/Payment";
import AuthSuccessPage from "./pages/AuthSuccessPage";
import firebase, { ui, db } from './utilities/firebaseContext';
import UserAuthContext from "./context/UserAuthContext";
import PublicProfilePage from "./pages/PublicProfilePage";
import DashboardV2 from "./templates/DashboardV2/DashboardV2";
import { HomeLandingNew } from "./pages/LandingPage";
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
// import AppHomeScreen from './pages/AppHomeScreen';
import PricingPage from './pages/PricingPage/PricingPage';
import AboutPage from './pages/AboutPage/AboutPage';
import { CookiesProvider } from 'react-cookie';
import SignupWizardPage from './pages/SignupWizardPage/SignupWizardPage';
import './pages/Widgets/WidgetEditor.scss';

const App = () => {
  const defaultUserAuth = useContext(UserAuthContext);
  const [userAuthData, setUserAuthData] = useState(defaultUserAuth);
  const [userData, setUserData] = useState();
  const [uid, setUid] = useState('');
  const [activeTopNavMenu, setActiveTopNavMenu] = useState('edit');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [widgetGroupTarget, setWidgetGroupTarget] = useState({});
  const [widgetContentTarget, setWidgetContentTarget] = useState({});
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [frontForms, setFrontForms] = useState([]);
  const [loggedIn, setLoggedIn] = useState();
  const history = useHistory();
  const ENV = process.env.REACT_APP_ENVIRONMENT;

  console.log("ENV is ", ENV);

  const [widgets, setWidgets] = useState([
    /* TODO: This should be blank if user is not new */
    // {
    //   type: 'link',
    //   links: [
    //     {
    //       title: 'Facebook',
    //       url: 'https://facebook.com'
    //     },
    //     {
    //       title: ' twitter',
    //       url: 'https://twitter.com'
    //     }
    //   ]
    // }
    // {
    //   id:'DVYvEA6tP312brReYAKx',
    //   type: 'link',
    //   items: [
    //     {
    //       id: 1,
    //       title: 'My Awesome Blog',
    //       url: 'http://google.com',
    //     },
    //     {
    //       id: 2,
    //       title: 'Check my art here',
    //       url: 'https://google.com',
    //     },
    //     {
    //       id: 3,
    //       title: 'Donate me',
    //       url: 'https://google.com',
    //     }

    //   ]
    // },

  ]);

  // useEffect(() => {
  //   const uid = window.sessionStorage.getItem('uid');
  //   async function addListener() {
  //     if (uid) {
  //       await db.collection('widgets').doc(uid).onSnapshot(widget => {
  //         const widgetData = widget.data();
  //         if(!widgetData) return;
  //         const widgetsRes = widgetData.items;
  //         setWidgets(widgetsRes);
  //       });

  //     }

  //   }
  //   addListener();
  // }, []);

  const toggleOverlayActionSheet = () => {
    setShowOverlay(!showOverlay);
    setShowActionSheet(!showActionSheet);
  }

  const handleOnClickDelete = () => {
    const confirmDelete = window.confirm('Are you sure do you want to delete this widget item?');
    if (confirmDelete) {
      fetchDeleteWidgetItem({ widgetGroupTargetId: widgetGroupTarget.id, widgetContentTargetId: widgetContentTarget.id });
    }
  }

  const fetchDeleteWidgetItem = ({ widgetGroupTargetId, widgetContentTargetId }) => {
    console.log('widgetGroupTarget :', widgetGroupTargetId);
    console.log('widgetContentTargetId :', widgetContentTargetId);

    /* duplicate widgets */
    const widgetsDuplicate = [...widgets];
    /* filter to find widget item to delete */
    const widgetsFiltered = widgetsDuplicate[widgetGroupTargetId].contents.filter((widgetItem, index) => {
      console.log(`comparing index(${index}) and content target id ${widgetContentTargetId}`);
      console.log(index !== widgetContentTargetId);
      return index !== widgetContentTargetId
    });
    /* replace to */
    widgetsDuplicate[widgetGroupTargetId].contents = widgetsFiltered;

    /* begin update to firestore */
    db.collection('widgets')
      .doc(window.sessionStorage.getItem('uid'))
      .update({ items: widgetsDuplicate });
  }

  const pushFrontForm = (currentPath, frontForms) => {
    const currentIndex = frontForms.indexOf(currentPath);
    // debugger;
    if (!frontForms[currentIndex + 1]) {
      history.push("/dashboardv2");
    } else {
      history.push(frontForms[currentIndex + 1]);
    }

  }

  if (document.location.href === "https://perona.id/" /*&& document.location.hostname !== "localhost" */) {
    // return (<HomeLandingNew />);
  }

  return (
    <>
      <CookiesProvider>
        <UserAuthContext.Provider value={userAuthData}>
          <div className="App">
            <div id="overlay" style={{ display: showOverlay ? 'block' : 'none' }} onClick={toggleOverlayActionSheet}>
              <section id="action-sheet" style={{ display: showActionSheet ? 'block' : 'none' }}>
                <ul>
                  <li><Link style={{ textDecoration: 'none' }} to="/dashboard/edit_widget_item"><button><i className="fas fa-edit"></i><span>Edit</span></button></Link></li>
                  {/* <li><button onClick={handleOnClickEdit}><i className="fas fa-edit"></i><span>Edit</span></button></li> */}
                  <li><button onClick={handleOnClickDelete}><i className="fas fa-trash"></i><span>Delete</span></button></li>
                  <li><button onClick={toggleOverlayActionSheet}><i></i><span>Cancel</span></button></li>
                </ul>
              </section>
            </div>
            <Switch>
              <Route exact path="/">
                <HomeLandingNew />
                {/* <AppHomeScreen /> */}
                {/* {ENV !== "development" && <Home loggedIn={loggedIn} setLoggedIn={setLoggedIn} firebase={firebase} />} */}

              </Route>
              <Route path="/landing">
                <HomeLandingNew />
              </Route>
              <Route path="/pricing">
                <PricingPage />
              </Route>
              <Route path="/about">
                <AboutPage />
              </Route>
              <Route exact path="/loginv2">
                <LoginPagev2 />
              </Route>
              <Route exact path="/login">
                <LoginPage firebase={firebase} ui={ui} setUserAuthData={setUserAuthData} uid={uid} setUid={setUid} db={db} history={history} />
              </Route>
              <Route exact path="/sign_up">
                <LoginPage firebase={firebase} ui={ui} setUserAuthData={setUserAuthData} uid={uid} setUid={setUid} db={db} history={history} authType="sign_up" />
              </Route>
              <Route exact path="/plans">
                <Payment />
              </Route>
              <Route exact path="/pick_username">
                <UsernamePickerPage pushFrontForm={pushFrontForm} frontForms={frontForms} />
              </Route>
              {/* <Route exact path="/pick_theme">
                <ThemePickerPage pushFrontForm={pushFrontForm} frontForms={frontForms} />
              </Route> */}
              <Route exact path="/auth_success">
                <AuthSuccessPage firebase={firebase} db={db} setLoggedIn={setLoggedIn} />
              </Route>
              {/* <Route path="/dashboard">
            <Dashboard
              activeTopNavMenu={activeTopNavMenu}
              setActiveTopNavMenu={setActiveTopNavMenu}
              widgets={widgets}
              setWidgets={setWidgets}
              firebase={firebase}
              db={db}
              userAuthData={userAuthData}
              setShowOverlay={setShowOverlay}
              setShowActionSheet={setShowActionSheet}
              setWidgetGroupTarget={setWidgetGroupTarget}
              setWidgetContentTarget={setWidgetContentTarget}
              setSelectedWidget={setSelectedWidget}
              selectedWidget={selectedWidget}
              userData={userData}
              setUserData={setUserData}
              setFrontForms={setFrontForms}
            />
          </Route> */}
              <Route path="/dashboardv2">
                <DashboardV2
                  activeTopNavMenu={activeTopNavMenu}
                  setActiveTopNavMenu={setActiveTopNavMenu}
                  widgets={widgets}
                  setWidgets={setWidgets}
                  firebase={firebase}
                  db={db}
                  userAuthData={userAuthData}
                  setShowOverlay={setShowOverlay}
                  setShowActionSheet={setShowActionSheet}
                  setWidgetGroupTarget={setWidgetGroupTarget}
                  setWidgetContentTarget={setWidgetContentTarget}
                  setSelectedWidget={setSelectedWidget}
                  selectedWidget={selectedWidget}
                  userData={userData}
                  setUserData={setUserData}
                  setFrontForms={setFrontForms}
                  setUserAuthData={setUserAuthData}
                  loggedIn={loggedIn}
                  setLoggedIn={setLoggedIn}
                />
              </Route>
              <Route path="/wizard">
                <span><SignupWizardPage firebase={firebase} userAuthData={userAuthData} userData={userData} db={db} setUserData={setUserData} history={history} /></span>
              </Route>
              <Route exact path="/pick_theme">
                <ThemePickerPage userAuthData={userAuthData} setUserAuthData={setUserAuthData} userData={userData} db={db} history={history} />
              </Route>
              <Route path="/logout">
                <LogoutPage />
              </Route>
              <Route>
                <PublicProfilePage history={history} db={db} />
              </Route>
            </Switch>
          </div>
        </UserAuthContext.Provider>
      </CookiesProvider>
    </>
  );

}

export default App;
