import React, { useState, useContext } from "react";
// import "./WidgetItem.scss";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";
import "../../components/Button/Button.scss";
import { Widget } from "../../Entities";
import ActionSheet from "../../components/ActionSheet";
import { db } from "../../utilities/firebaseContext";
import UserAuthContext from "../../context/UserAuthContext";
import styled from "styled-components";
import { array_move } from "../../utilities/coreutils";
import { toast } from "../../utilities/toastWrapper";
import { Draggable } from "../../components";

const WidgetGroup = ({ children, type, id, widget, setWidgets, widgets, itemIndex, setLoading, setOverWidgetItem, overWidgetItem, ...props }) => {
  const [widgetGroupExpand, setWidgetGroupExpand] = useState("idle");
  const [showActionSheet, setShowActionSheet] = useState(false);
  const userAuthData = useContext(UserAuthContext);
  const widgetTitle = widget?.title || widget?.copyText ? `: ${(widget.title || widget?.copyText)}` : "";
  let widgetType;
  if (widget?.type === "single_link") {
    widgetType = "Link";
  } else if (widget?.type === "link") {
    widgetType = "Stack Link";
  } else if (widget?.type === "profile_cover") {
    widgetType = "Profile Cover";
  } else if (widget?.type === "social_media") {
    widgetType = "Social Media"
  } else {
    widgetType = type;
  }
  const toggleWidgetGroupExpand = () => {
    if (widgetGroupExpand === "idle") {
      setWidgetGroupExpand(true);
    } else {
      setWidgetGroupExpand(!widgetGroupExpand);
    }
  }

  const handleClickContextIcon = (e) => {
    e.stopPropagation();
    toggleShowActionSheet();
  }

  const toggleShowActionSheet = () => {
    setShowActionSheet(!showActionSheet);
  }

  const handleOnClickDelete = async () => {
    setLoading(true);
    const widgetId = id;
    const uid = userAuthData?.uid;
    if (!uid) return;
    const w = new Widget(uid, db);
    /* delete wdiget by id */
    await w.deleteWidget({ widgetId });
    /* show toast */
    toast.success("Widget successfully deleted");
    /* fetch widgets */
    const widgets = await w.fetchAllWidgets();
    setWidgets(widgets);
    setLoading(false);
  }

  const handleClickMove = async (direction) => {
    if (!userAuthData?.uid) return;
    const uid = userAuthData?.uid;
    const widgets2 = widgets;

    const move = (direction === 'up') ? itemIndex - 1 : itemIndex + 1;
    const movedWidgets = array_move(widgets2, itemIndex, move);
    setWidgets(movedWidgets);
    const w = new Widget(uid, db);
    await w.updateWidgetsOrder(movedWidgets);
    // w.updateWidget({ widgetId: widget_id, field: 'links', value: movedArray });
    // w.updateWidgetsBatch();
  }

  const handleDragStart = ({ itemIndex, event }) => {
    // console.log("strattttttttttttttttt");
    setOverWidgetItem(itemIndex);
  }

  const handleDragOver = () => {
    // console.log("overrrrr");
  }

  const handleDrop = async ({ itemIndex, event }) => {
    console.log("lapuuu ", overWidgetItem);
    if (!userAuthData?.uid) return;
    const uid = userAuthData?.uid;
    const widgets2 = widgets;
    console.log("widgets2 iz ", widgets2);
    // const direction = "up";
    // const delta = overWidgetItem - itemIndex;
    const move = itemIndex;
    console.log("move iz ", move);
    // const move = delta;
    const movedWidgets = array_move(widgets2, overWidgetItem, move);
    console.log("movedWidgets zu ", movedWidgets);
    setWidgets(movedWidgets);
    const w = new Widget(uid, db);
    await w.updateWidgetsOrder(movedWidgets);
  }
  return (
    <Draggable itemIndex={itemIndex} onDrop={handleDrop} onDragOver={handleDragOver} onDragStart={handleDragStart}>
      {/* <span>huhu</span> */}
      <ActionSheet showActionSheet={showActionSheet} toggleShowActionSheet={toggleShowActionSheet}>
        <ul>
          <li><Link style={{ textDecoration: 'none' }} to={`/dashboard/widget/${widget.id}`}><button><i className="fas fa-edit"></i><span>Edit</span></button></Link></li>

          {(itemIndex !== 0) &&
            <li><button onClick={() => handleClickMove('up')}><i className="fas fa-chevron-up"></i><span>Move Up</span></button></li>
          }

          {(itemIndex !== widgets.length - 1) &&
            <li><button onClick={() => handleClickMove('down')}><i className="fas fa-chevron-down"></i><span>Move Down</span></button></li>
          }

          <li><button onClick={handleOnClickDelete}><i className="fas fa-trash"></i><span>Delete</span></button></li>
          <li><button /*onClick={toggleOverlayActionSheet} */><i></i><span>Cancel</span></button></li>
        </ul>
      </ActionSheet>

      <WidgetItemBox
        {...{
          widgetGroupExpand,
          toggleWidgetGroupExpand,
          widgetType,
          widgetTitle,
          widget,
          handleClickContextIcon,
          id,
          children,
        }}
      />

      {/* <Container className={classnames('widget-item', {
        expanded: widgetGroupExpand === true,
        unexpanded: widgetGroupExpand === false,
      })}>
        <div className="widget-item--head">
          <div className="title" onClick={toggleWidgetGroupExpand}>
            <i className="fas fa-chevron-right icon"></i>&nbsp;&nbsp;<h3>{widgetType}{widgetTitle}</h3>
          </div>
          <div className="action-control" onClick={(e) => handleClickContextIcon(e)}>
            <Link to={{
              pathname: `/dashboardv2/widget/${id}`, widget
            }} className="btn btn-sm btn-light"><i className="typcn typcn-edit"></i><span>Edit</span></Link>
            <i className="fas fa-ellipsis-v option"></i>

          </div>
        </div>
        <div className="widget-item--body">
          <div className="content">
            {children && children}

          </div>
        </div>
      </Container> */}
    </Draggable>
  )
}

export const WidgetItemBox = ({
  widgetGroupExpand,
  toggleWidgetGroupExpand,
  widgetType,
  widgetTitle,
  widget,
  handleClickContextIcon,
  id,
  children,
  ...props
}) => {
  console.log("id kung ", id);
  const history = useHistory();
  const handleClickEdit = () => {
    if (!props.onClickEdit) {
      history.push(`/dashboardv2/widget/${id}`, widget); 
    } else {
      props.onClickEdit();
    } 
      
    
  }

  return (
    <Container className={classnames('widget-item', {
      expanded: widgetGroupExpand === true,
      unexpanded: widgetGroupExpand === false,
    })}>
      <div className="widget-item--head">
        <div className="title" onClick={toggleWidgetGroupExpand}>
          <i className="fas fa-chevron-right icon"></i>&nbsp;&nbsp;<h3>{widgetType}{widgetTitle}</h3>
        </div>
        <div className="action-control" onClick={(e) => handleClickContextIcon(e)}>
          {/* <Link to={{
            pathname: `/dashboardv2/widget/${id}`, widget
          }} className="btn btn-sm btn-light"> */}
          <button className="btn btn-sm btn-light" onClick={handleClickEdit} style={{ cursor: "pointer" }}>
            <i className="typcn typcn-edit"></i><span>Edit</span>
          </button>
          {/* </Link> */}

          <i className="fas fa-ellipsis-v option"></i>

        </div>
      </div>
      <div className="widget-item--body">
        <div className="content">
          {children && children}

        </div>
      </div>
    </Container>
  )
}

export default WidgetGroup;

const Container = styled.div`
@keyframes widgetGroupBodyUnExpanded {
  0% {
    max-height: 1000px;
  }

  100% {
    max-height: 0px;
  }
}

@keyframes widgetGroupBodyExpanded {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 1000px;
  }
}
  margin-bottom: 1em;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* box-shadow: ${props => props.theme.boxShadow}; */
  .widget-item--head {
    /* display:none !important; */
    background: white !important;
    border: 1px solid ${props => props.theme.lineNav};
    border-bottom:2px solid ${props => props.theme.lineNav}; 
    border-radius: 5px;

    color: ${props => props.theme.primaryText};
    display: flex;
    align-items: center;
    cursor: pointer;

    .title {

      display: flex;
      align-items: center;
      flex:7;
      padding: 1em;
      h3 {
        text-transform: capitalize;
      }
      i {
        display: inline-block;
        transition: all 0.2s ease-in-out;
        z-index: 0;
      }
    }
    .action-control {
      /* flex:3; */
      /* font-size:.85rem; */
      display:flex;
      justify-content: flex-end !important;
      margin-left: auto;
      padding: 1em;
      justify-content: space-between;
      align-items: center;
      .btn {
        margin-right:1em;
        display:flex;
      }
    }
  }
  .widget-item--body {
    background: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s;
    border:1px solid white; 
    .content {
       /* height: auto; */
      background:#FEFAFF;
      padding: 1em 1em 1em 1em;
    }
  }

  &.expanded {
    .widget-item--body {
      max-height: 1000px;
      border:1px solid ${props => props.theme.lineNav};
      border-top:white;
    }

    .widget-item--head {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      .icon {
        transform: rotate(90deg);
      }
    }
  }

  &.unexpanded {
    .widget-item--body {
      max-height: 0px;
    }
  }


`;