import React, { useEffect, useState } from "react";
import "./Payment.scss";
// import axios from "axios";

const Payment = () => {
  const snap = window.snap;
  const [snapToken, setSnapToken] = useState();
  useEffect(() => {

    async function fetchGetSnapToken() {
      // const AUTH_STRING = btoa("SB-Mid-server-08WQiPlI3emGylJf8gkOPmrL");
      const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
      // const url = "https://app.sandbox.midtrans.com/snap/v1/transactions"; 
      // const fire = await axios.post(`${BACKEND_URL}/getSnapToken`, {
      //   body: JSON.stringify({
      //     "transaction_details": {
      //       "order_id": "ORDER-101",
      //       "gross_amount": 10000
      //     }
      //   }),
      // })
      const fire = await fetch(`${BACKEND_URL}/getSnapToken`, {
        method: 'POST',
      });
      const data = await fire.json();
      setSnapToken(data.token);
      console.log(data);
      /*
      const fire = await fetch("https://app.sandbox.midtrans.com/snap/v1/transactions", {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `BASIC ${AUTH_STRING}`,
        },
        body: JSON.stringify({
          "transaction_details": {
            "order_id": "ORDER-101",
            "gross_amount": 10000
          }
        }),

      });

      const json = fire.json();
      console.log(json);
      */
    }
    fetchGetSnapToken();

  }, [snap]);

  useEffect(() => {
    console.log("yolo");
    var payButton = document.getElementById('pay-button');
    const handleOnClickPay = () => {
      snap.pay(snapToken);
    }
    if (snapToken) {
      payButton.addEventListener('click', handleOnClickPay, true);
    }
    return (() => {
      console.log("paybutton unmounted");
      payButton.removeEventListener('click', handleOnClickPay, true);
    });
  }, [snap,snapToken]);
  return (
    <div>
      yoloo
      <button id="pay-button">Pay!</button>
    </div>
  )
}

export default Payment;