import React from "react";
import styled from "styled-components";
const Container = styled.section`
  margin-bottom:3em;
  .title {
    margin-bottom:1.5em;
    font-size:1.5rem;
    line-height:0px;
    margin-bottom:30px;
  }
`;
const SectionBox = ({ title, body, style }) => {
  return (
    <Container style={style}>
      <div className="title">{title}</div>
      <div className="body">
        {body}
      </div>
    </Container>
  )
}


export default SectionBox;


