import React from "react";
import styled from "styled-components";
import { lighten } from 'polished';
import { Link, useHistory } from "react-router-dom";
import { Route } from "react-router-dom";
import { Auth } from "../../Entities";
import ConfigPageProfile from "./ConfigPageProfile";
import ConfigPageGeneral from "./ConfigPageGeneral";
import ConfigPageAppearance from "./ConfigPageAppearance";
import { ScreenContainer } from "../../components";
import UserBox from "./components/UserBox";

const ConfigPage = ({ userAuthData, db, firebase, setLoggedIn, setUserAuthData, userData, setUserData, ...props }) => {
  const history = useHistory();
  const handleClickLogout = async e => {
    e.preventDefault();
    const auth = new Auth(null, null, firebase);
    auth.clearCookies();
    await auth.logout();
    setLoggedIn(false);
    setUserAuthData(null);
    history.push("/login");
  }
  return (
    <ScreenContainer>
      <Route path="/dashboardv2/config/profile">
        <ConfigPageProfile userData={userData} setUserData={setUserData} userAuthData={userAuthData} db={db} />
      </Route>
      <Route path="/dashboardv2/config/general">
        <ConfigPageGeneral userData={userData} setUserData={setUserData} userAuthData={userAuthData} db={db} />
      </Route>
      <Route path="/dashboardv2/config/appearance">
        <ConfigPageAppearance userData={userData} setUserData={setUserData} userAuthData={userAuthData} db={db} />
      </Route>
      <Route exact path="/dashboardv2/config">
        <Container>
          <UserBox userData={userData} />
          <ul>
            <li><Link to="config/profile"> <i className="fas fa-user"></i>&nbsp;&nbsp;Profile</Link></li>
            <li><Link to="config/general"> <i className="fas fa-cog"></i>&nbsp;&nbsp;General</Link></li>
            <li><Link to="config/appearance"> <i className="fas fa-paint-brush"></i>&nbsp;&nbsp;Appeareance</Link></li>
            <li><a href="logout" onClick={handleClickLogout}> <i className="fas fa-power-off"></i>&nbsp;&nbsp;Logout</a></li>
          </ul>
        </Container>
      </Route>

    </ScreenContainer>
  )
}

const Container = styled.div`
 display:flex;
 flex-direction:column;
 ul {
   list-style:none;
   li {
    border-bottom:1px solid ${props => props.theme.lineNav};
    height:3em;
    a {
      display:flex;
      height:100%;
      align-items:center;
      text-decoration:none;
      padding-left:1em;
      color: ${props => props.theme.primaryText};
      &:hover {
        background: ${props => lighten(0.05, props.theme.lineNav)}
      }
    }
   }
 }
`;

export default ConfigPage;