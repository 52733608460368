import React from "react";
import styled from "styled-components";
import MaxContainer from "../../components/MaxContainer";

const TextWidgetPreview = ({ widget }) => {
  return (
    <MaxContainer>
      <Container>


        <h3>{widget?.title}</h3>
        <pre style={{
          whiteSpace: 'pre-wrap'
        }}>{widget?.text}</pre>


      </Container>
    </MaxContainer>
  )
}

const Container = styled.div`
  background:white;
  border-radius:10px;
  padding: 1em 1em;
  box-shadow:0px 5px 10px rgba(170,145,196,0.045);
  margin-bottom:1.5em;
  @media (max-width:415px) {
    margin-left:1em;
    margin-right:1em;
  }
`;



export default TextWidgetPreview;