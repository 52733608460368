import React, { useState, useEffect, useContext } from "react";
import firebase from "../../utilities/firebaseContext";
import { VerifiedBadge } from "../../components";
import styled, { ThemeContext } from "styled-components";
import placeHolderAvatar from "../../assets/images/avatar_placeholder.png";

const ProfileCoverWidgetPreview = ({ userData, widget }) => {
  const [profileImageUrl, setProfileImageUrl] = useState();
  const themeContext = useContext(ThemeContext);
  const currentTheme = userData?.theme;
  // const {  subTitle,bio } = widget;
  const subTitle = userData?.subTitle ?? widget?.subTitle;
  const bio = userData?.bio ?? widget?.bio;

  const storage = firebase.storage();

  useEffect(() => {
    // console.log("userDatakyunnn", userData);
    // const profileImagePath = widget?.profileImagePath
    const profileImagePath =
      widget?.profileImagePath ?? userData?.profileImagePath;

    if (!profileImagePath) return;
    const pathReference = storage.ref(profileImagePath);
    pathReference.getDownloadURL().then((url) => {
      setProfileImageUrl(url);
    });
    // eslint-disable-next-line
  }, [userData]);
  return (
    <Container
      // background={currentTheme === "custom" ? themeContext?.background : themeContext?.secondaryBackground}
      background={themeContext?.secondaryBackground}
      // background="red"
    >
      {/* <MaxContainer style={{

      }}> */}
      {
        /*profileImageUrl && */ <ProfileImage
          className="avatar-container"
          src={profileImageUrl ?? placeHolderAvatar}
        />
      }
      <div className="right-side">
        <span className="username">
          {userData?.displayName ? userData?.displayName : userData?.username}{" "}
          {userData?.verifiedUser ? <VerifiedBadge /> : null}
        </span>
        <span className="subtitle">{subTitle}</span>
        <pre className="bio">{bio}</pre>
      </div>
      {/* </MaxContainer> */}
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => {
    /* console.log("proprrrr ",props); */
    return  props?.background ? props?.background : props?.theme?.secondaryBackground ? props?.theme?.secondaryBackground : "white"
  }};
  /* background:red; */
  display: flex;
  padding: 1.5em 1.5em;
  box-shadow: 0px 5px 10px rgba(170, 145, 196, 0.045);
  border-radius: 5px;
  max-width: 768px;
  align-self: center;
  margin: 0 auto;
  margin-bottom: 1.5em;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  .right-side {
    display: flex;
    flex-direction: column;
    .username {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1rem;
      margin-bottom: 0.25em;
      color: ${(props) => props?.theme?.primaryText ?? "#000"};
    }
    .subtitle {
      font-size: 1rem;
      color: #7c7b7b;
      margin-bottom: 0.5em;
    }
    .bio {
      font-size: 1rem;
      white-space: pre-wrap;
      color: ${(props) => props?.theme?.primaryText ?? "#000"};
    }
  }

  @media (max-width: 415px) {
    flex-direction: column;
    align-items: center;
    .username,
    .subtitle,
    .bio {
      text-align: center;
    }
    .username {
      margin-top: 1em;
    }
    .avatar-container {
      margin-right: 0;
    }
  }
`;

const ProfileImage = styled.div`
  width: 100px;
  height: 100px;
  min-width: 100px;
  background-size: cover;
  border-radius: 50%;
  background-image: url("${(prop) => prop.src}");
  margin-right: 1.5em;
  background-repeat: no-repeat;
`;

export default ProfileCoverWidgetPreview;
