import React from "react";
import LandingTemplate from "../../templates/LandingTemplate/LandingTemplate";
import illustration from "../../assets/images/illustrations/undraw_team_work_k-80-m.svg";
import Fade from 'react-reveal/Fade';

const AboutPage = () => {
  return (
    <LandingTemplate>
      <div
        className="common-page maxw-container"
        style={{
          display: "flex",
          flex: 1,
          // backgroundColor:"red",
          flexDirection: "column",

          paddingTop: "3em"
        }}>
        <h1 style={{ textAlign: 'center' }}>About <span className="accent">Us</span></h1>
        <Fade bottom>
          <img src={illustration} alt="about us illustration" style={{
            width: 500,
            maxWidth:"100%",
            height: 350,
            alignSelf: "center",
            marginTop: "2em"
          }} />
        </Fade>
        <Fade bottom>
        <div style={{
          marginTop: "2em",
          fontWeight: "500",
          fontSize: "1.25rem",
          textAlign: "center",
          marginBottom:"2em"
        }}>
          
          <p>Perona's vision is to help creators or business owners, whether you're just getting started or you already have fanbase, Perona helps you to connect and engage your userbase by gathering links or any type of content you make into a single place that your userbase can access easily.</p>
          <p style={{ marginTop: "1em" }}>Perona's story starts with a single person (which btw still single) , and Indonesian kid who's bored with his 9 to 5 job as a web developer and decided to make a simple project that he can mantain longterm. </p>
        </div>
        </Fade>
      </div>
    </LandingTemplate>
  )
}

export default AboutPage;