import { format, parse } from "date-fns";

function mapApiToApp({ data, type }) {
  if (type === "analytics") {
    return mapToApiInsights({ data });
  }
}

export function mapToApiInsights({ data }) {
  const mapped = data.rows.map(item => {
    console.log("item iz ", item);
    const dateStr = item?.dimensionValues[2]?.value;
    const parsedDate = parse(dateStr, "yyyyMMdd", new Date());
    const formattedDate = format(parsedDate, "dd MMM yyyy");
    const count = item?.metricValues[0]?.value;
    return {
      date: formattedDate,
      profile_view: count,
    };
  });
  return mapped;
}

export function maxDecimalPlaces(number) {
  return number.toFixed(Math.max(2, (number.toString().split('.')[1] || []).length));
}

export default mapApiToApp;