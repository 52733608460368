import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "styled-components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavLink from "./NavLink";

const ENV = process.env.REACT_APP_ENVIRONMENT;

const Container = styled.div`
  display:flex;
  height: 4em;
  
  border-top:1px solid ${props => props?.theme?.borderColor};
  background:white;
  box-sizing:border-box;
  margin-top:auto;
  justify-content:space-between;
  position:fixed;
  bottom:0;
  width:100%;
  .nav-link {
    
    .nav-icon {
      font-size: 21px;
    }
    span {
      text-align:center;
    }
  }
`;
const BottomBar = ({userData, userAuthData}) => {
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const { t } = useTranslation();
  // console.warn("userAuthDatapower",userAuthData);
  return (
    <Container className="app-bottom-nav" theme={theme}>
      {true && <NavLink icon="typcn typcn-home-outline" iconActive="typcn typcn-home" name={t('HOME')} link="/dashboardv2" location={location} userData={userData} userAuthData={userAuthData} />}
      <NavLink icon="typcn typcn-th-large-outline" iconActive="typcn typcn-th-large" name="Widgets" link="/dashboardv2/widgets" location={location} userData={userData} userAuthData={userAuthData} />
      <NavLink icon="typcn typcn-eye-outline" iconActive="typcn typcn-eye" name="Preview" link="/dashboardv2/preview" location={location} userData={userData} userAuthData={userAuthData} />
      { ENV!=="production" && <NavLink icon="typcn typcn-bell" iconActive="typcn typcn-bell active" name="Notifikasi" link="/dashboardv2/notifications" location={location} userData={userData} userAuthData={userAuthData}  />}
      <NavLink icon="typcn typcn-cog-outline" iconActive="typcn typcn-cog" name={t('CONFIG')} link="/dashboardv2/config" location={location} userData={userData} userAuthData={userAuthData}  />
    </Container>
  )
}


export default BottomBar