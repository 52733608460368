import React, { useContext } from "react";
import LinkWidgetPreview from "./LinkWidgetPreview";
import { ThemeContext } from 'styled-components';
const LinkWidgetPreviews = ({ itemIndex, widget, userData, ...props }) => {
  const themeContext = useContext(ThemeContext);
  console.warn("themeContext ");
  return (
    <div style={{
      marginTop:"1.5em"
    }}>
    <ul key={itemIndex} style={{ listStyle: 'none', marginBottom: '1.5em' }}>
      {widget.links.map((link, linkIndex) => {
        return (
          <LinkWidgetPreview key={linkIndex} link={link} linkIndex={linkIndex} themeContext={themeContext} userData={userData} />
        )
      })}

    </ul>
    </div>
  )
}


export default LinkWidgetPreviews;