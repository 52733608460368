import classNames from "classnames";
import React from "react";
import { useState } from "react";
import styled from "styled-components";

const BottomSheet = ({
  title,
  renderContent,
}) => {
  const [minimize, setMinimize] = useState(false);

  const handleClickToggleButton = () => {
    toggleBottomSheet();
  }

  const toggleBottomSheet = () => {
    setMinimize(!minimize);
  }
  return (
    <Container className={classNames({ minimize })}>
      <div style={{
        padding: "0em 1em"
      }}>
        <button className={classNames("toggle-button")} onClick={handleClickToggleButton}><i className={classNames("fas", {
          "fa-chevron-up": minimize,
          "fa-chevron-down": !minimize,
        })} /></button>
        {!minimize && (
          <h1>{title}</h1>
        )}

      </div>
      {!minimize && (
        renderContent()
      )}

    </Container>
  )
}


const Container = styled.div`
  background:#fff;
  box-shadow:0px -5px 10px #8f8f8f0a;
  position: absolute;
  width:100%;
  bottom:0;
  padding-top: 0em;
  padding-bottom:1em;
  box-sizing:border-box;
  transform:translate(0px, 0px);
  animation-name: slide;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
  border-top-left-radius: 16px;
  border-top-right-radius:16px;
  @keyframes slide {
    from {transform: translate(0px, 100px)}
    to {transform: translate(0px, 0px)}
  }
  &.minimize {
    /* background:red; */
  }
  h1 {
    font-weight:500;
    font-size:26px;
  }

  .toggle-button {
    border:none;
    background:transparent;
    /* margin:auto; */
    display:flex;
    /* background:red; */
    width:100%;
    text-align:center;
    justify-content: center;
    padding-bottom:.5em;
    padding-top:1em;
    
    i {
      font-size:16px;
    }
  }

`;
export default BottomSheet;