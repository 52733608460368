import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display:flex;
  flex-direction:column;
  text-align:center;
  cursor:default;
  user-select:none;
  .title {
    font-size: .85rem;
    text-transform:uppercase;
  }
  .number {
    font-weight:bold;
    font-size:3rem;
  }
  .rate {
    font-size:.85rem;
  }

  @media only screen and (max-width:414px) {
     .title {
       font-size: .5rem;
     }
   }
`;


const StatCard = ({ title, number, rate }) => {
  return (
    <Container className="stat-card">
      <span className="title">{title}</span>
      <span className="number">{number}</span>
      <span className="rate">{rate}</span>
    </Container>
  )
}

export default StatCard;