import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import "./i18nextConf";
// import * as serviceWorker from './serviceWorker';


const rootElement = document.getElementById("root");
ReactDOM.render(<Router><App /></Router>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
