import React from "react";

const Draggable = ({ children, itemIndex, onDragStart, onDragOver, onDrop }) => {
  const handleDrop = (event) => {
    console.log("drop");
    onDrop({ itemIndex, event });
  }
  const handleDragOver = (event) => {
    event.preventDefault();
    console.log("yo");
    onDragOver();
  }
  const handleDragStart = (event) => {
    console.log("start", itemIndex);
    onDragStart({ itemIndex, event });
  }
  return (
    <>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          width: "100%",
          // height: "10em",
          // backgroundColor: "red",
          // marginBottom: 50,
        }}>
        <div style={{
        }}>
          <div
            id="hahay"
            draggable


            onDragStart={handleDragStart}
            style={{
            }}>
            {children}
          </div>
        </div>

      </div>
    </>
  )
}

export default Draggable;