import React from "react";
import "./WhiteCard.scss";

const WhiteCard = ({ children, title, ...props }) => {
  return (
    <div className="whitecard">
      {title && <h2>{title}</h2>}
      <div className="whitecard-body">
        {children}
      </div>

    </div>
  )
}

export default WhiteCard;