import React, { useRef, useState } from "react";
import styled from "styled-components";
import Avatar from "../Avatar";

const AvatarUpload = ({name,style}) => {
    const [image,setImage] = useState();
    const inputFile = useRef();

    const handleClick = () => {
        inputFile.current.click();
    }

    const handleChange = (e) => {
        const file = e.target?.files[0];
        const tempUrl = URL.createObjectURL(file);
        setImage(tempUrl);
        
    }
    return(
        <Container style={style}>
            <input name={name} type="file" ref={inputFile} accept=".jpg,.png,.jpeg" onChange={handleChange}/>
            <Avatar src={image} onClick={handleClick} />
        </Container>
    )
}

const Container = styled.div`
    margin-top:1em;
    &:hover img {
        filter: grayscale(55%);
        filter:brightness(40%);
    }
    input[type="file"] {
        display:none;
    }
    img {
        width:100%;
        height:100%;
        &:hover {
            // filter: grayscale(55%);
            // filter:brightness(40%);
        }
        
    }
`;

export default AvatarUpload;