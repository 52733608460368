import React from "react";
import { CopyableWidgetPreview } from "../../widgets";

const CopyableWidgetManage = ({ widget }) => {
  console.log('widget is',widget);
  return (
    <CopyableWidgetPreview widget={widget} />
  )
}

export default CopyableWidgetManage;