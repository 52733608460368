import React from "react";
import MaxContainer from "../../components/MaxContainer";
import socialMaster from "./socialMaster";
import { withTheme } from 'styled-components'

const SocialMediaWidgetPreview = ({ widget, link, userData, linkIndex, themeContext, theme }) => {
  // console.log("theme hey ", theme);
  const socials = userData?.socials;
  console.log("socialsaree", socials)
  const links = generateSocialLinks({ socials });
  return (
    <>
      {/* <span>
        <pre>{JSON.stringify({ links }, null, true)}</pre>
      </span> */}
      <MaxContainer>
        <div style={{
          display: "flex",
          flexDirection: "row", justifyContent: 'center',
          marginBottom: "1.5em"
        }}>

          {links.map((item, index) => {
            // const {link } =
            const href = generateHref(item);
            const { url } = item || {};
            console.log("href iz ", href);
            return (
              <div
                key={index}
                style={{
                  marginLeft: 6,
                  marginRight: 6,
                }}>
                <a href={url} target="_new">
                  <i className={item?.iconClass} style={{ fontSize: "1.85rem", color: theme?.primaryText ?? "#000" }} />
                </a>
              </div>
            )
          })}
        </div>
      </MaxContainer>
    </>
  )
}

const generateSocialLinks = ({ socials }) => {
  if (!socials) return [];
  const mappedSocials = socials?.map?.(item => {
    const value = item?.username;
    const { url } = item || {};
    const socialData = socialMaster.find(i => i?.value === item?.social);
    const obj = {
      value,
      url,
      ...socialData
    }
    return obj;
  })
  return mappedSocials;
  // let icons = [];
  // for (const name in socials) {
  //   const value = socials[name];
  //   const socialData = socialMaster[name];
  //   const obj = {
  //     value,
  //     ...socialData
  //   }
  //   icons.push(obj);
  // }
  // console.log("icons generated yo ", icons);
  // return icons;
}

const generateHref = ({ name, value, ...options }) => {
  const baseUrl = options?.baseUrl;
  let href = "https://google.com";
  if (["instagram", "twitter"].includes(name)) {
    href = `${baseUrl}/${value}`;
  }
  return href;
}

export default withTheme(SocialMediaWidgetPreview);