import React from "react";
import PricingPlans from "../../components/PricingPlans/PricingPlans";

import LandingTemplate from "../../templates/LandingTemplate/LandingTemplate";

const PricingPage = () => {
    // const [showMenu, setShowMenu] = useState(false);

    // const handleClickBurger = () => {
    //     setShowMenu(!showMenu);
    // }
    return (
        <LandingTemplate>
            <div
                className="common-page maxw-container"
                style={{
                    display: "flex",
                    flex: 1,
                    // backgroundColor:"red",
                    flexDirection: "column",
                
                    paddingTop: "3em"
                }}>
                <h1 style={{ textAlign: 'center' }}>Flexible <span className="accent">Plans</span></h1>

                <p className="somewords">Choose a plan that works best for you and your team</p>
                <p className="somewords" style={{fontSize:"12px"}}>(Note: We're still working on the Pro tier. Stay tune!)</p>

                <PricingPlans plans={plans} />
            </div>
        </LandingTemplate>
    )
}

const plans = [
    {
        name:"basic",
        title:"Basic",
        price_usd:0,
        popular:true,
        primary:true,
        description:"Basic needs for your profile at no cost hehe",
        features: [
            "Free single username",
            "Single link profile",
            "Basic widgets",
            "Basic insights"
        ],
    },
    {
        name:"pro",
        title:"Pro",
        price_usd:3,
        // popular:true,
        // primary:true,
        description:"Basic needs for your profile at no cost hehe",
        features: [
            "All Basic Features",
            "Full Analytics Feature",
            "Special Widgets",

        ],
    },
]; 

export default PricingPage;
