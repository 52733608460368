import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
const Container = styled.main`
   /* background:white; */
   background:${props => props.pathname.includes("/preview") ? "#FEFAFF" : "white"};
   /* min-height:100vh; */
   /* overflow-y:scroll; */
   /* padding:5em 1em 4em 1em; */
   /* box-sizing:border-box; */


   /* @media only screen and (max-width:414px) {
     & {
      padding:5em 1em 13em 1em;
     }
   } */
`;
const Main = ({ children }) => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <Container className="app-main" pathname={pathname}>
      {children}
    </Container>

  )

}

export default Main;