import React, { useContext } from "react";
import { FireStoreContext } from "../../utilities/firebaseContext";
import { Widget } from "../../Entities";
import { useHistory } from "react-router-dom";
import { TextInput, SubmitButton, Label } from "../";
import UserAuthContext from "../../context/UserAuthContext";

const LinkWidgetForm = ({ crud, toast, submitting, setSubmitting, setToastCall, setShowToast }) => {
  const db = React.useContext(FireStoreContext);
  const history = useHistory();
  const userAuthData = useContext(UserAuthContext);
  const handleOnSubmit = async (e) => {
    e.persist();
    e.preventDefault();
    const uid = userAuthData?.uid;
    if (!uid) return;
    const data = new FormData(e.target);
    const title = data.get('title');
    const url = data.get('url');
    if (!title || !url) return;
    const newWidget = {
      type: 'link',
      links: [{ title, url }
      ]
    };

    setSubmitting(true);
    const WidgetStore = new Widget(uid, db);
    await WidgetStore.addNewWidget(newWidget);
    setSubmitting(false);
    // setShowToast(true);
    console.log("setToastCall iz", setToastCall);

    setToastCall({
      type: "success",
      message: "New Widget successfully added"
    });

    // alert("New Widget successfully added");

    history.push("/dashboardv2/widgets");

  }
  return (
    <form onSubmit={handleOnSubmit} method="post">
      <Label htmlFor="title" popOverText="Label yang akan ditampilkan">Label</Label>
      <TextInput type="text" name="title" id="title" className="default-input-text" placeholder="Title" autoComplete="off" required />
      <Label htmlFor="url" popOverText="URL yang digunakkan untuk mengarahkan User ke website tertentu">URL</Label>
      <TextInput type="url" name="url" className="default-input-text" placeholder="URL" autoComplete="off" required />
      <SubmitButton type="submit" value="Submit" submitting={submitting} spinnerColor="white" />


    </form>
  )

}

export default LinkWidgetForm;