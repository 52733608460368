import styled from "styled-components"
import React from "react";

const AddButton = ({ onClickAdd, children }) => {
  return (
    <AddButtonStyled onClick={onClickAdd} className="addbutton">{children}</AddButtonStyled>
  )
}
const AddButtonStyled = styled.button`
  background: ${props => `${props.theme.success}`};
  border:none;
  font-size: 1.15rem;
  color:white;
  padding: .5em 1em;
  border-radius:10px;
  margin-bottom:1em;
  cursor:pointer;
  
`;

export default AddButton