import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "../../Entities";
const AuthSuccessPage = ({ db, firebase, setLoggedIn }) => {
  const history = useHistory();

  useEffect(() => {
    async function start() {
      if (!db || !history || !firebase) return;
      const auth = new Auth(null, db);
      const userIsNew = await checkIfUserIsNew();
      if (userIsNew) {
        await createUserCollection();
      }
      auth.getUsername().then(username => {
        setLoggedIn(true);

      }).catch(e => {
        history.push("/dashboardv2");

      });

    }
    start();
    // eslint-disable-next-line
  }, [db, history, firebase]);

  async function checkIfUserIsNew() {
    try {
      const uid = await getUserUid();
      const userExistInFirestore = await checkIfUidInFirestore(uid);
      return userExistInFirestore;
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  function getUserUid() {
    return new Promise((resolve,reject) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            const uid = user.uid;
            resolve(uid);
          } else {
            reject("user iz not logged in");
          }
        });
      
    });
  }

  function checkIfUidInFirestore(uid) {
    return new Promise(async (resolve,reject) => {
      const userRef = await db.collection('users').doc(uid).get();
      const exists = userRef.exists;
      const isNew = !exists;
      resolve(isNew);
    });
  }

  function createUserCollection()  {
    return new Promise(async (resolve, reject) => {
      const uid = await getUserUid();
      debugger;
      if(!uid) return;
      const userRef = await db.collection('users').doc(uid);
      await userRef.set({
        active: true
      }, { merge: true });
      resolve(true);
    });

  }
  return (
    <p>Success Sign In.. Please wait</p>
  )
}

export default AuthSuccessPage;