import React, { Suspense, useEffect } from "react";
import TopBar from "./TopBar"
import styled, { ThemeProvider } from "styled-components";
import Main from "./Main";
import BottomBar from "./BottomBar";
import { Route, useHistory,  } from "react-router-dom";
import HomeDashboard from "../../pages/HomeDashboard/HomeDashboard";
// import WidgetManager from "../../pages/Widgets/WidgetManager";
import WidgetForm from "../../pages/Widgets/WidgetFormV2";
import WidgetPickerPage from "../../pages/WidgetPickerPage/WidgetPickerPage";
import PreviewProfilePage from "../../pages/PreviewProfilePage/PreviewProfilePage";
import ConfigPage from "../../pages/ConfigPage/ConfigPage";
import LinkWidgetEditor from "../../components/LinkWidgetV2/LinkWidgetEditor";
import WidgetEditorForm from "../../components/WidgetEditorForm/WidgetEditorForm";
import { User } from "../../Entities";
import ToastContext from "../../context/ToastContext";
import UserDataContext from "../../context/UserDataContext";
import ArticlePage from "../../pages/ArticlePage/ArticlePage";

// const ENV = process.env.REACT_APP_ENVIRONMENT;

const theme = {
  borderColor: '#E8E0FF',
  primary: '#5F31E0',
  success: '#66D8C6',
  lineNav: '#E8E0FF',
  boxShadow: '0px 5px 10px rgba(170, 145, 196, 0.3)',
  secondaryText: '#ffffff',
  primaryText: '#7b52eb',
}
const Container = styled.div`
  display:flex;
  width:100%;
  flex-direction:column;
  height:100vh;
  margin:0 auto;
  @media(min-width:1200px) {
    /* background:red; */
    & {
      /* background:green; */
      width:768px;
      
    }
    .app-top-bar {
      width:768px;
      z-index:1; /* for chevron right icon show in perona top bar */
    }

    .app-bottom-nav {
      width:768px;
    }
  }
`;
const WidgetManager = React.lazy(() => import("../../pages/Widgets/WidgetManager"));
const DashboardV2 = ({ activeTopNavMenu, setActiveTopNavMenu, widgets, setWidgets, firebase, db, userAuthData, setShowOverlay, setShowActionSheet, setWidgetGroupTarget, setWidgetContentTarget, selectedWidget, setSelectedWidget, userData, setUserData, setFrontForms, setCurrentFrontForm, setUserAuthData, loggedIn, setLoggedIn, ...props }) => {
  // debugger;
  // return null;
  const history = useHistory();
  const [toastCall, setToastCall] = React.useState();
  // const location = useLocation();
  // console.log("locationkunggg",location);

  useEffect(() => {
    // return;
    if (!firebase) return;
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        // console.info('User sign in ', user);
        setLoggedIn(true);
        setUserAuthData(user);
      } else {
        console.log("You haven't signed in");
        console.log("history is", history);
        // if (history?.location?.pathname.includes("dashboard")) {
        //   history.push("/");
        //   setLoggedIn(false);
        // }


      }
    });
    // eslint-disable-next-line
  }, []);

  /* set userdata hook from firestore collection 'users */
  useEffect(() => {
    async function start() {
      const uid = userAuthData?.uid;
      if (!uid) return;
      const u = new User(uid, db);
      const userData = await u.getUserData();

      if (userData) {
        /* check if userData.username has been set */
        let front_forms = [];
        if (!userData?.username) {
          // front_forms.push("/pick_username");
        }
        if (!userData?.theme) {
          // front_forms.push("/pick_theme")
        }
        if (front_forms.length > 0) {
          setFrontForms(front_forms);
          history.push(front_forms[0]);

        }

        setUserData(userData);
      }
    }

    if (db) start();

  }, [db, setUserData, setFrontForms, history, userAuthData]);

  /* auth checker */
  useEffect(() => {
    console.log("loggedIn iz", loggedIn);
  }, [loggedIn]);


  /* redirect to final wizard signup */
  useEffect(() => {
    // if (ENV !== "development") return;
    // history.push("/wizard");
    // return;
    if (userData && userAuthData && db) {
      if (!userData?.finishSignupWizard) {
        history.push("/wizard");
      }
    }
    // eslint-disable-next-line
  }, [userData, db, userAuthData]);

  return (
    <ThemeProvider theme={theme}>

      <UserDataContext.Provider value={{ userData, setUserData }}>
        <ToastContext.Provider value={{ toastCall, setToastCall }}>
          {/* <MainContainer> */}
          <Container className="dashboard-container">
            <TopBar userData={userData} userAuthData={userAuthData} />
            <Main>
              <Route path="/dashboardv2">
                {true && <HomeDashboard />}
                {/* {ENV === "production" && <Redirect to="/dashboardv2/widgets" />} */}
              </Route>

              <Route
                // exact
                path="/dashboardv2/add_widget/:widget_type">
                <WidgetForm crud="create" setToastCall={setToastCall} />
              </Route>
              <Route exact path="/dashboardv2/pick_widget">
                <WidgetPickerPage userAuthData={userAuthData} />
              </Route>
              <Route exact path="/dashboardv2/widgets">
                <Suspense fallback={<p>loading</p>}>
                  <WidgetManager widgets={widgets} setWidgets={setWidgets} firebase={firebase} db={db} userAuthData={userAuthData} setShowOverlay={setShowOverlay} setShowActionSheet={setShowActionSheet} setWidgetGroupTarget={setWidgetGroupTarget} setWidgetContentTarget={setWidgetContentTarget} setSelectedWidget={setSelectedWidget} setToastCall={setToastCall} toastCall={toastCall} userData={userData} />
                </Suspense>
              </Route>
              <Route exact path="/dashboardv2/widgets/:id/add">
                <Suspense fallback={<p>loading</p>}>

                  <LinkWidgetEditor setToastCall={setToastCall} toastCall={toastCall} {...props} />
                </Suspense>
              </Route>

              <Route
                // exact
                path="/dashboardv2/widget/:id">
                <WidgetEditorForm userAuthData={userAuthData} setToastCall={setToastCall} toastCall={toastCall} {...props} />
                {/* <LinkWidgetEditor userAuthData={userAuthData} {...props} /> */}
              </Route>
              {/* <Route exact path="/dashboardv2/widget/:id/edit/:index"> */}
              {/* <LinkWidgetEditor userAuthData={userAuthData} setToastCall={setToastCall} {...props} /> */}
              {/* <WidgetEditorForm userAuthData={userAuthData} setToastCall={setToastCall} toastCall={toastCall} {...props} /> */}
              {/* </Route> */}
              <Route exact path="/dashboardv2/preview">
                <PreviewProfilePage db={db} userData={userData} setUserData={setUserData} />
              </Route>
              <Route exact path="/dashboardv2/notifications">
                <p>notif</p>
              </Route>
              <Route path="/dashboardv2/config">
                <ConfigPage userAuthData={userAuthData} db={db} userData={userData} setUserData={setUserData} firebase={firebase} setUserAuthData={setUserAuthData} setLoggedIn={setLoggedIn} />
              </Route>

              <Route path="/dashboardv2/articles/:id">
                <ArticlePage />
                {/* <h1>hello there</h1> */}
                {/* <ArticlePage /> */}
              </Route>
            </Main>
            <BottomBar userData={userData} userAuthData={userAuthData} />

          </Container>
          {/* </MainContainer> */}
        </ToastContext.Provider>
      </UserDataContext.Provider>
    </ThemeProvider>
  )
}

export default DashboardV2;