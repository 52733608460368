import React, { useState } from "react";
import styled from "styled-components";
import { User } from "../Entities";
import { isEmail, isAlphaNumeric } from "../utilities/coreutils";
import { TextInput, SubmitButton, Label } from "../components";
import { AlertBox } from "../components/forms";
import { useTranslation } from "react-i18next";

// const appDomain = document.location.origin;
const appDomain = 'https://perona.id'

const SignUpForm = ({ firebase, db, history, toggleAuthType, setAuthTypeState }) => {

  const [username, setUsername] = useState();
  const [domainWithUsername, setDomainWithUsername] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertObject, setAlertObject] = useState();
  const { t, i18n } = useTranslation();
  const currentLang = i18n?.language;

  const handleSubmit = e => {
    e.preventDefault();
    const email = e.target.email.value;
    const username = e.target.username.value;
    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;
    const signupObj = { email, username, password, confirmPassword };
    doSignup(signupObj);
  }

  const doSignup = async ({ email, username, displayName, password, confirmPassword }) => {
    setSubmitting(true);
    if (!isEmail(email)) {
      setAlertObject({ title: "Email tidak valid", type: "danger", message: "Mohon gunakan alamat email yang valid." });
      setShowAlert(true);
      window.scrollTo(0, 0);
      setSubmitting(false);
    }
    else if (!isAlphaNumeric(username)) {
      setAlertObject({ title: "Username tidak valid", type: "danger", message: "Mohon input username yang valid. Hanya boleh menggunakan kombinasi karakter huruf, angka dan simbol underscore" });
      setShowAlert(true);
      window.scrollTo(0, 0);
      setSubmitting(false);
    }
    else if (password !== confirmPassword) {
      setAlertObject({ title: "Password dan Konfirmasi Password tidak sama", type: "danger", message: "Mohon samakan input Password dan Konfirmasi Passowrd" });
      setShowAlert(true);
      window.scrollTo(0, 0);
      setSubmitting(false);
    }
    else if (await checkUsernameExists(username)) {
      setAlertObject({ title: "Username Telah Terdaftar", type: "danger", message: "Username yang Anda input telah terdaftar. Mohon coba lagi input username." });
      setShowAlert(true);
      window.scrollTo(0, 0);
      setSubmitting(false);
    } else {
      createUser({ email, password, username, displayName });



    }
  }

  const createUser = ({ email, password, username, displayName }) => {
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(async (user) => {
        // alert("dududu");
        console.log("user created ", user);
        console.log("sendEmailVerification iz ",user?.user?.sendEmailVerification);
        await user?.user?.sendEmailVerification();
        setSubmitting(false);
        setAuthTypeState("email_verification_sent");
        await createUserCollection({ email, username, displayName });
        // history.push("/dashboardv2");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const errorPhrase = `[${errorCode}] ${errorMessage}`;
        setAlertObject({ title: "Signup Failed", type: "danger", message: errorPhrase });
        setShowAlert(true);
        window.scrollTo(0, 0);
        setSubmitting(false);
      });
  }

  const checkUsernameExists = async username => {
    const u = new User(null, db);
    const res = await u.getTotalUsernames(username);
    let ret;
    if (res?.totalUsernames > 0) {
      ret = true;
    } else {
      ret = false;
    }
    return ret;
  }

  function createUserCollection({ username, email, displayName }) {
    return new Promise(async (resolve, reject) => {
      const uid = await getUserUid();
      if (!uid) return;
      const userRef = await db.collection('users').doc(uid);
      await userRef.set({
        active: true,
        username,
        email,
        displayName: displayName ? displayName : '',
        lang: currentLang,
        signUpDate: new Date(),
      }, { merge: true });
      resolve(true);
    });

  }

  function getUserUid() {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const uid = user.uid;
          resolve(uid);
        } else {
          reject("user iz not logged in");
        }
      });

    });
  }

  const handleChangeUsername = e => {
    const username = e.target.value;
    const domainWithUsername = `${appDomain}/${username}`;
    setUsername(username);
    setDomainWithUsername(domainWithUsername);
  }


  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-head">
        <h1>Sign Up</h1>
        <p>{t('HAS_ACCOUNT')} <a href="/sign_up" onClick={toggleAuthType}>{t('SIGN_IN')}</a> {t('HERE')}.</p>
      </div>
      {showAlert && <AlertBox type={alertObject.type} title={alertObject.title}>{alertObject.message}</AlertBox>}

      <Label htmlFor="displayName" popOverText={t('DISPLAY_NAME_POPUP')}>{t('NAME')} ({t('OPTIONAL')})</Label>
      <TextInput type="text" id="displayName" name="displayName" placeholder={t('NAME')} />
      <Label htmlFor="email">Email</Label>
      <TextInput type="text" id="email" name="email" placeholder="Email" required />
      <Label htmlFor="username" popOverText={t('USERNAME_POPUP')}>Username</Label>
      <TextInput type="text" id="username" name="username" placeholder="Username" onInput={handleChangeUsername} autoComplete="off" maxLength={26} required />
      {username && <p>{t('YOUR_URL_PROFILE')} <UrlBox>{domainWithUsername}</UrlBox></p>}
      <Label htmlFor="password">Password</Label>
      <TextInput type="password" id="password" name="password" placeholder="Password" minLength="6" required />
      <Label htmlFor="confirmPassword">{t('CONFIRM')} Password</Label>
      <TextInput type="password" id="confirmPassword" name="confirmPassword" placeholder={`${t('CONFIRM')} Password`} minLength="6" />
      <SubmitButton type="submit" value={t('SIGN_UP')} submitting={submitting} spinnerColor="white" />
      {/* <button onClick={handleClickTestEmail}>Test Send Email</button> */}
    </Form>
  );

}

const Form = styled.form`
  display:flex;
  flex-direction:column;
`;

const UrlBox = styled.span`
  display:inline-block;
  background: #e3e3e3;
  padding: .2rem .4rem;
  border-radius:5px;
  margin-top: .25em;
`;


export default SignUpForm;