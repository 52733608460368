import React from "react";
import WidgetOption from "./WidgetOption";
import styled from "styled-components";
import Joyride from 'react-joyride';

const WidgetPicker = ({ selectedWidget, onSelectWidget, finishAddFirstWidget,finishPreviewWidget, style }) => {

  
  const widgets = [
    {
      name: "single_link",
      displayName: "Link",
      icon: "fas fa-link",
    },
    // {
    //   name: "profile_cover",
    //   displayName: "Profile Cover",
    //   icon: "fas fa-user",
    // },
    {
      name:"social_media",
      displayName:"Social Links",
      icon:"fab fa-twitter"
    },
    {
      name: "youtube",
      displayName: "Youtube",
      icon: "fab fa-youtube",
    },
    {
      name: "text",
      displayName: "Text",
      icon: "fas fa-align-left",
    },
    // {
    //   name: "link",
    //   displayName: "Stack Link",
    //   icon: "fas fa-layer-group",
    // },
    {
      name: "copyable",
      displayName: "Copyable",
      icon: "far fa-copy"
    },
    {
      name: "image",
      displayName: "Image",
      icon: "fas fa-images",
      disabled: true,
    },
    {
      name: "rss",
      displayName: "RSS Feed",
      icon: "fas fa-rss",
      disabled: true,
    },
    {
      name: "post",
      displayName: "Blog Post",
      icon: "fas fa-feather-alt",
      disabled: true,
    },
    {
      name: "spotify",
      displayName: "Spotify",
      icon: "fab fa-spotify",
      disabled: true,
    },
  ];

  const widgetOptions = widgets.map(widget => <WidgetOption key={widget.name} widget={widget} onSelectWidget={onSelectWidget} selectedWidget={selectedWidget} selected={widget?.name === selectedWidget?.name} />);

  const steps = [
    {
      target: '.widget-single_link',
      content: "Let's add a link to your profile, Click this button",
    },
    {
      target: '.btn-add-new-widget',
      content: 'After you select a widget, Click this button to continue'
    }
  ];

  return (
    <>
      {/*ENV === "development"*/  !finishAddFirstWidget && !finishPreviewWidget &&  <Joyride
        steps={steps}
      />}
      <WidgetPickerContainer widgets={widgets} finishAddFirstWidget={finishAddFirstWidget} style={{ ...style }}>
        {widgetOptions}
      </WidgetPickerContainer>
    </>
  )
}
// const ENV = process.env.REACT_APP_ENVIRONMENT;
const WidgetPickerContainer = styled.div`

  display:flex;
  flex-wrap:wrap;
  
  /* gap:1.15em; */
  margin-bottom:1em;
  /* justify-content:space-between; */
  /* background:pink; */

`;


export default WidgetPicker;