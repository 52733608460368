import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ThemePickerBottomSheet from "./components/ThemePickerBottomSheet";
import PreviewProfile from "../../components/PreviewProfile/PreviewProfile";
import { User } from "../../Entities";
import { useLocation } from "react-router-dom";
import firebase from '../../utilities/firebaseContext';
import lavenderTheme from "../../themes/lavenderTheme";
import skyblueTheme from "../../themes/skyblueTheme";
import h4x0rTheme from "../../themes/h4x0rTheme";
import ewenTheme from "../../themes/ewenTheme";

const ThemePickerPage = ({
  userAuthData,
  setUserAuthData,
  db,
  history,
}) => {
  const location = useLocation();
  const [widgets, setWidgets] = useState([]);
  const [userData, setUserData] = useState(null);
  const [themeName, setThemeName] = useState("lavender");
  const uid = userAuthData?.uid;


  useEffect(() => {
    async function start() {

      const sampleWidgets = [{
        id: "000",
        title: "Sample Link 1",
        type: "single_link",
        url: ""
      },
      {
        id: "001",
        title: "Sample Link 2",
        type: "single_link",
        url: ""
      }
      ];
      const sortedWidgets = [...sampleWidgets]
      setWidgets(sortedWidgets);

    }
    if (db && uid && history) start();

  }, [db, history, location, setWidgets, uid]);

  useEffect(() => {
    async function start() {
      const u = new User(uid, db);
      const userData = await u.getUserData();
      setUserData(userData);
    }
    if (uid && db)
      start();
    // eslint-disable-next-line
  }, [uid, db]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        setUserAuthData(user)
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleClickSlideItem = ({ name }) => {
    console.warn("name iz ", name);
    setThemeName(name);
    // alert("namekun ",name);
  }

  const handleClickSubmit = async (e) => {
    const uid = userAuthData?.uid;
    const u = new User(uid, db);
    await u.updateUserData({ theme: themeName });
    // history.push("/dashboardv2/widgets"); 
    window.location.href = "/dashboardv2/widgets/";
  }

  return (
    <Container>
      {/* <BottomSheet/> */}
      <PreviewProfile userData={userData} widgets={widgets} setWidgets={setWidgets} theme={themeName} />
      <ThemePickerBottomSheet onClickSlideItem={handleClickSlideItem} onClickSubmit={handleClickSubmit} theme={themeName} themes={themes} />
    </Container>
  )
}

const themes = [
  {
    name: "lavender",
    label: "Lavender",
    style: lavenderTheme,
  },
  {
    name: "skyblue",
    label: "Skyblue",
    style: skyblueTheme,
  },
  {
    name: "h4x0r",
    label: "h4x0r",
    style: h4x0rTheme,
  },
  {
    name: "ewen",
    label: "Ewen",
    style: ewenTheme,
  }
];

const Container = styled.div`
  background:red;
  min-height:100%;
  position:relative;
`;

// const theme = {
//   primary: '#c19aea',
//   background: '#f7f4fa',
//   primaryText: '#b485e6',
//   boxShadow: '0px 5px 10px rgba(170, 145, 196, 0.3)',
// }

export default ThemePickerPage;