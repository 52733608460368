import React from "react";
import styled from "styled-components";
import getUrlIllustration from "../../assets/images/illustrations/undraw_portfolio_feedback.svg";
const HomeLanding = () => {
  return (
    <Container>
      <Box>
        <img src={getUrlIllustration} style={{ width: "350px", height: "350px" }} alt="slide 2" />
        <h2>Landing Page Coming Soon</h2>
        <p>We are developing this page. Please stay tuned. </p>
        <p>If not ,refer to our app page below instead</p>
        <Anchor href="https://app.perona.id">Goto App</Anchor>

      </Box>
    </Container>
  )
}

const Container = styled.div`
  width:100%;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;

`;

const Box = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  h2 {
    margin-bottom:1em;
  }
  p {
    text-align:center;
  }
`;

const Anchor = styled.a`
  display:inline-block;
  background:purple;
  padding: 10px 10px;
  border-radius:10px;
  color:white;
  box-sizing:border-box;
  text-decoration:none;
  text-align:center;
  background:#ae76e8;
  margin-top:1em;
`;

export default HomeLanding;