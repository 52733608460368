import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import Fade from 'react-reveal/Fade';

const PricingPlans = ({
  plans
}) => {

  return (
    <StyledSections className="plans">
      {plans.map((plan, index) => {
        console.log("plan iz ",plan);
        const title = plan?.title;
        const description = plan?.description;
        const features = plan?.features;
        const priceUsd = plan?.price_usd;
        return (
          <Fade key={index} bottom>
          <Plan  className={classNames(
            "plan",
            {
              primary:plan?.primary
            }
          )}>
            <div className="head">
              <h3>{title}</h3>
              {plan?.popular && <span className="badge">POPULAR</span>}
              
            </div>
            <div className="price-container">
              <span className="price">${priceUsd}</span>
              <span className="per"> / Month</span>
            </div>
            <p className="description">{description}</p>
            <ul className="features">
              {features.map((item,index) => {
                return (
                  <li><i key={`${plan?.name}-ft-${index}`} index={index} className={classNames("fas fa-check-circle icon",{
                    primary:plan?.primary
                  })}></i> {item}</li>
                )
              })}
            </ul>
            <button className={classNames("button no-shadow",{
              "button-secondary":plan?.primary
            })} style={{
              marginBottom:0
            }}>{plan?.name === "basic" ? "Start Free" : "Choose Plan"} <i className="fas fa-chevron-right" style={{fontSize:".85rem"}}></i></button>
          </Plan>
          </Fade>
        )
      })}
    </StyledSections>
  );
}

const Plan = styled.div`
`;

const StyledSections = styled.section`
  /* background:pink; */
  margin-top:2em;
`;

export default PricingPlans; 