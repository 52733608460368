import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
// import { Button } from "../../components/forms";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router";
import { subDays, eachDayOfInterval } from "date-fns";
import InsightsDateContext from "../../context/InsightsDateContext";
// import MaxContainer from "../../components/MaxContainer";
import classNames from "classnames";

const FilterDatePage = ({
  filterDateMode,
  setFilterDateMode,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setStartDateFinal,
  setEndDateFinal,
  loading,
}) => {
  // const  [tempFilterDateMode, setTempFilterDateMode] = useState("last_7_days");; 
  const history = useHistory();
  const [highlightDates, setHighlightDates] = useState(LAST_7_DAYS_DATES);
  const insightsDateContext = useContext(InsightsDateContext);

  useEffect(() => {
    console.warn("insightsDateContext la ", insightsDateContext);
  }, [insightsDateContext]);

  const handleChange = dates => {
    if (filterDateMode === "custom") {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

    }
  };

  const handleClickFilterMode = (mode) => {
    setFilterDateMode(mode);
    if (mode === "last_7_days") {
      setStartDate(null);
      setEndDate(null);
      setHighlightDates(LAST_7_DAYS_DATES);
    } else if (mode === "last_14_days") {
      setStartDate(null);
      setEndDate(null);
      setHighlightDates(LAST_14_DAYS_DATES);
    } else if (mode === "last_30_days") {
      setStartDate(null);
      setEndDate(null);
      setHighlightDates(LAST_30_DAYS_DATES);
    } else if (mode === "custom") {
      setStartDate(undefined);
      setEndDate(undefined);
      setHighlightDates(undefined);
    }
  }

  const handleClickSelect = () => {
    console.log("yow");
    setStartDateFinal(startDate);
    setEndDateFinal(endDate);
    history.push("/dashboardv2");
  }
  const buttons = [
    {
      label: "Last 7 Days",
      name: "last_7_days",
    },
    {
      label: "Last 14 Days",
      name: "last_14_days",
    },
    {
      label: "Last Month",
      name: "last_30_days",
    },
    {
      label: "Custom",
      name: "custom"
    }
  ];
  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        {/* <p>{filterDateMode}</p> */}
        <p style={{ marginBottom: "1em" }}>Select Filter Mode</p>
        <div className="list-filterdate-buttons">
          {buttons.map((item, index) => {
            return (
              <button key={index} className={classNames("button button-secondary2 button-mini", {
                active: filterDateMode === item?.name,
              })} onClick={() => handleClickFilterMode(item?.name)} style={{ margin: "0em .5em .5em 0em" }}>{item?.label}</button>
            )
          })}
        </div>
        {/* <button className="button button-secondary2 button-mini" onClick={() => handleClickFilterMode("last_7_days")} style={{ margin: ".5em 0" }}>Last 7 Days</button>
      <button className="button button-secondary2 button-mini" onClick={() => handleClickFilterMode("last_14_days")} style={{ margin: ".5em 0" }}>Last 14 Days</button>
      <button className="button button-secondary2 button-mini" onClick={() => handleClickFilterMode("last_30_days")} style={{ margin: ".5em 0" }}>Last Month</button>
      <button className="button button-secondary2 button-mini" onClick={() => handleClickFilterMode("custom")} style={{ margin: ".5em 0" }}>Custom</button> */}

        <DatePicker
          selected={startDate}
          onChange={handleChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange={filterDateMode === "custom"}
          highlightDates={highlightDates}
          inline
          disabled
        />
        {/* <MaxContainer> */}
        <button className="button button-primary" title="Select" /*navigateTo="/dashboardv2"*/ onClick={handleClickSelect} >Select</button>
        {/* </MaxContainer> */}
      </div>

    </div>
  )
}

const LAST_7_DAYS_DATES = eachDayOfInterval(
  { end: new Date(), start: subDays(new Date(), 7) }
);
const LAST_14_DAYS_DATES = eachDayOfInterval(
  { end: new Date(), start: subDays(new Date(), 14) }
);

const LAST_30_DAYS_DATES = eachDayOfInterval(
  { end: new Date(), start: subDays(new Date(), 30) }
);

export default FilterDatePage;