import React from "react";
import TextWidgetPreview from "./TextWidgetPreview";
const TextWidgetManage = ({ widget,...props }) => {
  console.log('youtube widget is ',widget);
  return (
    <React.Fragment>
      <TextWidgetPreview  widget={widget} />
    </React.Fragment>
  )
}

export default TextWidgetManage;