import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "styled-components";
import { useHistory } from "react-router-dom";
import "../../assets/vendors/typicons/typicons.min.css";
import { db } from "../../utilities/firebaseContext";

const Container = styled.div`
  display:flex;
  -webkit-tap-highlight-color: transparent;
  a {
    padding:.5em 1em;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-decoration:none;  
    color:${props => props.theme.primary};
    /* outline:none; */
    i {
      margin-bottom:.25em;
      &.typcn-bell.active:before {
        font-weight:bold;
      }
    }
  }
  span {
    font-size:.85rem;
  }

  @media only screen and (max-width:414px) {
     a {
      /* font-size:.25rem; */
     }
     span {
       font-size: .65rem;
     }
   }
`;
const NavLink = ({ name, icon, iconActive, link, location, userData, userAuthData }) => {
  
  const theme = useContext(ThemeContext);
  const path = location.pathname;
  const [currentIcon, setCurrentIcon] = useState(icon);
  const history = useHistory();
  const uid = userAuthData?.uid;
  const handleClick = async ({ link, e }) => {
    e.preventDefault();
    if (!userData || !userAuthData?.uid) return;
    
    if (link === "/dashboardv2/preview") {
      const finishAddFirstWidget = userData?.onboarding;
      const finishPreviewWidget = userData?.finishPreviewWidget;
      if(finishAddFirstWidget && !finishPreviewWidget) {
        db.collection("users").doc(uid).update({
          "onboarding.finishPreviewWidget":true,
        });
      }
      history.push(link ?? "/dashboardv2/");
    } else {
      history.push(link ?? "/dashboardv2/");
    }

  }
  useEffect(() => {
    if (!link || !iconActive) return;
    if (link === path) {
      setCurrentIcon(iconActive);
    } else if (path.includes("pick_widget") && link.includes("widget")) { /* for pick widget */
      setCurrentIcon(iconActive);
    } else if (path.includes("config") && link.includes("config")) {
      setCurrentIcon(iconActive);
    } else {
      setCurrentIcon(icon);
    }
  }, [location, iconActive, link, path, icon]);
  return (
    <Container className={`nav-link-${name}`} theme={theme}>

      <a href="/" onClick={(e) => handleClick({ link, e })} style={{
        cursor: "pointer"
      }}>
        <i className={`${currentIcon} nav-icon`}></i>
        <span>{name}</span>
      </a>
    </Container>
  )
}

export default NavLink;