import React, { useContext } from "react";
import { Label, TextInput, SubmitButton } from "../../components";
import { TextArea } from "../../components/forms";
import { useHistory } from "react-router-dom";
import UserAuthContext from "../../context/UserAuthContext";
import { FireStoreContext } from "../../utilities/firebaseContext";
import { Widget } from "../../Entities";

const TextWidgetForm = ({ widget, submitting, setSubmitting, setToastCall, ...props }) => {
  const userAuthData = useContext(UserAuthContext);
  const db = React.useContext(FireStoreContext);
  const history = useHistory();
  const title = widget?.title;
  const text = widget?.text;

  console.log("setToastCall iz ", setToastCall);

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    const title = e.target.title.value;
    const text = e.target.text.value;
    const widgetId = widget?.id;
    const newWidget = {
      type: 'text',
      title, text
    }

    await doSubmitWidget({ newWidget, widgetId });
    setSubmitting(false);
  }

  const doSubmitWidget = async ({ newWidget, widgetId }) => {
    const uid = userAuthData?.uid;
    const WidgetStore = new Widget(uid, db);
    if (props?.crudType === "update") {
      await doUpdateWidget({ WidgetStore, newWidget, widgetId })
    } else {
      await doInsertWidget({ WidgetStore, newWidget });
    }
  }

  const doInsertWidget = async ({ WidgetStore, newWidget }) => {
    try {
      await WidgetStore.addNewWidget(newWidget);
      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
      history.push("/dashboardv2/widgets");
    } catch (e) {

      setToastCall({
        type: "error",
        message: "There was an error while updating new Wiget"
      });
    }
  }

  const doUpdateWidget = async ({ WidgetStore, newWidget, widgetId }) => {
    try {
      await WidgetStore.updateWidgetBulk(widgetId,
        { ...newWidget });
      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
      history.push("/dashboardv2/widgets");
    } catch (e) {

      setToastCall({
        type: "error",
        message: "There was an error while adding new Wiget"
      });
    }
  }
  return (
    <form onSubmit={handleSubmit} method="post">
      <Label htmlFor="title">Title</Label>
      <TextInput type="text" id="title" defaultValue={title || ""} name="title" placeholder="Title" autoComplete="off" required />
      <Label htmlFor="text">Text</Label>
      <TextArea name="text" id="text" maxLength="500" placeholder="Text" defaultValue={text || ""} required />
      <SubmitButton type="submit" value="Submit" submitting={submitting} spinnerColor="white" />
    </form>
  )
}

export default TextWidgetForm;