import React, { useEffect, useState } from "react";
import LinkWidgetEditor from "../../components/LinkWidgetV2/LinkWidgetEditor";
import YoutubeWidgetForm from "../../components/YoutubeWidget/YoutubeWidgetForm";
import { TextWidgetForm, CopyableWidgetForm, SingleLinkWidgetForm, ProfileCoverWidgetForm, SocialMediaWidgetForm } from "../../widgets";
import { Spinner } from "../../components";
import Widget from "../../Entities/Widget";
import { db } from "../../utilities/firebaseContext";
import { useLocation } from "react-router-dom";
import { ScreenContainer } from "../";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

const WidgetEditorForm = ({ userAuthData, toastCall, setToastCall, ...props }) => {

  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const [widget, setWidget] = useState(location?.widget);
  // const widget = location?.widget;
  console.log("location is ", location);

  useEffect(() => {
    if (widget) return;
    start();
    async function start() {
      if (!userAuthData) return;
      const widgetId = location.pathname.split("/")[3];
      console.log("the widget id is ", widgetId);
      const uid = userAuthData?.uid;
      const w = new Widget(uid, db);
      const wObj = await w.fetchWidget(widgetId);
      console.log("wObj is ", wObj);
      setWidget({ ...wObj, id: widgetId });
    }

  }, [widget, userAuthData, location.pathname]);


  /* Toast message listener */
  useEffect(() => {
    if (!toastCall) return;
    if (toastCall.type === "success") {
      toast.success(toastCall.message);
    } else if (toastCall.type === "danger") {
      toast.danger(toastCall.message);
    }
    setToastCall(undefined);
    // eslint-disable-next-line
  }, [toastCall]);

  return (
    <ScreenContainer>
      <ToastContainer autoClose={2000} />
      {widget && widget?.type === 'link' && <LinkWidgetEditor submitting={submitting} setSubmitting={setSubmitting} userAuthData={userAuthData} setToastCall={setToastCall} {...props} />}
      {widget && widget?.type === 'single_link' && <SingleLinkWidgetForm submitting={submitting} setSubmitting={setSubmitting} userAuthData={userAuthData} widget={widget} crudType="update" setToastCall={setToastCall} {...props} />}
      {widget && widget?.type === 'youtube' && <YoutubeWidgetForm submitting={submitting} setSubmitting={setSubmitting} userAuthData={userAuthData} widget={widget} crudType="update" setToastCall={setToastCall} {...props} />}
      {widget && widget?.type === 'text' && <TextWidgetForm submitting={submitting} setSubmitting={setSubmitting} userAuthData={userAuthData} widget={widget} crudType="update" setToastCall={setToastCall} {...props} />}
      {widget && widget?.type === 'copyable' && <CopyableWidgetForm submitting={submitting} setSubmitting={setSubmitting} userAuthData={userAuthData} widget={widget} crudType="update" setToastCall={setToastCall} {...props} />}
      {widget && widget?.type === 'profile_cover' && <ProfileCoverWidgetForm submitting={submitting} setSubmitting={setSubmitting} userAuthData={userAuthData} widget={widget} crudType="update" setToastCall={setToastCall} {...props} />}
      {widget && widget?.type === 'social_media' && <SocialMediaWidgetForm submitting={submitting} setSubmitting={setSubmitting} userAuthData={userAuthData} widget={widget} crudType="update" setToastCall={setToastCall} {...props} />}
      {!widget && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner show={true} />
      </div>}
    </ScreenContainer>
  )
}

export default WidgetEditorForm;