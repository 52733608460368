import React, { useContext, useState, useEffect } from "react";
import { FireStoreContext } from "../../utilities/firebaseContext";
import { Widget } from "../../Entities";
import { useHistory } from "react-router-dom";
import { TextInput, Label } from "../";
import UserAuthContext from "../../context/UserAuthContext";
import { SubmitButton } from "../";
import { toast } from "../../utilities/toastWrapper";
// import { ToastContainer } from "react-toastify";

const YoutubeWidgetForm = ({ widget, setToastCall, setSubmitting, submitting, ...props }) => {
  console.log("widget izz ", widget);
  const db = React.useContext(FireStoreContext);
  const history = useHistory();
  const userAuthData = useContext(UserAuthContext);
  const [url, setUrl] = useState("");
  const [embedUrl, setEmbedUrl] = useState("");
  const [title, setTitle] = useState("");

  const handleOnSubmit = async (e) => {
    e.persist();
    e.preventDefault();
    const uid = userAuthData?.uid;
    if (!uid) return;
    const data = new FormData(e.target);
    const title = data.get('title');
    const url = data.get('url');
    if (!title || !url) return;
    const newWidget = {
      type: 'youtube',
      title,
      videos: [{ title, url }
      ]
    };

    /* validation */
    const params = new URL(url).searchParams;
    const v = params.get("v");
    if (!v || !url.includes("youtube.com/")) {
      toast.error("Youtube URL isn't valid");
      return;
    }

    /* submit */
    setSubmitting(true);
    const WidgetStore = new Widget(uid, db);
    if (props?.crudType === "update") {
      // const updateWidget = {
      //   widgetId: widget.id,
      //   field: "videos",
      //   value: newWidget.videos,
      // }
      await WidgetStore.updateWidgetBulk(widget.id, {
        title,
        videos: newWidget.videos,
      });

      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
    } else { /* create */
      validateTotalYoutubeWidgets()
        .then(async () => {
          await WidgetStore.addNewWidget(newWidget);
          setToastCall({
            type: "success",
            message: "New Widget successfully added"
          });
          history.push("/dashboardv2/widgets");
        })
        .catch(err => {
          alert(err);
        })


    }
    setSubmitting(false);

  }

  const validateTotalYoutubeWidgets = async () => {
    return new Promise(async (resolve, reject) => {
      //only total of 1 youtube widget are allowed

      const uid = userAuthData?.uid;
      const widgets = await new Widget(uid, db).fetchAllWidgets();
      const youtubeWidgets = widgets.filter(widget => widget?.type === "youtube");
      if (youtubeWidgets?.length >= 1) {
        reject("Only 1 Youtube Widget are allowed for the current time being");
      } else {
        resolve()
      }

    });
  }

  const handleInputUrl = e => {
    const url = e.target.value;
    if (!url) return;
    if (!validURL(url)) return;
    const params = new URL(url).searchParams;
    const v = params.get("v");
    if (!v) return;
    const newUrl = `https://www.youtube.com/embed/${v}`;
    setEmbedUrl(newUrl);

  }

  const handleChangeTitle = e => {
    const title = e.target.value;
    setTitle(title);
  }

  /* toggle crud type */
  useEffect(() => {
    // if(!props?.crudType) return;
    // setCrudType("update")

    if (widget && props?.crudType === "update") {

      setTitle(widget.videos[0].title);

      if (!validURL(widget.videos[0].url)) return;
      const newUrl = convertToEmbedUrl(widget.videos[0].url);
      if (!newUrl) return;

      setUrl(widget.videos[0].url);
      setEmbedUrl(newUrl);

    }
  }, [props, widget]);

  /* if crud type is update */
  // useEffect(() => {
  //   if(widget && crudType==="update") {

  //   } else {
  //     alert("should refresh");
  //   }
  // },[widget,crudType]);

  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  function convertToEmbedUrl(url) {
    const params = new URL(url).searchParams;
    const v = params.get("v");
    if (!v) return;
    const newUrl = `https://www.youtube.com/embed/${v}`;
    return newUrl;
  }
  return (
    <form onSubmit={handleOnSubmit} method="post">
      {/* <ToastContainer autoClose={2000} /> */}
      <Label htmlFor="title">Title</Label>
      <TextInput type="text" id="title" name="title" defaultValue={title || ""} onChange={handleChangeTitle} placeholder="Title" autoComplete="off" required />
      <Label htmlFor="url" popOverText="The URL should starts with https://www.youtube.com/watch?v">Youtube URL</Label>
      <TextInput type="url" id="url" onChange={handleInputUrl} name="url" defaultValue={url || ""} placeholder="URL" autoComplete="off" required />
      {embedUrl && (
        <div style={{ marginBottom: "1.5em" }}>
          <iframe width="100%" height="315" src={embedUrl} frameBorder="0" title={title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      )}
      {/* <input type="submit" value="Submit" className="btn-add-new-widget" /> */}
      <SubmitButton type="submit" value="Submit" submitting={submitting} spinnerColor="white" />

    </form>
  )
}


export default YoutubeWidgetForm;