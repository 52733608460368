import React,{useContext} from "react";
import { FireStoreContext } from "../../utilities/firebaseContext";
// import { useHistory } from "react-router-dom";
import UserAuthContext from "../../context/UserAuthContext";
import DefaultLayoutV3 from "../../templates/DefaultLayoutV3";
const UsernamePickerPage = ({ pushFrontForm, frontForms }) => {
  // debugger;
  // const history = useHistory();
  const db = React.useContext(FireStoreContext);
  const userAuthData = useContext(UserAuthContext);

  const handleSubmit = async e => {
    e.preventDefault();
    const username = e.target.username.value;
    if (!username) return;
    await createUserCollection();
    await fetchSetUsername(username);
    pushFrontForm("/pick_username", frontForms);
  }

  /* make user collection to firestore after first time signup */
  const createUserCollection = () => {
    return new Promise(async (resolve, reject) => {
      const uid = userAuthData?.uid;
      if(!uid) return;
      const userRef = await db.collection('users').doc(uid);
      await userRef.set({
        active: true
      }, { merge: true });
      resolve(true);
    });

  }

  const fetchSetUsername = (username) => {
    return new Promise(async (resolve, reject) => {
      const uid = userAuthData?.uid;
      if(!uid) return;
      const userRef = await db.collection('users').doc(uid);
      await userRef.set({
        username
      }, { merge: true });
      resolve(true);
    })

  }

  return (
    <DefaultLayoutV3 title="Pilih Username">
      <div style={{ marginBottom: '1em' }}>
        <p>Let's pick your username</p>
      </div>
      <form onSubmit={handleSubmit}>
        <input type="text" className="default-input-text" name="username" placeholder="Username kamu..." pattern="[a-zA-Z]+" required></input>
        <input type="submit" className="btn-add-new-widget"></input>
      </form>
    </DefaultLayoutV3>
  )
}

export default UsernamePickerPage;