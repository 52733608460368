import React from "react";
import styled from "styled-components";


const SpinnerComponent = ({ show, color }) => {
  
  return (
    <>
      {show && <Spinner className="fas fa-spinner spinner" color={color} />}
    </>
  )
}

const Spinner = styled.i`
  color: ${props => props.color ? props.color : 'grey'};


@keyframes rotating {
  from {

    transform: rotate(0deg);
  }
  to {

    transform: rotate(360deg);
  }
}

animation: rotating 2s linear infinite;

`;

export default SpinnerComponent;