import React from "react";
// import SmartphoneIframe from "../../components/SmartphoneIframe/SmartphoneIframe";
import smartphoneImage from "../../assets/images/smartphone2.png";
// import erinProfile from "../../assets/images/erindesongprofile.png";
import erinface from "../../assets/images/avatars/erindesong.jpg";
import Fade from 'react-reveal/Fade';

const HighlightPoints = () => {
    return (
        <div
            className="maxw-container highlight-points"
            style={{
                display: "flex",
                marginTop: "1.5em",
                // justifyContent:"center",
                // alignItems:"center",

            }}>
            <Fade left duration={800}>
                <div className="highlight-logo-continer" style={{
                    marginRight: "1.5em"
                }}>
                    <a href="https://perona.id/erindesong" target="_new">
                        <img src={smartphoneImage} style={{
                            // width: "340px", 
                            // height: "auto",
                            width: "320px",
                            height: "548px"
                        }} alt="smartphone erin de song profile" />
                    </a>
                </div>
            </Fade>
            {/* <SmartphoneIframe /> */}
            {/* <div className="left" style={{
                
            }}>
                <div style={{
                    width:"350px",
                }}>
                    <span>smartpohne image</span>
                    <img src={erinProfile}  alt="erin de song profile" />
                </div>
            </div> */}
            <Fade right durtion={800}>
                <div className="right" style={{
                    // flex:"1",
                    // background:"pink",
                    width: "850px",
                    // marginTop:"-.5em"
                }}>
                    <h1>Features</h1>
                    <Points />
                    <Fade right durtion={800}>
                        <h1 style={{ marginTop: ".5em" }}>What Our Users are Saying</h1>
                    </Fade>
                    <Fade right durtion={800}>
                        <Testimonial />
                    </Fade>
                </div>
            </Fade>
        </div >
    )
}

const Testimonial = () => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1.5em"
        }} className="testo">
            <div className="left" style={{
                paddingLeft: "1em",
                marginRight: "1em"
            }}>
                <img src={erinface} style={{
                    width: "125px",
                    height: "125px"
                }} alt="erin face" className="testimony-pp" />
            </div>
            <div className="right" style={{
                display: "flex",
                flexDirection: "column"
            }}>
                <p style={{
                    fontSize: "1.25rem",
                    fontStyle: "italic"
                }}>"I have been using perona for my personal brand and I'm extremely happy with the decision. and I am so impressed with the ease of use and the professional look it adds to my online presence"</p>
                <em className="subtitle-name">Erin de Song</em>
                <em className="subtitle-title">Product Designer , Illustrator , Streamer</em>
            </div>
        </div>
    );
}

const Points = () => {
    const items = [
        {
            title: "Customizeable",
            content: "Personalize your profile with our widgets from Text, Links, Youtube and many more",
            icon: "fa-th-large",
        },
        {
            title: "One Link",
            content: "Get a single link to promote your links to your audience",
            icon: "fa-link"
        },
        {
            title: "Insights",
            content: "Get insighting insights from your audience traffic",
            icon: "fa-chart-line"
        },
        {
            title: "Free",
            content: "Make your own profile with no cost",
            icon: "fa-dollar-sign"
        },
    ];
    return (
        <div style={{
            display: "flex",
            flexWrap: "wrap",
        }}>
            {items.map((item,index) => {
                return (
                    <Fade right key={index} durtion={800}>
                        <div
                            className="highlight-point"
                            style={{
                                flex: "1",
                                padding: "1em 1em",
                                // background:"blue",
                                minWidth: "300px",
                                width: "100%",
                                // border:"2px solid #9A52E4",
                                borderRadius: "12px",
                                marginRight: "1em",
                                marginBottom: "1em",
                                display: "flex",
                            }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "1em",
                            }}>
                                <i className={`fas ${item.icon} icon`} style={{
                                    fontSize: "26px",
                                    color: "#5F31E0",
                                }} />
                                {/* <span>icon</span> */}
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <span style={{
                                    color: "#5F31E0",
                                    fontWeight: "bold",
                                }} className="title">{item.title}</span>
                                <p className="content">{item.content}</p>
                            </div>

                        </div>
                    </Fade>
                )
            })}
        </div>
    )
}
export default HighlightPoints;
