import React from "react";
import "./Overlay.scss";
const Overlay = ({ children, showOverlay, toggleShowOverlay, ...props }) => {
  return (
    <div id="overlay" style={{ display: showOverlay ? 'block' : 'none' }} onClick={toggleShowOverlay}>
      {children}
    </div>
  )
}


export default Overlay;