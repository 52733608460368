import React from "react";
import styled from "styled-components";

const ScreenContainer = ({ children }) => {
  return (
    <Container className="screen-container">{children}</Container>
  )
}
const Container = styled.div`
  padding:5em 1em 4em 1em; 
`;

export default ScreenContainer;