import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const Container = styled.div`
  display:flex;
  overflow-x:scroll;
  padding-bottom:1em;
  .slide-box {
    max-width:260px;
    margin-right:1.5em;
    a {
      text-decoration:none;
      color:inherit;
    }
  }
`;

const Articles = ({ data }) => {
  console.log("datarticle", data);
  return (
    <Container>
      {data?.map?.((post, index) => {
        const { post_thumbnail, title, ID } = post;
        console.log("post iz ", post);
        const thumbnailUrl = post_thumbnail?.URL;
        console.log("post_thumbnail hey ", post_thumbnail);
        return (
          <div className="slide-box" key={index}>
            <Link to={`/dashboardv2/articles/${ID}`}>
              <Picture
                src={thumbnailUrl}
              />
            </Link>
            <Link to={`/dashboardv2/articles/${ID}`}><span>{title}</span></Link>
          </div>
        )
      })}

    </Container>

  );
}

const Picture = styled.div`
  width:17em;
  height:7em;
  border-radius:10px;
  background-image: url('${prop => prop.src}');
  margin-bottom:.5em;
  background-size:cover;
`;

export default Articles