import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import { Label, SubmitButton, TextInput } from "../../components";
import { TextArea } from "../../components/forms";
import AvatarUpload from "../../components/forms/AvatarUpload";
import MaxContainer from "../../components/MaxContainer";
import { validateImage } from "../../widgets/ProfileCover/ProfileCoverWidgetForm";
import { uploadImageProfile } from "../ConfigPage/ConfigPageProfile";
import { User } from "../../Entities";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const SignupWizardPage = ({
    userAuthData,
    db,
    firebase,
}) => {
    const [loading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();

    const handleClickSkip = async e => {
        e.preventDefault();
        if (submitting) return;
        setSubmitting(true);
        const updateObj = {
            finishSignupWizard: true
        }
        const uid = userAuthData?.uid;
        const u = new User(uid, db);
        await u.updateUserData(updateObj);
        setSubmitting(false);
        // history.push("/dashboardv2");
        window.location.href = "/dashboardv2/widgets";
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        e.persist();
        if (submitting) return;
        setSubmitting(true);

        // const uid = userAuthData?.uid;
        const displayName = e.target.displayName?.value;
        const subTitle = e.target.subTitle?.value;
        const bio = e.target.bio?.value;






        // console.warn("updateObj izz ",updateObj);
        // return;
        // console.log("uid iz ", uid);
        // return;
        // return;
        const user = firebase.auth().currentUser;
        // firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                let profileImagePath;
                const uid = user.uid;
                const profileImageEl = e.target?.avatar;
                const profileImage = profileImageEl.files[0];
                const profileImageExt = profileImage?.type?.split('/')[1];
                let updateObj = {
                    finishSignupWizard: true
                }
                if (profileImage) {
                    if (!uid) {
                        alert("error encountered"); return;
                    }
                    profileImagePath = `images/users/${uid}/profile.${profileImageExt}`;
                    const validImage = validateImage(profileImage);

                    if (displayName) {
                        updateObj.displayName = displayName;
                    }
                    if (subTitle) {
                        updateObj.subTitle = subTitle;
                    }
                    if (bio) {
                        updateObj.bio = bio;
                    }
                    if (profileImage) {
                        updateObj.profileImagePath = profileImagePath;
                    }


                    if (!validImage) {
                        return;
                    } else {
                        await uploadImageProfile({ profileImage, profileImagePath })
                    }
                }

                let updateObjTotal = Object.keys(updateObj)?.length
                if (updateObjTotal > 0) {
                    const u = new User(uid, db);
                    //   resolve(uid);
                    await u.updateUserData(updateObj);
                    setSubmitting(false);
                }

                toast.success(<SuccessIcon />);
                history.push("/pick_theme");
            } else {
                // alert("failed");
                // reject("user iz not logged in");
            }
        // });



        // history.push("/pick_theme");
        // window.location.href = "/dashboardv2/widgets/";
        // window.location.href = "/pick_theme";
        // history.push("/dashboardv2",{
        //     finishSignupWizard:true
        // });

    }

    useEffect(() => {
        // const currentUser = firebase.auth().currentUser;
        // console.warn("currentUser ",currentUser);
    },[]);
    return (<div style={{
        paddingLeft: "1em",
        paddingRight: "1em"
    }}>
        <ToastContainer autoClose={3000} />
        <MaxContainer>
            {/* <button onClick={() => { console.warn("yo"); history.push("/dashboardv2") }}>go back</button> */}
            <form onSubmit={handleSubmit}>
                <div className="text-center">
                    <h2 className="mt-3">Last Step</h2>
                    <p className="mt-1">You have successfully signed up an account. Now for the last step, please fill in the form below. Or you can skip this step</p>

                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <AvatarUpload name="avatar" style={{
                        marginTop: "1em"
                    }} />
                </div>
                <TextInput name="displayName" label="Display Name (optional)" placeholder="Display Name..."
                    popOverText="An alternate name to display instead your username"
                // defaultValue={userData?.displayName || ""} 
                // onChange={handleOnChangeDisplayName}
                />
                <Label popOverText="A text below your username">Title</Label>
                <TextInput type="text" name="subTitle"
                    // defaultValue={subTitle || ""}
                    placeholder="Product Designer, Web Developer, Manager" autoComplete="off"></TextInput>
                <Label>Bio</Label>
                <TextArea name="bio"
                    // defaultValue={bio}
                    placeholder="Bio..."
                />
                <div style={{ paddingBottom: "1em" }}>
                    {!loading && <SubmitButton value="Save" submitting={submitting} spinnerColor="white" />}
                    <button onClick={handleClickSkip} className="button button-secondary2 button-mini" style={{ display: "block", textAlign: "center", marginTop: "0", width: "100%" }}>Skip</button>
                </div>
            </form>
        </MaxContainer>
    </div>)
}

const SuccessIcon = () => {
    return (
        <p><i className="fas fa-check"></i> Changes updated successfully</p>
    )
}

export default SignupWizardPage;