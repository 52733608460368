import React, { useContext, useState } from "react";
import firebase from "../../utilities/firebaseContext";
import { Label, TextInput, SubmitButton } from "../../components";
import { TextArea } from "../../components/forms";
import UserAuthContext from "../../context/UserAuthContext";
import { Widget } from "../../Entities";
import { FireStoreContext } from "../../utilities/firebaseContext";
import { useHistory } from "react-router-dom";
// import styled from "styled-components";

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const ProfileCoverWidgetForm = ({ setToastCall, widget, crudType, ...props }) => {

  const [submitting, setSubmitting] = useState();
  console.log("widget iz ", widget);


  const userAuthData = useContext(UserAuthContext);
  const db = React.useContext(FireStoreContext);
  const history = useHistory();
  const uid = userAuthData?.uid;


  const subTitle = widget?.subTitle;
  const imageProfilePath = widget?.profileImagePath;
  const bio = widget?.bio;

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);

    const profileImageEl = e.target.profileImage;
    const profileImage = profileImageEl.files[0];

    const subTitle = e.target.subTitle.value;
    const bio = e.target.bio.value;

    const widgetId = widget?.id;

    const newWidget = {
      type: 'profile_cover',
      text: 'lorem ipsum dolor sit amet',
      // profileImagePath: `images/users/${uid}/widgets/profile_cover/profile.jpg`,
      subTitle,
      bio,
    }

    if (profileImage) {
      newWidget.profileImagePath = `images/users/${uid}/widgets/profile_cover/profile.jpg`;
    }

    await doSubmitWidget({ profileImage, newWidget, widgetId });
    setSubmitting(false);
  }

  const doSubmitWidget = async ({ newWidget, profileImage, widgetId }) => {
    let valid = true
    if (profileImage) {
      valid = validateImage(profileImage);
    }
    if (!valid) {
      return;
    } else if (valid && profileImage) {
      uploadImageProfile({ profileImage });
    }
    const uid = userAuthData?.uid;
    const WidgetStore = new Widget(uid, db);
    if (crudType === "update") {
      await doUpdateWidget({ WidgetStore, newWidget, widgetId })
    } else { /* create */
      await doInsertWidget({ WidgetStore, newWidget });
    }


  }

  const doInsertWidget = async ({ WidgetStore, newWidget }) => {
    validateTotalYoutubeWidgets()
      .then(async () => {
        try {
          await WidgetStore.addNewWidget(newWidget);
          setToastCall({
            type: "success",
            message: "Widget successfully updated"
          });
          history.push("/dashboardv2/widgets");
        } catch (e) {

          setToastCall({
            type: "error",
            message: "There was an error while updating new Wiget"
          });
        }
      })
      .catch(err => alert(err))

  }

  const doUpdateWidget = async ({ WidgetStore, newWidget, widgetId }) => {
    try {
      await WidgetStore.updateWidgetBulk(widgetId,
        { ...newWidget });
      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
      history.push("/dashboardv2/widgets");
    } catch (e) {

      setToastCall({
        type: "error",
        message: "There was an error while adding new Wiget"
      });
    }
  }

  const uploadImageProfile = ({ profileImage }) => {
    // Create a root reference
    var storageRef = firebase.storage().ref();

    // Create a reference to 'images/mountains.jpg'
    var mountainImagesRef = storageRef.child(`images/users/${uid}/widgets/profile_cover/profile.jpg`);

    // While the file names are the same, the references point to different files
    // mountainsRef.name === mountainImagesRef.name;           // true
    // mountainsRef.fullPath === mountainImagesRef.fullPath;   // false
    mountainImagesRef.put(profileImage).then((snapshot) => {
      console.log('Uploaded a blob or file!');
    });
  }



  const validateTotalYoutubeWidgets = async () => {
    return new Promise(async (resolve, reject) => {
      //only total of 1 youtube widget are allowed

      const uid = userAuthData?.uid;
      const widgets = await new Widget(uid, db).fetchAllWidgets();
      const profileCoverWidgets = widgets.filter(widget => widget?.type === "profile_cover");
      if (profileCoverWidgets?.length >= 1) {
        reject("Only 1 Profile Cover Widget is allowed");
      } else {
        resolve()
      }

    });
  }
  return (
    <form onSubmit={handleSubmit}>
      <Label>Profile Picture &nbsp;&nbsp;
        {(crudType === "update" && imageProfilePath) &&
          <a href={`${STORAGE_URL}/${imageProfilePath?.replace(/\//g, '%2F')}?alt=media`} target="_new" style={{ color: '#9E66D8' }}>
            <i className="fas fa-external-link-alt"></i></a>
        }
      </Label>
      <TextInput type="file" name="profileImage" accept=".jpg,.png" />
      <div>

        {/* <InputFileContainer>

          <div className="image-container">
            <img src={`${STORAGE_URL}/${imageProfilePath.replace(/\//g, '%2F')}?alt=media`} alt="profile" />
          </div>
          <div className="input-container">
            <TextInput type="file" name="profileImage" />

          </div>

        </InputFileContainer> */}

      </div>
      <Label popOverText="A text below your username">Title</Label>
      <TextInput type="text" name="subTitle" defaultValue={subTitle || ""} placeholder="Product Designer, Web Developer, Manager" autoComplete="off"></TextInput>
      <Label>Bio</Label>
      <TextArea name="bio" defaultValue={bio}></TextArea>
      <SubmitButton type="submit" value="Submit" submitting={submitting} spinnerColor="white" />
    </form>
  )
}

export const validateImage = file => {
  console.log("file iz", file);
  const allowedFileTypes = ["image/jpeg", "image/png"];
  const currentFileType = file?.type;
  const currentfileSize = file?.size;
  if (!allowedFileTypes.includes(currentFileType)) {
    alert("Image type not valid");
    return false;
  } else if (currentfileSize > 3500000) {
    alert("Maximum image size is 3MB");
    return false;
  }
  return true;


}

// const InputFileContainer = styled.div`
//   display:flex;
//   flex-direction:row;
//   flex-wrap:wrap;
//   .image-container {
//     margin-right:1.5em;
//     img {
//       width:100px;
//       height:100px;
//       background-size: 100%;
//       border-radius: 50%;
//     }
//   }
//   .input-container {
//     flex:1;
//   }
// `;

export default ProfileCoverWidgetForm;