import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { fetchRecordWidgetClicks } from "../../actions/analytics";
import { EWEN_THEME, H4X0R_THEME, LAVENDER_THEME, SKYBLUE_THEME } from "../../themes";
import MaxContainer from "../../components/MaxContainer";

const Link = styled.li`
  width:100%;
  margin-bottom: 1.5em;
  list-style:none;
  box-sizing:border-box;
  @media (max-width:415px) {
    padding-left:1em;
    padding-right:1em;
  }
  a {
    display: block;
    width:100%;
    transition:all .3s ease-in-out;
    background: ${props => props.theme.primary};
    box-shadow: ${props => {
    // console.log("props.theme.boxShadow :", props.theme.boxShadow);
    return props.theme.boxShadow ?? `none`
  }};
    color:${props => props.theme.secondaryText};
    text-decoration:white;
    text-align:center;
    padding-top:1em;
    padding-bottom:1em;
    border-radius:5px;
    font-weight:bold;
  }
`;
const LinkWidgetPreview = ({ link, userData, linkIndex, themeContext }) => {
  console.warn("themeContext izz ",themeContext);
  const { url, title, id } = link;
  // const userData = React.useContext(UserDataContext)?.userData;
  const userTheme = userData?.theme;
  const [theme, setTheme] = useState(LAVENDER_THEME);
  const location = useLocation();


  const handleClick = () => {
    const { pathname } = location;
    if (pathname.includes("/dashboardv2")) return;
    const username = location.pathname.replace("/", "");
    fetchRecordWidgetClicks({
      username,
      widget_id: id,
      url,
      title,
    });
  }

  useEffect(() => {
    // console.warn("usus ",userTheme);
    // console.warn("udud", userData);
    if (/*!userTheme ||*/ !userData) return;
    let theme;
    let currentTheme = userTheme;
    if (themeContext) {
      // alert("hey");
      currentTheme = themeContext;
    }
    console.warn("currentTheme izz ",currentTheme);
    if (currentTheme === "lavender") {
      theme = LAVENDER_THEME;
    } else if (currentTheme === "skyblue") {
      theme = SKYBLUE_THEME;
    } else if(currentTheme === "h4x0r") {
      theme = H4X0R_THEME;
    } else if(currentTheme === "ewen") {
      theme = EWEN_THEME;
    }else {
      // theme = userData?.customThemeColors;
      theme = themeContext;
    }
    console.log("MY THEME IZZ ", theme);
    setTheme(theme);
  }, [userTheme, userData,themeContext]);

  return (
    <>
      <Link theme={theme} location={location} >
        <MaxContainer>
          <a href={url} target="_blank" rel="noopener noreferrer" onClick={handleClick}>{title}</a>
        </MaxContainer>
      </Link>
    </>
  )
}

export default LinkWidgetPreview;