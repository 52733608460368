
const generateSocialUrl = ({ social, socialObj, username }) => {
  let link = "";
  const { baseUrl, type } = socialObj || {};
  // console.log("socialObj izo ", socialObj);
  if (type === "url") {
    link = username;
  } else {
    link = `${baseUrl}${username}`;
  }

  return link;

}

export default generateSocialUrl;