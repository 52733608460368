import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  /* padding-top:1em; */
  @media (min-width: 420px) {
    
    & {
      margin: auto;
      width: ${props => props?.pathname?.includes("/preview") ? "100%" : "100%"};
      max-width:768px;
    }
  }
`;

// eslint-disable-next-line
const MaxContainer = ({
  style
}) => {
  return (
    <StyledContainer className="max-container"
    // style={style} 
    />
  )
}

export default StyledContainer;