import React, { useContext } from "react";
import { FireStoreContext } from "../../utilities/firebaseContext";
import { Widget } from "../../Entities";
import { useHistory } from "react-router-dom";
import { TextInput, SubmitButton, Label } from "../../components";
import UserAuthContext from "../../context/UserAuthContext";
import Joyride from 'react-joyride';

const SingleLinkWidgetform = ({ crud, toast, widget, submitting, setSubmitting, setToastCall, setShowToast, ...props }) => {

  const db = React.useContext(FireStoreContext);
  const history = useHistory();
  const userAuthData = useContext(UserAuthContext);

  const handleOnSubmit = async (e) => {
    e.persist();
    e.preventDefault();
    setSubmitting(true);
    const uid = userAuthData?.uid;
    if (!uid) return;
    const data = new FormData(e.target);
    const title = data.get('title');
    const url = data.get('url');
    const widgetId = widget?.id;
    if (!title || !url) return;
    const newWidget = {
      type: 'single_link',
      title,
      url

    };
    await doSubmitWidget({ newWidget, widgetId });
    setSubmitting(false);
  }

  const doSubmitWidget = async ({ newWidget, widgetId }) => {
    const uid = userAuthData?.uid;
    const WidgetStore = new Widget(uid, db);
    if (props?.crudType === "update") {
      await doUpdateWidget({ WidgetStore, newWidget, widgetId })
    } else {
      await doInsertWidget({ WidgetStore, newWidget });
    }
  }

  const doInsertWidget = async ({ WidgetStore, newWidget }) => {
    try {
      await WidgetStore.addNewWidget(newWidget);
      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
      history.push("/dashboardv2/widgets");
    } catch (e) {

      setToastCall({
        type: "error",
        message: "There was an error while updating new Wiget"
      });
    }
  }

  const doUpdateWidget = async ({ WidgetStore, newWidget, widgetId }) => {
    try {
      await WidgetStore.updateWidgetBulk(widgetId,
        { ...newWidget });
      setToastCall({
        type: "success",
        message: "Widget successfully updated"
      });
      history.push("/dashboardv2/widgets");
    } catch (e) {

      setToastCall({
        type: "error",
        message: "There was an error while adding new Wiget"
      });
    }
  }

  const steps = [
    {
      target: '.widget-title',
      content: "Input a title that will be displayed to your link",
    },
    {
      target: '.widget-url',
      content: 'Input the url link'
    },
    {
      target: '.btn-add-new-widget',
      content: 'Click Submit to confirm adding this widget to your profile page',
    }
  ]
  return (
    <>
      {<Joyride
        steps={steps}
      />}
      <form onSubmit={handleOnSubmit} method="post">
        <Label htmlFor="title" popOverText="Label yang akan ditampilkan">Label</Label>
        <TextInput type="text" name="title" defaultValue={widget?.title || ""} id="title" className="default-input-text widget-title" placeholder="Title" autoComplete="off" required />
        <Label htmlFor="url" popOverText="URL yang digunakkan untuk mengarahkan User ke website tertentu">URL</Label>
        <TextInput type="url" name="url" defaultValue={widget?.url || ""} className="default-input-text widget-url" placeholder="URL" autoComplete="off" required />
        <SubmitButton type="submit" value="Submit" submitting={submitting} spinnerColor="white" />
      </form>
    </>
  )
}

// const ENV = process.env.REACT_APP_ENVIRONMENT;

export default SingleLinkWidgetform;