import React from "react";
import Select from 'react-select';
import Label from "../../Label";

const SelectPicker = ({ name, required, options, label, onChange, defaultValue }) => {
  // console.log("options arex ", options);
  return (
    <div style={{
      marginBottom: "2em"
    }}>
      <Label>{label}</Label>
      <Select
        name={name}
        options={options}
        onChange={onChange}
        required={required}
        defaultValue={defaultValue}
        // value="tiktok"
        // inputValue="tiktok"

        styles={{
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isFocused ? "var(--primary)" : data.color,
              color: isFocused ? "#fff" : "#000"
            };
          },
          control: (styles, { data }) => {
            console.log("datataa", data);
            return {
              ...styles,
              // border: 0,
              borderColor: "hsl(0, 0%, 80%) !important",
              boxShadow: 'none'
            }
          }
        }}
      />
    </div>
  )
}

export default SelectPicker;