import React, { useState } from "react";
import { ToastContainer, } from 'react-toastify';
import { SubmitButton, Label } from "../../components";
import { ColorPicker } from "../../components/forms";
import HeaderConfig from "./components/HeaderConfig";
import { useTranslation } from "react-i18next";
import { toast } from "../../utilities/toastWrapper";
import { Select } from "../../components/forms";
import { User } from "../../Entities";
import UserDataContext from "../../context/UserDataContext";
import { LAVENDER_THEME } from "../../themes";

const ConfigPageAppearance = ({ userAuthData, db, ...props }) => {
  // const [userData, setUserData] = useState({});
  // const [loading, setLoading] = useState(false);
  const { userData, setUserData } = React.useContext(UserDataContext);
  const [currentTheme, setCurrentTheme] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [customThemeColors, setCustomThemeColors] = useState({ ...LAVENDER_THEME,/* boxShadow: 'none'*/ });
  const { t } = useTranslation();

  const handleChangeCustomThemeColor = (name, value) => {
    setCustomThemeColors({
      ...customThemeColors,
      [name]: value,
    });
    // const name = e.target.name;
    // const color = e.target.value;
    // console.log(name);
  }

  const handleOnSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    const theme = e.target.theme.value;

    await doSubmit({ theme, userData, customThemeColors });
    // setLoading(true);
    setSubmitting(false);
    toast.success("Settings successfully updated");
  }

  const doSubmit = async ({ theme, userData, customThemeColors }) => {
    const uid = userAuthData?.uid;
    const u = new User(uid, db);
    let updateObj = {};
    updateObj.theme = theme;
    if (theme === "custom") {
      customThemeColors.boxShadow = `0px 5px 10px ${customThemeColors.primary}4C`;
      updateObj.customThemeColors = customThemeColors;
    } else {
      updateObj.customThemeColors = {};
    }
    await u.updateUserData({ ...updateObj });
    setSubmitted(true);
    setUserData({ ...userData, theme,customThemeColors });

  }

  const handleChangeTheme = e => {
    const theme = e.target.value;
    setCurrentTheme(theme);
  }

  React.useEffect(() => {
    if (userData) {
      setCurrentTheme(userData?.theme);
      if (userData?.theme === "custom" && userData?.customThemeColors && !submitted) {
        setCustomThemeColors(userData?.customThemeColors);
      }
    }
  }, [userData, submitted]);

  // React.useEffect(() => {
  //   if (userData?.theme === "custom" && userData?.customThemeColors) {
  //     setCustomThemeColors(userData?.customThemeColors);
  //   }
  // }, [userData]);

  return (
    <div>
      <ToastContainer autoClose={3000} />
      <HeaderConfig>
        <h2>{t("APPEARANCE")}</h2>
        {/* <Spinner show={loading || submitting} /> */}
      </HeaderConfig>
      <form onSubmit={handleOnSubmit}>
        <Label htmlFor="language">{t("THEME")}</Label>
        <Select name="theme" id="language" /*defaultValue={theme}*/ value={currentTheme} className="default-select" onChange={handleChangeTheme}>
          <option value="lavender">Lavender</option>
          <option value="skyblue">Skyblue</option>
          <option value="h4x0r">h4x0r</option>
          <option value="ewen">ewen</option>
          <option value="custom">Custom</option>
        </Select>
        {currentTheme === "custom" && (
          <>
            <h3 style={{ marginBottom: '1em' }}>Custom Colors</h3>
            <ColorPicker label="Primary Color" name="primary" value={customThemeColors.primary} onChange={handleChangeCustomThemeColor} />
            <ColorPicker label="Background Color" name="background" value={customThemeColors.background} onChange={handleChangeCustomThemeColor} />
            <ColorPicker label="Text Color (Links)" name="secondaryText" value={customThemeColors.secondaryText} onChange={handleChangeCustomThemeColor} />
          </>
        )}
        {<SubmitButton value="Update" submitting={submitting} spinnerColor="white" />}

      </form>
    </div>
  )
}


export default ConfigPageAppearance;