import React from "react";
import styled from "styled-components";

const Avatar = ({onClick,style,src}) => {
    return(
        <AvatarComponent className="avatar" onClick={onClick} style={style}>
            <img src={src ?? require("../assets/images/avatar_placeholder.png")} alt="avatar" />
            {onClick && <span className="edit-control"><i className="fas fa-pen"></i></span>}
            
        </AvatarComponent>
    )
}

const AvatarComponent = styled.div`
    width:100px;
    height:100px;
    border-radius:50%;
    overflow:hidden;
    display:flex;
    justify-content:center;
    position:relative;
    
    img {
        object-fit: cover;
    }
    

    .edit-control {
        display:block;
        align-self:center;
        position:absolute;
        color:#fff;
        
    }
`;



export default Avatar;