import React, { useEffect, useState } from "react";
import firebase from "../../../utilities/firebaseContext";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { toast } from "../../../utilities/toastWrapper";
import { Spinner } from "../../../components";
import Avatar from "../../../components/Avatar";

const UserBox = ({ userData }) => {

  const [publicProfileUrl, setPublicProfileUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [profileImageUrl, setProfileImageUrl] = useState(require("../../../assets/images/avatar_placeholder.png"));

  const storage = firebase.storage();

  const handleClickCopy = () => {
    if (!publicProfileUrl) return;
    navigator.clipboard.writeText(publicProfileUrl).then(function () {
      toast.dark("Link copied successfully");
    }, function (err) {
      toast.dark("Failed to copy");
      console.error(err);
    });

  }

  useEffect(() => {
    console.log("userDatakyunnn", userData);
    // const profileImagePath = widget?.profileImagePath
    const profileImagePath = userData?.profileImagePath;

    if (!profileImagePath) return;
    const pathReference = storage.ref(profileImagePath);
    pathReference.getDownloadURL().then(url => {
      setProfileImageUrl(url);
    })
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (!userData?.username) return;
    // setPublicProfileUrl(`${document.location.origin}/${userData.username}`);
    setPublicProfileUrl(`https://perona.id/${userData.username}`);
    setLoading(false);
  }, [userData]);

  return (
    <UserBoxStyled>
      <ToastContainer
        autoClose={2000}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        hideProgressBar
        position="bottom-center"
      />
      <Spinner show={loading} />
      {!loading && (
        <>
          <div style={{ marginBottom: "1em" }}>
            <Avatar src={profileImageUrl} />
          </div>

          {/* <img src={profileImageUrl} className="avatar-dummy" /> */}
          <h2 className="name-full">{userData?.displayName ? userData?.displayName : userData?.username}</h2>
          <div className="url-link-box">
            <input type="text" defaultValue={publicProfileUrl} disabled />
            <button onClick={handleClickCopy}>Copy</button>
          </div>
        </>
      )}
    </UserBoxStyled>
  );
}

const UserBoxStyled = styled.div`
  /* height:4em; */
  /* border:1px solid purple; */
  border-radius:10px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-bottom:1em;
  .avatar-dummy {
    /* background-image:url('${require("../../../assets/images/avatar_placeholder.png")}'); */
    background-size:cover;
    width:5em;
    height:5em;
    border-radius:50%;
    margin-bottom:.5em;
  }
  h2.name-full {
    font-size:1.25rem;
    margin-bottom:.5em;
  }

  .url-link-box {
    display:flex;
    gap:5px;
    input {
      padding: 5px 7px;
      border-top-left-radius:10px;
      border-bottom-left-radius:10px;
      border:1px solid #ebe1f5;
      font-size:.85rem;
      min-width: 200px;
    }
    button {
      font-size:.85rem;
      cursor:pointer;
      background:#5F31E0;
      padding: 5px 10px; 
      border:0;
      outline:none;
      /* border:1px solid #ebe1f5;  */
      /* border-bottom:3px solid #ebe1f5;  */
      border-top-right-radius:10px;
      border-bottom-right-radius:10px;
      /* color: #404040; */
      color:white;
    }
  }
`;

export default UserBox;