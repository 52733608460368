import React, { useState } from "react";
import LinkWidgetContent from './LinkWidgetContent';
import ActionSheet from "../ActionSheet";
import "./LinkWidget.scss";
import { Link, useRouteMatch } from "react-router-dom";
import { Widget } from "../../Entities";
import { db } from "../../utilities/firebaseContext";
import { array_move } from "../../utilities/coreutils";

const LinkWidget = ({ widget, itemIndex, mode, handleOnClickTextIcon, setWidgetGroupTarget, setWidgetContentTarget, setSelectedWidget, userAuthData }) => {
  // debugger;
  const match = useRouteMatch();
  const widget_id = match.params.id;
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [contentIndex, setContentIndex] = useState(0);
  // const showActionSheet = ({ e, widgetInfo }) => {
  //   const widgetGroupTarget = { id: parseInt(e.target.dataset.widgetGroupId) };
  //   const widgetContentTarget = { id: parseInt(e.target.dataset.widgetContentId) }

  //   setShowOverlay(true);
  //   setShowActionSheet(true);
  //   setWidgetGroupTarget(widgetGroupTarget);
  //   setWidgetContentTarget(widgetContentTarget);
  //   setSelectedWidget(widgetInfo);
  // }
  const handleClickLinkWidgetContextIcon = ({ e, url, title, contentIndex }) => {
    setShowActionSheet(true);
    setContentIndex(contentIndex);
    // const widgetType = 'link';
    // const widgetInfo = {
    //   widgetType,
    //   itemIndex,
    //   contentIndex,
    //   url,
    //   title
    // }
    // if (!showActionSheet) return;
    // showActionSheet({ e, widgetInfo });
  }
  const toggleShowActionSheet = () => {
    setShowActionSheet(!showActionSheet);
  }

  const handleClickMove = async (direction) => {
    if (!userAuthData?.uid) return;
    const uid = userAuthData?.uid;
    const links = widget.links;
    const move = (direction === 'up') ? contentIndex - 1 : contentIndex + 1;
    const movedArray = array_move(links, contentIndex, move);
    const w = new Widget(uid, db);
    w.updateWidget({ widgetId: widget_id, field: 'links', value: movedArray });
  }

  const handleOnClickDelete = async (widgetId, contentIndex) => {
    if (!userAuthData?.uid) return;
    const widget_id = match.params.id;
    const uid = userAuthData?.uid;
    const w = new Widget(uid, db);
    const widgetCopy = widget;
    widgetCopy.links.splice(contentIndex, 1);
    const updateWidget = await w.updateWidget({
      widgetId: widget_id,
      field: 'links',
      value: widgetCopy.links
    });
    if (updateWidget)
      alert("Link successfully deleted!");
  }

  return (
    <React.Fragment>
      <ActionSheet showActionSheet={showActionSheet} toggleShowActionSheet={toggleShowActionSheet}>
        <ul>
          {(contentIndex !== 0) && <li><button onClick={() => handleClickMove('up')}><i className="fas fa-chevron-up"></i><span>Move Up</span></button></li>}
          {(contentIndex !== widget.links.length - 1) && <li><button onClick={() => handleClickMove('down')}><i className="fas fa-chevron-down"></i><span>Move Down</span></button></li>}

          <li><Link style={{ textDecoration: 'none' }} to={`/dashboardv2/widget/${widget_id}/edit/${contentIndex}`}><button><i className="fas fa-edit"></i><span>Edit</span></button></Link></li>
          {/* <li><button onClick={handleOnClickEdit}><i className="fas fa-edit"></i><span>Edit</span></button></li> */}
          <li><button onClick={e => handleOnClickDelete(widget_id, contentIndex)}><i className="fas fa-trash"></i><span>Delete</span></button></li>
          <li><button /*onClick={toggleOverlayActionSheet} */><i></i><span>Cancel</span></button></li>
        </ul>
      </ActionSheet>
      <ul key={itemIndex} className={`links-widget widget-group mode-${mode}`} data-widget-id={itemIndex}>
        {widget.links.map((widgetContent, contentIndex) => {
          return (
            <LinkWidgetContent key={contentIndex} mode={mode} contentIndex={contentIndex} widgetContent={widgetContent} itemIndex={itemIndex} handleOnClickTextIcon={handleOnClickTextIcon} handleClickLinkWidgetContextIcon={handleClickLinkWidgetContextIcon} />
          )

        })
        }
      </ul>
    </React.Fragment>
  )

}

export default LinkWidget;