import React from "react";
import { Link, } from 'react-router-dom';

const SuperCallAction = () => {
  return (
    <div className="supercallaction-container">
      <div className="maxw-container">
        <h2>Engage your Audience Now for Free!</h2>
        <Link to="sign_up">
          <button
            className="button button-secondary no-shadow"
            style={{
              alignSelf: "center",
              width: "auto",
              background: "#fff !important",
              fontWeight: "600",
              marginTop: "1.5em",
            }}
          >Get Started</button>
        </Link>
      </div>
    </div>
  )
}

export default SuperCallAction;