import React from "react";
import PropTypes from "prop-types";
import { lighten } from 'polished';
import styled from "styled-components";

const WidgetOption = ({ widget, onSelectWidget, selected }) => {
  return (
    <WidgetOptionStyled onClick={() => onSelectWidget(widget)} selected={selected} widget={widget} className={`widget-${widget?.name}`}>
      <i className={`${widget.icon} icon`}></i>
      <hr></hr>
      <span className="displayName">{widget.displayName}</span>
      <i className="fas fa-check-circle checkbox"></i>
    </WidgetOptionStyled>
  )
}

const WidgetOptionStyled = styled.div`
  background:white;
  border-radius:10px;
  /* border:1px solid grey; */
  box-shadow: 0px 2px 4px #e0e0e0; 
  cursor:${prop => prop.widget.disabled ? 'default' : 'pointer'};
  padding-left:15px;
  padding-right:15px;
  padding-top:15px;
  padding-bottom:5px;
  display:flex;
  flex-direction:column;
  margin-right:1.15em;
  margin-bottom:1.15em;
  align-items:center;
  width:8em;
  box-sizing:border-box;
  border:${prop => prop.selected ? `2px solid ${prop.theme.success}` : '2px solid white'};
  transition: all .3s ease-in-out;
  position:relative;
  
  hr {
    width:100%;
    /* border:0; */
    /* border:1px solid #E8D4FC; */
    border:0;
    height:1px;
    background:#E8D4FC;
    margin-bottom:10px;
  }
  .icon {
    font-size: 2rem;
    margin-bottom:16px;
    color:var(--primary);
    /* color: ${prop => prop.selected ? '#9E66D8' : lighten(0.001, '#C19AEA')}; */
  }
  .displayName {
    color:var(--primary);
    font-weight: ${prop => prop.selected ? 'bold' : 'normal'};
    text-align:center;
  }

  .checkbox {
    width:10px;
    height:10px;
    /* background:purple; */
    position:absolute;
    right:0;
    top:0;
    font-size:.8rem;
    /* color:#9E66D8; */
    color: ${prop => prop.selected ? prop.theme.success : 'transparent'};
    transition: all .3s ease-in-out;
    margin-right:8px;
    margin-top:8px;
  }

`;

WidgetOption.propTypes = {
  widget: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  onSelectWidget: PropTypes.func,
  selectedWidget: PropTypes.object,
  selected: PropTypes.bool,
}

export default WidgetOption;