import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "styled-components";


const Container = styled.div`
  
  .center {
    width:100%;
  }
  .icon {
    font-size: 2rem;
    margin-right:.5em;
    display:flex;
    align-items:center;
  }
  .center {
    display:flex;
    flex-direction:column;
    width:100%;
    .list-title {
      margin-bottom:.25em;
    }
  }
  .right {
    margin-left:auto;
    display:flex;
    flex-direction:column;
    text-align:center;
    .number {
      font-size:1.5rem;
      font-weight:600;
    }
  }
`;
const TopWidget = ({ data }) => {
  return (
    <Container>
      {data.map((item, index) => {
        console.log("items are ",item);
        const {title,/*url,*/event_count} = item || {};
        return (
          <ListBox key={index} title={title} icon="fas fa-link" subTitle="Widget Link" number={event_count} subNumber="CLICKS" />
        )
      })}
      {/* <ListBox title="PRIMARY LINKS" icon="fas fa-link" subTitle="Widget Link" number="143" subNumber="CLICKS" />
      <ListBox title="PRIMARY LINKS" icon="fas fa-link" subTitle="Widget Link" number="143" subNumber="CLICKS" />
      <ListBox title="PRIMARY LINKS" icon="fas fa-link" subTitle="Widget Link" number="143" subNumber="CLICKS" /> */}
    </Container>
  )
}

const ListBoxContainer = styled.div`
  display:flex;
  padding-top:1em;
  padding-bottom:1em;
  border-bottom:.5px solid ${prop => prop.theme.borderColor};
  .icon {
    color: ${prop => prop.theme.primary}
  }
  &:first-child {
    padding-top:0
  }
`;

const ListBox = ({ title, icon, subTitle, number, subNumber }) => {
  const theme = useContext(ThemeContext);
  return (
    <ListBoxContainer theme={theme}>
      <i className={`${icon} icon`} />
      <div className="center">
        <span className="list-title">{title}</span>
        <span>{subTitle}</span>
      </div>
      <div className="right">
        <span className="number">{number}</span>
        <span>{subNumber}</span>
      </div>
    </ListBoxContainer>
  )
}
export default TopWidget;