class User {
  constructor(uid = null, db = null) {
    this.uid = uid;
    this.db = db;
  }

  getUserData() {
    return new Promise(async (resolve, reject) => {
      const userRef = await this.db.collection('users').doc(this.uid).get();
      const data = await userRef.data();
      if (data) {
        resolve(data);
      } else {
        // reject(new Error("Can't fetch userData from firestore"));
      }

    });
  }

  getUidByUsername(username) {
    return new Promise(async (resolve, reject) => {
      const query = await this.db.collection("users").where("username", "==", username).get();
      const uid = await query.docs[0]?.id;
      if (uid) {
        resolve(uid);
      } else {
        reject(new Error('Username not found'));
      }
    });
  }

  getTotalUsernames(username) {
    return new Promise(async (resolve, reject) => {
      const query = await this.db.collection("users").where("username", "==", username).get();
      const totalUsernames =  query.docs.length;
      const uid = await query.docs[0]?.id;
      resolve(
        {totalUsernames,
        uid}
      )
    });
  }


  userNameExists(username, currentUid) {
    return new Promise(async (resolve, reject) => {
      const res = await this.getTotalUsernames(username);
      /* username not available except the current loggedin uid */
      if (res?.totalUsernames > 0 && (currentUid === res?.uid)) {
        resolve(false);
      } else if (res?.totalUsernames > 0 && (currentUid !== res?.uid)) {
        resolve(true);
      } else {
        resolve(false);
      }
    })
  }

  updateUserData(updateObj) {
    return new Promise(async (resolve, reject) => {
      const userRef = this.db.collection('users').doc(this.uid);

      await userRef.set(updateObj, { merge: true });
      resolve(true);
    })
  }

  getUserOnboarding() {
    return new Promise(async (resolve, reject) => {
      const userRef = await this.db.collection('users').doc(this.uid).get();
      const data = await userRef.data();
      if (data?.onboarding) {
        resolve(data?.onboarding);
      } else {
        reject(new Error("Can't fetch userData from firestore"));
      }

    });
  }
}

export default User;