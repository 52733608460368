// import React from "react";
import styled from "styled-components";

const Select = styled.select`
  outline: none;
  width: 100%;
  height:3em;
  border: none;
  background: white;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 2em;
  border: 1px solid #f1ebf8;
  box-shadow: 0px 2px 5px rgba(170, 145, 196, 0.1);
  font-size: 1rem;
`;

export default Select;